import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}api/`,
  timeout: 60000
});
const defaultOpt = { login: true };

function baseRequest(options) {
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzYWFzLWFwaS55dW56b25nYnUuY24iLCJhdWQiOiJzYWFzLWFwaS55dW56b25nYnUuY24iLCJpYXQiOjE2NDM1MjkzMDAsIm5iZiI6MTY0MzUyOTMwMCwiZXhwIjoxNjQ0MTM0MTAwLCJqdGkiOlsxMDA2MTAsInVzZXIiXX0.qwlyYqc_CS1lE4fOS-UkXfWsbXPtRI12aFx-EccfK6E';
  const url = location.hostname.split('.')[0];
  let token;
  if (url == 'localhost') {
    token = store.getters.token;
    // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzYWFzLWFwaS55dW56b25nYnUuY24iLCJhdWQiOiJzYWFzLWFwaS55dW56b25nYnUuY24iLCJpYXQiOjE2NDM1MjkzMDAsIm5iZiI6MTY0MzUyOTMwMCwiZXhwIjoxNjQ0MTM0MTAwLCJqdGkiOlsxMDA2MTAsInVzZXIiXX0.qwlyYqc_CS1lE4fOS-UkXfWsbXPtRI12aFx-EccfK6E';
  } else {
    token = store.getters.token;
  }
  const mer_id = store.getters.mer_id;
  if (mer_id) {
    if (options.method === 'get') {
      const params = options.params || {};
      params.mer_id = mer_id;
    } else {
      const params = options.data || {};
      params.mer_id = mer_id;
    }
  }
  const headers = options.headers || {};
  if (token) {
    const uQ = localStorage.getItem('urlQuery');
    if (uQ) {
      headers['x-source-type'] = 'wechat_mini';
    } else {
      headers['x-source-type'] = 'h5';
    }
    headers['X-Token'] = token;
    options.headers = headers;
  }
  return new Promise((resolve, reject) => {
    instance(options).then(res => {
      const data = res.data || {};
      if (res.status !== 200) {
        return reject({ message: '请求失败', res, data });
      }
      if ([401, 402].indexOf(data.status) !== -1) {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        });
      } else if (data.status === 200) {
        return resolve(data, res);
      } else {
        return reject({ message: data.message, res, data });
      }
    }).catch(message => reject({ message }));
  });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, data, method }, defaultOpt, options)
    );
  };
  return request;
}, {});

['get', 'head'].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, params, method }, defaultOpt, options)
    );
  };
});

export default request;
