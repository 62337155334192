import request from './request';


/** 
 * 申请开通数字商户
 */
 export function accountApplyApi(data) {
  return request.post(`/nft/account/apply`, data);
}

/** 
 * 数字账户详情
 */
 export function accountInfoApi() {
  return request.get(`/nft/account/info`);
}

/** 
 * 数字藏品详情
 */
export function nftCollectionInfo(id) {
  return request.get(`store/product/nftCollection/info/${id}`);
}

/** 
 * 数字藏品购买
 */
 export function createNftApi(data) {
  return request.post(`order/createNft`, data);
}

/** 
 * 我的数字藏品
 */
export function ownListApi(data) {
  return request.get(`nft/ownList`, data);
}

/** 
 * 我的数字藏品转赠列表
 */
 export function transfersListApi(data) {
  return request.get(`nft/transfersList`, data);
}

/** 
 * 我的数字藏品详情
 */
 export function ownNftInfoApi(id) {
  return request.get(`nft/data/info/${id}`);
}

/** 
 * 查看账户地址对应的用户信息
 */
 export function findAccountUserApi(data) {
  return request.get(`nft/account/findAccountUser`, data);
}

/** 
 * 转赠藏品
 */
export function transfersApi(data) {
  return request.post(`nft/transfers`, data);
}