<template>
  <div>
    <!-- 单列模式 -->
    <div
      class="content-box"
      v-if="content.style == 1"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        v-for="(item, index) in content.renderData"
        :key="index"
        class="one-list"
        :class="{ 'mt0': index == 0 }"
        :style="`width: 100%;margin-top:${facade.item_bottom_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background} url(${facade.bg_image}) repeat left center;background-size: 100%`"
        @click="goDetailAction(item)"
      >
        <div class="time">
          <div
            class="color-white"
            v-if="item.product.stock <= 0"
          >售罄</div>
          <template v-else-if="item.goTime <= 0 || !item.goTime">抢购中</template>
          <template v-else-if="item.goTime > item.djsPD">敬请期待 {{ item.dayArr[1] }}.{{ item.dayArr[2] }} {{ item.timeArr[0] }}:{{ item.timeArr[1] }} 开售</template>
          <template v-else>
            <van-count-down
              :time="item.goTime * 1000"
              @finish="isFinishAction(index)"
            >
              <template #default="timeData">
                <div style="font-size: 0.32rem;font-weight: 500;color: #00eeff;padding: 0.08rem 0">即将开售 {{ formatDateNumber(timeData.hours) }}:{{ formatDateNumber(timeData.minutes) }}:{{ formatDateNumber(timeData.seconds) }}</div>
              </template>
            </van-count-down>

          </template>
        </div>
        <div class="img">
          <img :src="item.product.image + (item.product.image.toLowerCase().indexOf('.gif') !== -1 ? '' : '!m640')">
        </div>
        <div class="text color-black">{{ item.product.store_name }}</div>
        <div class="tags flex align-items-c flex-wrap">
          <div
            class="tag"
            v-for="(m, i) in item.product.LabelNames"
            :key="`tag${i}`"
          >{{ m }}</div>
          <div class="num">限量{{ item.issuer_num }}份</div>
        </div>
        <div class="bottom flex">
          <div class="l-price">￥<span>{{ formatPriceNumber(item.product.price, 2) }}</span></div>
          <div class="r-address line1">{{ item.issuer }}</div>
        </div>
      </div>
    </div>
    <div
      class="content-box"
      v-if="content.style == 2"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        v-for="(item, index) in content.renderData"
        :key="index"
        class="list flex"
      >
        <div class="l-time">
          <div
            class="t-xian"
            :class="{ 'pt0': index == 0 }"
            :style="`padding-top:${facade.item_bottom_padding / 75}rem;`"
          ></div>
          <div
            class="item-back"
            :style="`background: ${facade.background_color};`"
          >
            <div
              v-if="item.day"
              class="day"
            >{{ item.dayArr[1] }}.{{ item.dayArr[2] }}</div>
            <div class="time">{{ item.timeArr[0] }}:{{ item.timeArr[1] }}</div>
            <div class="dian item-flex-center">
              <div></div>
            </div>
          </div>
          <div class="b-xian"></div>
        </div>
        <div
          class="r-view flex"
          :class="{ 'mt0': index == 0 }"
          :style="`margin-top:${facade.item_bottom_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background} url(${facade.bg_image}) repeat left center;background-size: 100%;`"
          @click="goDetailAction(item)"
        >
          <div class="img">
            <van-image
              style="width: 100%;height: 100%;"
              :src="item.product.image + (item.product.image.toLowerCase().indexOf('.gif') !== -1 ? '' : '!m640')"
              :fit="content.img_style === 2 ? 'contain' : 'cover'"
            ></van-image>
          </div>
          <div class="img-r">
            <div class="name line1">{{ item.product.store_name }}</div>
            <div class="count">限量{{ item.issuer_num }}份</div>
            <div class="price">￥<span>{{ formatPriceNumber(item.product.price, 2) }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表模式 -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { parseTime, formatPriceNumber, formatDateNumber } from "@/utils/util";
import { Image as VanImage, CountDown } from "vant";

export default {
  components: {
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
  },
  props: {
    content: [Object, String],
    facade: [Object, String],
  },
  setup(props) {
    const { content, facade, jump } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const color_data = computed(() => {
      return store.getters.color_data;
    });

    const goDetailAction = (item) => {
      localStorage.setItem("nftDetail", JSON.stringify(item));
      router.push({
        path: "/pages/nftCollection/index",
        query: { id: item.product_id },
      });
    };

    const isFinishAction = (index) => {
      content.value.renderData[index].goTime = -1;
    };

    return {
      formatPriceNumber,
      formatDateNumber,
      color_data,

      goDetailAction,
      isFinishAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .one-list {
    overflow: hidden;
    padding-bottom: 32px;
    position: relative;

    .img {
      img {
        width: 100%;
      }
    }

    .text {
      padding: 20px 32px 0 32px;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }

    .tags {
      padding: 6px 32px 0 32px;
      color: #00eeff;
      font-size: 24px;

      .tag {
        margin-top: 10px;
        line-height: 36px;
        height: 36px;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 8px;
        font-size: 22px;
        background: #5f5f5f;
        color: #fff;

        &:first-child {
          background: #00eeff;
          color: #000;
        }
      }

      .num {
        margin-top: 10px;
      }
    }

    .bottom {
      padding: 24px 32px 0 32px;
      align-items: flex-end;

      .l-price {
        color: #e6cb95;
        font-size: 24px;

        span {
          font-size: 48px;
          font-family: NUM;
        }
      }

      .r-address {
        flex: 1;
        margin-left: 32px;
        overflow: hidden;
        font-weight: 500;
        font-size: 24px;
        text-align: right;
        color: #999;
        margin-bottom: 4px;
      }
    }

    .time {
      position: absolute;
      right: 20px;
      top: 20px;
      background: rgba(0, 0, 0, 0.9);
      padding: 6px 16px;
      color: #00eeff;
      font-weight: 500;
      font-size: 24px;
      border-radius: 24px;
    }
  }

  .list {
    width: 100%;

    &:first-child {
      .l-time {
        .t-xian {
          background: none;
        }
      }
    }
    &:last-child {
      .l-time {
        .b-xian {
          background: none;
        }
      }
    }

    .r-view {
      height: 200px;
      flex: 1;
      margin-left: 24px;
      overflow: hidden;

      .img {
        width: 200px;
        height: 200px;
        background: #999;
      }

      .img-r {
        flex: 1;
        overflow: hidden;

        .name {
          padding: 20px 20px 0 32px;
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
        }
        .count {
          padding: 16px 20px 0 32px;
          color: #00eeff;
          font-size: 24px;
        }
        .price {
          color: #e6cb95;
          font-size: 24px;
          padding: 30px 20px 0 32px;

          span {
            font-size: 36px;
            font-family: NUM;
          }
        }
      }
    }

    .l-time {
      width: 90px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;

      .t-xian,
      .b-xian {
        flex: 1;
        width: 2px;
        background: #333;
      }

      .item-back {
        .day {
          color: #fff;
          font-size: 28px;
          text-align: center;
          padding-top: 8px;
        }

        .time {
          text-align: center;
          color: #999;
          font-size: 24px;
          margin-top: 8px;
        }

        .dian {
          margin-top: 16px;
          div {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: #00eeff;
          }
        }
      }
    }
  }

  .pt0 {
    padding-top: 0 !important;
  }
}
</style>