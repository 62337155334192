<template>
  <div class="home" ref="scrollBox" style="height:100vh;overflow-y:scroll;" @scroll="handleScroll">
    <div class="search-top">
      <div
        v-if="payType == 'wechat_h5'"
        class="search-back"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png"
          mode=""
        >
      </div>
      <div class="search-inp">
        <div class="search-input">
          <input
            type="text"
            v-model="searchValue"
            class="search-input-view"
            placeholder="请输入关键词搜索"
            @keyup.enter="goSearch"
          />
        </div>
        <div
          v-if="!isSearch"
          class="search-btn"
          @click="goSearch"
        >
          <img
            src="https://artimg2.artart.cn/image/20220319/a3da1235c89feffd42a86a22bc87fa2b.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="search-btn"
          @click="clearSearch"
        >
          <img
            src="https://artimg2.artart.cn/image/20220322/807a11006645b50c219b8f3ab8fb8ef3.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      :immediate-check="false"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <div class="search-result1" v-if="type == 1">
        <Auction
          :content="{ renderData: list, style: 2 }"
          :facade="auctionFacade"
        />
      </div>
      <div class="search-result1" v-else-if="type == 2">
        <AuctionAlbum
          :content="{ renderData: list, style: 2 }"
          :facade="auctionAlbumFacade"
        />
      </div>
    </van-list>
  </div>
</template>

<script>
import { ref, onBeforeMount, onActivated, nextTick  } from "vue";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import { albumListApi, auctionList } from "@/api/auction";
import Auction from "@/components/DiyComponent/Auction";
import AuctionAlbum from "@/components/DiyComponent/AuctionAlbum";
import { getDiyPage } from "@/api/system";
import { returnWxPayType } from '@/utils/util';

export default {
  components: { Auction, AuctionAlbum },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const type = ref(""); // type 1 拍品  2专场
    const searchValue = ref(""); // 搜索值
    const isSearch = ref(false);
    const list = ref([]);
    const query = ref({
      page: 1,
      limit: 10,
    });
    const auctionFacade = ref({});
    const auctionAlbumFacade = ref({});
    const payType = ref('');

    onBeforeMount(() => {
      type.value = route.query.type || "1";
      searchValue.value = route.query.keyword || "";
      payType.value = returnWxPayType();

      switch (Number(type.value)) {
        case 1:
          getDiyPage({
            type: "auction_component",
          }).then((res) => {
            const configData = res.data.config_data;
            let configFacade = {};
            for (let key in configData) {
              if (key === "type_setting") {
                Object.assign(configFacade, configData["type_setting"][2]);
              } else {
                configFacade[key] = configData[key];
              }
            }
            configFacade.page_margin -= configFacade.left_margin;
            auctionFacade.value = configFacade;
          });
          break;
        case 2:
          getDiyPage({
            type: "album_component",
          }).then((res) => {
            const configData = res.data.config_data;
            auctionAlbumFacade.value = configData;
          });
          break;
      }

      if (searchValue.value) {
        goSearch();
      }
    });

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const goSearch = () => {
      if (!searchValue.value) return false;
      isSearch.value = true;
      list.value = [];
      query.value.page = 1;
      query.value.keyword = searchValue.value;
      searchAction();
    };
    const isLoading = ref(false);
    const hasMore = ref(true);

    const searchAction = () => {
      isLoading.value = true;
      let action = auctionList;
      switch (Number(type.value)) {
        case 1:
          action = auctionList;
          break;
        case 2:
          action = albumListApi;
          query.value.limit = 5;
          break;
      }
      action(query.value)
        .then((res) => {
          if (query.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }

          isLoading.value = false;
        })
        .catch((message) => {
          isLoading.value = false;
          Toast(message);
        });
    };

    const loadMoreData = () => {
      if (!isLoading.value && hasMore.value) {
        isLoading.value = true;
        query.value.page = query.value.page + 1;
        searchAction();
      }
    };

    let scrollTop = ref(0);
    const scrollBox = ref(null);
    const handleScroll = () => {
      scrollTop.value = scrollBox.value.scrollTop;
    }

    onActivated(() => {
      if(scrollTop.value > 0){
        setTimeout(() => {
          nextTick(() => {
            scrollBox.value.scrollTop = scrollTop.value;
          })
        }, 10)
      }
    })
    return {
      type,
      searchValue,
      isSearch,
      isLoading,
      hasMore,
      list,
      auctionFacade,
      auctionAlbumFacade,
      payType,

      backAction,
      goSearch,
      loadMoreData,

      scrollBox,
      handleScroll,
      scrollTop,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-top {
  height: 90px;
  display: flex;
  align-items: center;
}

.search-back {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-back img {
  width: 48px;
  height: 48px;
  display: block;
}

.search-inp {
  flex: 1;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 30px;
  border-radius: 30px;
  background: #eee;
  margin: 0 20px;
}

.search-input {
  flex: 1;
  display: flex;
  align-items: center;
}

.search-input-view {
  flex: 1;
  font-size: 26px;
  border: none;
  background: none;
}

.search-btn {
  display: flex;
  align-items: center;
  height: 60px;
  width: 60px;
}
.search-btn img {
  width: 30px;
  height: 30px;
}

.huise {
  height: 20px;
  background: #f7f7f7;
}
</style>