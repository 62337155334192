<template>
  <div class="search" ref="scrollBox" style="height:100vh;overflow-y:scroll;" @scroll="handleScroll">
    <div class="search-top">
      <div
        v-if="payType == 'wechat_h5'"
        class="search-back"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png"
          mode=""
        >
      </div>
      <div class="search-inp">
        <div class="search-input">
          <input
            type="text"
            v-model="searchValue"
            class="search-input-view"
            placeholder="请输入关键词搜索"
            @keyup.enter="goSearch"
          />
        </div>
        <div
          v-if="!isSearch"
          class="search-btn"
          @click="goSearch"
        >
          <img
            src="https://artimg2.artart.cn/image/20220319/a3da1235c89feffd42a86a22bc87fa2b.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="search-btn"
          @click="clearSearch"
        >
          <img
            src="https://artimg2.artart.cn/image/20220322/807a11006645b50c219b8f3ab8fb8ef3.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="huise"></div>
    <!-- <div
      class="hot-search"
      v-if="!isSearch && hotSearch.length > 0"
    >
      <div class="hot-search-title">热搜</div>
      <div class="hot-search-content">
        <div
          v-for="(m, i) in hotSearch"
          :key="'hotSearch' + i"
          :data-keywords="m"
          @click='searchKeywords'
        >{{m}}</div>
      </div>
    </div> -->
    <div
      class="history"
      v-if="sHistory.length > 0 && !isSearch"
    >
      <div class='history-title'>
        <div class="history-tl">搜索足迹</div>
        <div
          class="history-tr"
          @click='clearHistory'
        >
          <img
            src="https://artimg2.artart.cn/image/20220322/807a11006645b50c219b8f3ab8fb8ef3.png"
            alt=""
          >
        </div>
      </div>
      <div class="history-content">
        <div
          v-for="(m, i) in sHistory"
          :key="'sHistory' + i"
          :data-keywords="m"
          @click='searchKeywords'
        >{{m}}</div>
      </div>
    </div>
    <div
      class="search-result"
      v-if="isSearch"
    >
      <div
        v-if="searchPm.length == 0 && searchZc.length == 0 && searchCount >= searchAllCount"
        class="no-data"
      >没有搜索到数据</div>
      <template v-if="searchPm.length > 0">
        <div class="search-result-title">
          <div>拍品</div>
          <router-link
            replace
            :to="'/pages/search/type?type=1&keyword=' + searchValue"
            class="more"
          >MORE ></router-link>
        </div>
        <div class="search-result1">
          <Auction
            :content="{ renderData: searchPm, style: 2 }"
            :facade="auctionFacade"
          />
        </div>
      </template>
      <template v-if="searchZc.length > 0">
        <div class="search-result-title">
          <div>专场</div>
          <router-link
            replace
            :to="'/pages/search/type?type=2&keyword=' + searchValue"
            class="more"
          >MORE ></router-link>
        </div>
        <div class="search-result1">
          <AuctionAlbum
            :content="{ renderData: searchZc, style: 2 }"
            :facade="auctionAlbumFacade"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { albumListApi, auctionList } from "@/api/auction";
import { getDiyPage } from "@/api/system";
import Auction from "@/components/DiyComponent/Auction";
import AuctionAlbum from "@/components/DiyComponent/AuctionAlbum";
import { returnWxPayType } from '@/utils/util';

export default {
  components: { Auction, AuctionAlbum },
  data() {
    return {
      openid: "",
      auctionColour: "",
      auctionButtonColour: "",
      auctionButtonBgColour: "",
      tabButtonBgColour: "",
      tabButtonColour: "",
      tabBackgroundColor: "",
      searchValue: "",
      isSearch: false,
      hotSearch: [],
      sHistory: [],
      searchCount: 0,
      searchAllCount: 2,
      hasCourse: false,
      hasPaimai: false,
      hasShop: false,
      searchCourse: [],
      searchPm: [],
      searchZc: [],
      searchShop: [],
      intervalList: [],
      productList: [],

      auctionFacade: {},
      auctionAlbumFacade: {},
      payType: '',
      scrollTop: 0,
    };
  },
  created() {
    this.payType = returnWxPayType();
    getDiyPage({
      type: "auction_component",
    }).then((res) => {
      const configData = res.data.config_data;
      let configFacade = {};
      for (let key in configData) {
        if (key === "type_setting") {
          Object.assign(configFacade, configData["type_setting"][2]);
        } else {
          configFacade[key] = configData[key];
        }
      }
      configFacade.page_margin -= configFacade.left_margin;
      this.auctionFacade = configFacade;
    });
    getDiyPage({
      type: "album_component",
    }).then((res) => {
      const configData = res.data.config_data;
      // let configFacade = {};
      // for (let key in configData) {
      //   if (key === "type_setting") {
      //     Object.assign(configFacade, configData["type_setting"][2]);
      //   } else {
      //     configFacade[key] = configData[key];
      //   }
      // }
      // configFacade.page_margin -= configFacade.left_margin;
      this.auctionAlbumFacade = configData;
    });
    let searchHistory = localStorage.getItem("searchHistory");
    console.log(searchHistory);
    searchHistory = searchHistory ? JSON.parse(searchHistory) : { key: [] };
    if (searchHistory) {
      this.sHistory = searchHistory.key;
    }
  },
  activated() {
    if(this.scrollTop > 0){
      let scrollTop = this.scrollTop;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs['scrollBox'].scrollTop = scrollTop;
        })
      }, 10)
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.scrollY || this.$refs['scroll-box'].scrollTop;
    },
    goSearch() {
      const searchValue = this.searchValue;
      if (!searchValue) {
        return false;
      }
      let searchHistory = localStorage.getItem("searchHistory");
      searchHistory = searchHistory ? JSON.parse(searchHistory) : {};
      let has = false;
      if (searchHistory && searchHistory.key) {
        for (let m of searchHistory.key) {
          if (m == searchValue) {
            has = true;
            break;
          }
        }
      } else {
        searchHistory = {
          key: [],
        };
      }
      if (!has) {
        searchHistory.key = searchHistory.key.concat([searchValue]);
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
        this.sHistory = searchHistory.key;
      }
      this.isSearch = true;
      this.searchCount = 0;
      this.searchPm = [];
      this.searchZc = [];
      this.searchPmAction(searchValue);
      this.searchZcAction(searchValue);
    },
    searchKeywords(e) {
      const searchValue = e.currentTarget.dataset.keywords;
      this.searchValue = searchValue;
      this.goSearch();
    },
    searchPmAction(val) {
      auctionList({
        keyword: val,
        page: 1,
        limit: 4,
      })
        .then((res) => {
          this.searchPm = res.data.list;
          this.searchCount = this.searchCount + 1;
        })
        .catch(() => {
          this.searchCount = this.searchCount + 1;
        });
    },
    searchZcAction(val) {
      albumListApi({
        keyword: val,
        page: 1,
        limit: 3,
      })
        .then((res) => {
          this.searchZc = res.data.list;
          this.searchCount = this.searchCount + 1;
        })
        .catch(() => {
          this.searchCount = this.searchCount + 1;
        });
    },
    clearSearch() {
      this.searchValue = "";
      this.isSearch = false;
      this.searchPm = [];
      this.searchZc = [];
      this.searchShop = [];
    },
    clearHistory() {
      localStorage.setItem("searchHistory", "");
      this.sHistory = [];
    },
    backAction() {
      if (window.history.length <= 1) {
        this.$router.replace("/");
      } else {
        this.$router.back();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.search {
  padding-bottom: 40px;
}
.search-top {
  height: 90px;
  display: flex;
  align-items: center;
}

.search-back {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-back img {
  width: 48px;
  height: 48px;
  display: block;
}

.search-inp {
  flex: 1;
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 30px;
  border-radius: 30px;
  background: #eee;
  margin: 0 20px;
}

.search-input {
  flex: 1;
  display: flex;
  align-items: center;
}

.search-input-view {
  flex: 1;
  font-size: 26px;
  border: none;
  background: none;
}

.search-btn {
  display: flex;
  align-items: center;
  height: 60px;
  width: 60px;
}
.search-btn img {
  width: 30px;
  height: 30px;
}

.huise {
  height: 20px;
  background: #f7f7f7;
}

.hot-search {
  padding: 20px;
  display: flex;
  font-size: 28px;
}

.hot-search-title {
  padding: 0 20px;
  color: #999;
}

.hot-search-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.hot-search-content div {
  margin: 0 10px;
}

.history {
  font-size: 28px;
  padding: 20px 40px;
}

.history-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
}
.history-title img {
  width: 30px;
  height: 30px;
}

.history-content {
  display: flex;
  flex-wrap: wrap;
}

.history-content div {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border: 2px solid #e1e1e1;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 8px;
}

.shenfen {
  margin: 50px 0;
}

.shenfen-title {
  text-align: center;
  font-size: 28px;
  color: #999;
}

.shenfen-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.shenfen-content .sf {
  width: 33%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 28px;
  margin-top: 30px;
}

.search-result1 {
  margin-top: 30px;
  /* padding: 0 15px; */
}

.search-result-title {
  font-size: 26px;
  color: #999;
  padding: 20px 0;
  margin: 0 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
}

.shop-list {
  display: flex;
  flex-wrap: wrap;
}
.shop {
  width: 210px;
  margin-left: 30px;
  margin-top: 30px;
}
.shop-img {
  width: 210px;
  height: 210px;
}
.shop-img img {
  width: 210px;
  height: 210px;
  display: block;
  border-radius: 8px;
}
.shop-name {
  text-align: center;
  margin-top: 10px;
  font-size: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-data {
  padding: 30px 80px;
  font-size: 24px;
  color: #999;
  text-align: center;
}
.more{
  color: #999;
}
</style>
