<template>
  <div>
  <div ref="scrollHomeBox" style="height:100vh;overflow-y:scroll;" @scroll="handleHomeScroll">
  <van-pull-refresh v-model="loadingr" @refresh="onRefresh" success-text="刷新成功">
  <div
    class="home"
    :style="pageStyle"
  >
    <template v-if="config_data.headerSetting && !config_data.hide_header && !config_data.hide_header_view">
      <div
        class="custom-header"
        :style="headerStyle"
      >
        <div
          class="header-bg"
          :style="{ background: (config_data.headerSetting.header_background || '#fff'), opacity: (headerShow ? 1 : 0) }"
        >
        </div>
        <div
          class="costom-header-view"
          :class="{ 'justify-center': config_data.headerSetting.logo_position == 2 }"
          :style="{ padding: `${headerPaddingTop / 75}rem 0 0.13333rem 0` }"
        >
          <div
            class="custom-header-back"
            v-if="!config_data.headerSetting.hide_back_btn"
            :style="{ left: `${headerPaddingLeft / 75}rem`, top: `${headerPaddingTop / 75}rem` }"
            catchtap="backAction"
          >
            <img
              src="https://artimg2.artart.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png"
              v-if="config_data.headerSetting.header_color === 'white'"
            >
            <img
              src="https://artimg2.artart.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png"
              v-else
            >
          </div>

          <div
            class="header-img item-align-center"
            v-if="config_data.headerSetting.logo"
            :style="{ paddingLeft: `${config_data.headerSetting.logo_left_margin / 75}rem`, marginLeft: (config_data.headerSetting.logo_position == 1 && !config_data.headerSetting.hide_back_btn ? '0.64rem' : '')}"
          >
            <img
              :src="config_data.headerSetting.logo"
              :style="{ height: `${headerHeight / 75}rem`}"
              mode="heightFix"
              v-show="!headerShow || !config_data.headerSetting.logo_slide"
            >
            <img
              :src="config_data.headerSetting.logo_slide"
              :style="{ height: `${headerHeight / 75}rem`}"
              mode="heightFix"
              v-show="headerShow"
              v-if="config_data.headerSetting.logo_slide"
            >
          </div>

          <div
            class="search-content item-align-center"
            :class="{ yuanxing: config_data.headerSetting.header_search_type == 1 }"
            v-if="config_data.headerSetting.open_header_search"
            :style="headerSearchStyle"
            catchtap="goSearch"
          >
            <van-icon
              name="search"
              :size="(config_data.headerSetting.header_search_text_size)"
              :color="(config_data.headerSetting.header_search_icon_color)"
            />
            <div
              class="flex-one"
              style="text-align: left;"
              v-if="config_data.headerSetting.header_search_type == 2"
            >
              {{config_data.headerSetting.header_search_text}}
            </div>
          </div>

          <div
            class="txt"
            :style="{ paddingLeft: ((headerPaddingRight || 0) / 75) + 'rem',paddingRight: ((headerPaddingRight || 0) / 75) + 'rem', fontSize: (config_data.headerSetting.header_text_size / 75) + 'rem' }"
            v-if="!config_data.headerSetting.open_header_search && !config_data.headerSetting.logo"
          >{{name}}
          </div>
        </div>
      </div>
      <div
        class="custom-header-zw"
        :style="headerStyle"
        v-if="!config_data.headerSetting.header_air"
      ></div>
    </template>
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      :immediate-check="false"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <!-- <van-list> -->
      <template
        v-for="(item, index) in content"
        :key="index"
      >

        <template v-if="item.name === 'nav'">
          <div style="width: 10rem;overflow: hidden;">
            <template v-if="item.facade.fixed_top">
              <van-sticky
                :offset-top="(config_data.headerSetting && !config_data.hide_header && !config_data.hide_header_view) ? (100 / 75) * fix : 0"
                @change="(data) => isFixedAction(data, index)"
              >
                <Nav
                  :content="item.content"
                  :facade="item.facade"
                ></Nav>
              </van-sticky>
            </template>
            <template v-else>
              <Nav
                :content="item.content"
                :facade="item.facade"
              ></Nav>
            </template>
          </div>
        </template>
        <template v-else-if="item.name === 'banner'">
          <div style="width: 10rem;overflow: hidden;">
            <Banner
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'title'">
          <div style="width: 10rem;overflow: hidden;">
            <Title
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'search'">
          <div style="width: 10rem;overflow: hidden;">
            <Search
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'blank'">
          <div style="width: 10rem;overflow: hidden;">
            <Blank
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'rubik'">
          <div style="width: 10rem;overflow: hidden;">
            <Rubik
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <div
          class="video"
          v-else-if="item.name === 'video' && item.content.video.url"
          :style="{ paddingLeft: (item.facade.page_padding / 75) + 'rem' }"
        >
          <video
            :style="{ width: ((item.facade.vW || 750) / 75)+ 'rem', height: ((item.facade.vH || 411) / 75) + 'rem' }"
            :src="item.content.video.url"
            object-fit="cover"
            :poster="item.content.cover + '!16b9'"
            :loop="item.content.loop"
            :autoplay="item.content.autoplay"
            controls
          ></video>
        </div>
        <template v-else-if="item.name === 'notice'">
          <div style="width: 10rem;overflow: hidden;">
            <Notice
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'auction'">
          <div style="width: 10rem;overflow: hidden;">
            <Auction
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'auctionAlbum'">
          <div style="width: 10rem;overflow: hidden;">
            <AuctionAlbum
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'auctionMeeting'">
          <div style="width: 10rem;overflow: hidden;">
            <AuctionMeeting
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'word'">
          <div style="width: 10rem;overflow: hidden;">
            <Word
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'nftCollection'">
          <div style="width: 10rem;overflow: hidden;">
            <nftCollection
              :content="item.content"
              :facade="item.facade"
            />
          </div>
        </template>
        <template v-else-if="item.name === 'tag'">
          <div style="width: 10rem;overflow: hidden;">
            <template v-if="item.facade.fixed_top">
              <van-sticky
                :offset-top="(config_data.headerSetting && !config_data.hide_header && !config_data.hide_header_view) ? (100 / 75) * fix : 0"
                @change="(data) => isFixedAction(data, index)"
              >
                <Tag
                  :item="item"
                  :index="index"
                  @changeTag="changeTagAction"
                ></Tag>
              </van-sticky>
            </template>
            <template v-else>
              <Tag
                :item="item"
                :index="index"
                @changeTag="changeTagAction"
              ></Tag>
            </template>
            <div
              class="tag-content"
              :class="{ 'min-height': item.content.loadMore }"
              :style="{ marginTop: (item.facade.content_margin / 75) + 'rem' }"
            >
              <template v-if="item.renderData && item.renderData.content && item.renderData.content.renderData.length > 0">
                <template v-if="item.renderData.name === 'auction'">
                  <div style="width: 10rem;overflow: hidden;">
                    <Auction
                      :content="item.renderData.content"
                      :facade="item.renderData.facade"
                    />
                  </div>
                </template>
                <template v-else-if="item.renderData.name === 'auctionAlbum'">
                  <div style="width: 10rem;overflow: hidden;">
                    <AuctionAlbum
                      :content="item.renderData.content"
                      :facade="item.renderData.facade"
                    />
                  </div>
                </template>
                <template v-else-if="item.renderData.name === 'auctionMeeting'">
                  <div style="width: 10rem;overflow: hidden;">
                    <AuctionMeeting
                      :content="item.renderData.content"
                      :facade="item.renderData.facade"
                    />
                  </div>
                </template>
                <template v-else-if="item.renderData.name === 'word'">
                  <div style="width: 10rem;overflow: hidden;">
                    <Word
                      :content="item.renderData.content"
                      :facade="item.renderData.facade"
                    />
                  </div>
                </template>
                <template v-else-if="item.renderData.name === 'nftCollection'">
                  <div style="width: 10rem;overflow: hidden;">
                    <nftCollection
                      :content="item.renderData.content"
                      :facade="item.renderData.facade"
                    />
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </template>
    </van-list>

  </div>
  </van-pull-refresh>
  </div>
  <Footer
      :useMine="config_data.footerSetting && config_data.footerSetting.use_type == 2"
      :useData="config_data.footerSetting && config_data.footerSetting ? config_data.footerSetting : {}"
      :isHomePage="pageStatue == 1"
    />
  </div>
</template>

<script scoped>
import { getDiyPage, getDiyPageData } from "@/api/system";
import { Icon, Sticky } from "vant";
import Nav from "@/components/DiyComponent/Nav";
import Banner from "@/components/DiyComponent/Banner";
import Title from "@/components/DiyComponent/Title";
import Search from "@/components/DiyComponent/Search";
import Blank from "@/components/DiyComponent/Blank";
import Rubik from "@/components/DiyComponent/Rubik";
import Notice from "@/components/DiyComponent/Notice";
import Auction from "@/components/DiyComponent/Auction";
import AuctionAlbum from "@/components/DiyComponent/AuctionAlbum";
import AuctionMeeting from "@/components/DiyComponent/AuctionMeeting";
import Word from "@/components/DiyComponent/Word";
import Tag from "@/components/DiyComponent/Tag";
import nftCollection from "@/components/DiyComponent/nftCollection";
import { shareConfig } from "@/utils/share";
import { getRem } from "@/utils/rem";

export default {
  name: "Home",
  components: {
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    Nav,
    Banner,
    Title,
    Search,
    Blank,
    Rubik,
    Notice,
    Auction,
    AuctionAlbum,
    AuctionMeeting,
    Word,
    Tag,
    nftCollection,
  },
  data() {
    return {
      id: "",
      loading: false,
      finished: false,
      headerPaddingRight: 0,

      name: "",
      config_data: {},
      configData: {},
      diyData: {},

      renderContent: [],
      content: [],

      pageStyle: "",
      headerStyle: "",
      headerSearchStyle: "",
      pageStatue: "",

      headerShow: false,
      headerPaddingTop: 0,
      headerPaddingLeft: 0,
      headerHeight: 64,

      isLoading: false,
      noData: false,
      hasMore: true,
      page: 1,
      fix: getRem(),
      loadingr: false,
      scrollTop: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.load();
  },
  mounted() {
    
    // 监听滚动事件，用handleScroll这个方法进行相应的处理
    // window.addEventListener("scroll", this.handleScroll);
  },
  activated() {
    if(this.scrollTop > 0){
      let scrollTop = this.scrollTop;
      setTimeout(() => {
        this.$nextTick(() => {
          // window.scrollTo(0, scrollTop);
          this.headerShow = true;
          this.$refs['scrollHomeBox'].scrollTop = scrollTop;
          // this.$refs['scroll-box'].scrollTo(0, scrollTop);
        })
      }, 10)
      // this.scrollTop = 0;
    }
    console.log(this.two);
    if (this.two) {
      if(window.uni){
        return;
      }
      shareConfig(
        this.config_data.share_txt,
        "",
        this.config_data.share_image,
        "",
        "",
        location.href
      );
    }
    this.two = true;
    // window.addEventListener("scroll", this.handleScroll);
  },
  deactivated() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    onRefresh() {
      this.load();
      setTimeout(() => {
         this.loadingr = false;
      }, 1000);
    },
    handleHomeScroll() {
      this.scrollTop = this.$refs['scrollHomeBox'].scrollTop;
      if (this.scrollTop > 50 && !this.headerShow) {
        this.headerShow = true;
      } else if (this.scrollTop <= 50 && this.headerShow) {
        this.headerShow = false;
      }
    },
    load() {
      getDiyPage({
        id: this.id,
        type: "diy_page",
      })
        .then((res) => {
          this.renderContentAction(res);
        })
        .catch((message) => console.log(message));
    },
    renderContentAction(res) {
      const configData = res.data.config_data;
      const configStyle = this.getConfigStyle(configData);

      shareConfig(
        configData.share_txt,
        "",
        configData.share_image,
        "",
        "",
        location.href
      );

      this.config_data = configData;
      this.name = res.data.name;
      this.pageStyle = configStyle.pageStyle;
      this.headerStyle = configStyle.headerStyle;
      this.headerSearchStyle = configStyle.headerSearchStyle;
      this.pageStatue = res.data.status;
      const content = res.data.content;

      content.forEach((m) => {
        if (m.facade) {
          m.facade.isFixed = false;
        }
      });

      this.renderContent = content;

      this.loadContent();

      // this.content = JSON.parse(res.data.content);
    },
    getConfigStyle(configData) {
      const headerSetting = configData.headerSetting;
      let pageStyle = `background-color: ${configData.background};`;
      if (configData.bg_image) {
        pageStyle +=
          `background-image: url(${configData.bg_image});` +
          `background-repeat: ${
            configData.bg_image_mode === 3 ? "repeat-y" : "no-repeat"
          };` +
          `background-position: ${
            configData.bg_image_mode === 3 || configData.bg_image_mode === 4
              ? "center top"
              : "center"
          };`;
        if (configData.bg_image_mode === 1) {
          pageStyle += `background-size: contain;`;
        } else if (configData.bg_image_mode === 2) {
          pageStyle += `background-size: cover;`;
        } else if (configData.bg_image_mode === 3) {
          pageStyle += `background-size: 10rem;`;
        } else if (configData.bg_image_mode === 4) {
          pageStyle += `background-size: 10rem;`;
        }
      }
      const headerStyle =
        `padding-top: ${0}rem;` +
        `height: ${80 / 75}rem;` +
        `padding-bottom: ${20 / 75}rem;` +
        `background-color: ${headerSetting.header_background};` +
        `color: ${headerSetting.header_color};`;

      const headerSearchStyle =
        `color: ${configData.headerSetting.header_search_text_color};` +
        `background-color: ${configData.headerSetting.header_search_background_color};` +
        `border-color: ${configData.headerSetting.header_search_border_color};` +
        `border-radius: ${
          configData.headerSetting.header_search_border_radius / 75
        }rem;` +
        `font-size: ${
          configData.headerSetting.header_search_text_size / 75
        }rem;` +
        `width: ${
          configData.headerSetting.header_search_width_style / 75
        }rem;` +
        `font-weight: ${
          configData.headerSetting.header_search_text_style === 1 ? 400 : 500
        };` +
        `margin-left: ${
          configData.headerSetting.header_search_margin_left / 75
        }rem;`;

      return {
        pageStyle: pageStyle,
        headerStyle: headerStyle,
        headerSearchStyle: headerSearchStyle,
      };
    },
    loadContent(i) {
      const loadIndex = this.loadIndex || 0;
      if (loadIndex >= this.renderContent.length) {
        return false;
      }
      const index = this.getRenderIndex(i);
      if (index !== -1) {
        this.loadIndex = index + 1;
        const content = this.renderContent.slice(loadIndex, index + 1);
        this.content = this.content.concat(JSON.parse(JSON.stringify(content)));
        if (this.renderContent[index].name === "tag") {
          this.loadTagData(index, 0, () => {
            this.loadContent(index);
          });
        } else {
          this.loadData(index, () => {
            this.loadContent(index);
          });
        }
      } else {
        if (loadIndex < this.renderContent.length) {
          this.loadIndex = this.renderContent.length;
          const content = this.renderContent.slice(
            loadIndex,
            this.renderContent.length
          );
          this.content = this.content.concat(content);
          this.loadContent(this.loadIndex);
        }
      }
    },

    getRenderIndex(i) {
      let key = -1;
      for (
        let index = i || i === 0 ? i + 1 : 0;
        index < this.renderContent.length;
        index++
      ) {
        const name = this.renderContent[index].name;
        if (
          name == "auctionMeeting" ||
          name == "auctionAlbum" ||
          name == "auction" ||
          name == "word" ||
          name == "nftCollection" ||
          name == "tag"
        ) {
          key = index;
          break;
        }
      }
      return key;
    },

    loadData(index, cb) {
      if (
        this.renderContent[index].content.data[0] &&
        typeof this.renderContent[index].content.data[0] === "object"
      ) {
        this.renderContent[index].content.data = this.renderContent[
          index
        ].content.data.map((m) => m.id);
      }
      const searchData = {};
      for (let key in this.renderContent[index].content) {
        if (key !== "renderData") {
          searchData[key] = this.renderContent[index].content[key];
        }
      }
      const data = {
        search_data: JSON.stringify(searchData),
        type: this.renderContent[index].name,
      }
      getDiyPageData(data)
        .then((res) => {
          if (res.data.data.length > 0) {
            if (data.type == "nftCollection") {
              res.data.data.forEach((m, i) => {
                const sT = m.start_time.split(' ');
                if (!res.data.data[i - 1] || sT[0] !== res.data.data[i - 1].day) {
                  m.day = sT[0];
                  m.dayArr = sT[0].split('-');
                  m.timeArr = sT[1].split(':');
                } else {
                  m.dayArr = sT[0].split('-');
                  m.timeArr = sT[1].split(':');
                }
                const startD = new Date();
                startD.setFullYear(Number(m.dayArr[0]), Number(m.dayArr[1]) - 1, Number(m.dayArr[2]));
                startD.setHours(Number(m.timeArr[0]), Number(m.timeArr[1]), Number(m.timeArr[2]))
                const sTime = Date.parse(startD) / 1000;
                m.sTime = sTime;
                m.server_time = res.server_time;
                const nowTime = new Date();
                nowTime.setHours(0, 0, 0);
                m.djsPD = (86400 - (res.server_time - (Date.parse(nowTime) / 1000)));
                m.goTime = sTime - res.server_time;
              });
            }
            const cData = JSON.parse(
              JSON.stringify(this.content[index].content)
            );
            let newdata = res.data.data;
            if (
              this.renderContent[index].name === "auctionAlbum" &&
              cData.style === 2
            ) {
              // const imgWidth = 750 - this.content[index].facade.page_padding - this.content[index].facade.page_padding;
              // newdata.forEach(m => {
              //   if (m.image_show_style == 1) {
              //     m.imgW = imgWidth;
              //     m.imgH = parseInt(imgWidth / m.photoSize.width * m.photoSize.height, 10);
              //   }
              // });
            } else if (this.renderContent[index].name === "auction") {
              // newdata.forEach(m => {
              //   m.startPrice = util.formatPriceNumber(m.startprice / 100, 2);
              //   m.pmnowCoin = util.formatPriceNumber(m.pmnowcoin / 100, 2);
              // });
            }
            cData.renderData = newdata;
            cData.data = this.renderContent[index].content.data;
            this.content[index].content = cData;
          }
          if (cb) cb();
        })
        .catch((message) => console.log(message));
    },

    loadTagData(index, i, cb) {
      const tagItem = this.renderContent[index];
      const item = tagItem.content.data[i].link;
      if (!item.content) {
        this.noData = true;
        this.hasMore = false;
        this.content[index].renderData = {};
        if (cb) cb();
        return false;
      }
      if (item.content.data[0] && typeof item.content.data[0] === "object") {
        item.content.data = item.content.data.map((m) => m.id);
      }
      const data = {
        type: item.name,
        search_data: JSON.stringify(item.content),
      };
      if (tagItem.content.loadMore) {
        data.page = this.page;
        if (item.name === "auctionMeeting" && item.content.style != 3) {
          data.size = 3;
        } else if (item.name === "auctionAlbum") {
          data.size = 3;
        } else {
          data.size = 20;
        }
      }
      getDiyPageData(data)
        .then((res) => {
          if (res.data.data.length > 0) {
            if (data.type == "nftCollection") {
              res.data.data.forEach((m, i) => {
                const sT = m.start_time.split(' ');
                if (!res.data.data[i - 1] || sT[0] !== res.data.data[i - 1].day) {
                  m.day = sT[0];
                  m.dayArr = sT[0].split('-');
                  m.timeArr = sT[1].split(':');
                } else {
                  m.dayArr = sT[0].split('-');
                  m.timeArr = sT[1].split(':');
                }
                const startD = new Date();
                startD.setFullYear(Number(m.dayArr[0]), Number(m.dayArr[1]) - 1, Number(m.dayArr[2]));
                startD.setHours(Number(m.timeArr[0]), Number(m.timeArr[1]), Number(m.timeArr[2]))
                const sTime = Date.parse(startD) / 1000;
                m.sTime = sTime;
                m.server_time = res.server_time;
                const nowTime = new Date();
                nowTime.setHours(0, 0, 0);
                m.djsPD = (86400 - (res.server_time - (Date.parse(nowTime) / 1000)));
                m.goTime = sTime - res.server_time;
              });
            }
            const cData = JSON.parse(
              JSON.stringify(this.content[index].content.data[i].link)
            );
            cData.content.data = null;

            if (tagItem.content.loadMore && data.page > 1) {
              cData.content.renderData = this.content[
                index
              ].renderData.content.renderData.concat(res.data.data);
            } else if (tagItem.content.loadMore) {
              cData.content.renderData = res.data.data;
            } else {
              cData.content.renderData = res.data.data;
            }

            this.content[index].renderData = cData;
            if (cb) cb();

            if (tagItem.content.loadMore) {
              this.isLoading = false;
            }

            if (tagItem.content.loadMore) {
              if (res.data.data.length < data.size) {
                this.hasMore = false;
              } else {
                this.hasMore = true;
              }
              this.noData = false;
            }
          } else {
            if (tagItem.content.loadMore) {
              if (data.page === 1) {
                this.noData = true;
              } else {
                this.hasMore = false;
              }
              this.isLoading = false;
            } else {
              if (cb) cb();
            }
          }
        })
        .catch((message) => console.log(message));
    },
    loadMoreData() {
      const index = this.content.length - 1;
      if (index < 0) return false;
      const item = this.content[index];
      if (item.name === "tag" && item.content.loadMore) {
        if (!this.isLoading && this.hasMore) {
          this.page = this.page + 1;
          this.isLoading = true;
          this.loadTagData(index, item.active || 0);
        }
      } else {
        this.hasMore = false;
      }
    },
    changeTagAction(e) {
      const index = e.index; // content下的index
      const item = this.content[index];
      const i = e.i; // content.data 下的index
      if ((item.active || 0) !== Number(i)) {
        if (item.name === "tag" && item.content.loadMore) {
          this.isLoading = true;
          this.hasMore = true;
          this.page = 1;
          this.noData = false;
          this.content[index].renderData = {};
        } else if (item.name === "tag") {
          this.content[index].renderData = {};
        }
        this.loadTagData(index, i);
        this.content[index].active = Number(i);
      }
    },
    isFixedAction(isFixed, index) {
      this.content[index].facade.isFixed = isFixed;
    },
  },
};
</script>

<style lang="scss" scope>
.custom-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  color: #000;
  z-index: 100;
}

.custom-header .header-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-header-zw {
  width: 100vw;
}

.costom-header-view {
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.custom-header-back {
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: 25px;
  left: 0;
}

.custom-header-back img {
  width: 48px;
  height: 48px;
  display: block;
}

.costom-header-view .txt {
  font-size: 34px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-img {
  flex-shrink: 0;
}

.search-content {
  height: 64px;
  line-height: 64px;
  flex-shrink: 0;
  padding: 0 24px;
  border: 1px solid #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-top: 17px;
}

.search-content.yuanxing {
  width: 64px !important;
  border-radius: 32px !important;
  justify-content: center;
}

.search-content div {
  padding-left: 10px;
}

.video {
  width: 750rpx;
  height: 422rpx;
}

.video video {
  width: 750rpx;
  height: 422rpx;
  display: block;
}

.min-height {
  min-height: 69vh;
}
</style>
