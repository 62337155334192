import { Toast } from "vant";
import store from '@/store';

export function conutDown(lag, callBack, returnInt, noZero, hasDay) {
  let timer;
  let int;
  if (lag <= 0) {
    return;
  }
  timer = function () {
    lag -= 1;
    if (lag <= 0) {
      clearInterval(int);
      callBack(false, false, false);
      return;
    }
    let second = '';
    if (noZero) {
      second = Math.floor(lag % 60);
    } else {
      second = formatDateNumber(Math.floor(lag % 60));
    }
    let minite = formatDateNumber(Math.floor((lag / 60) % 60));
    let hour = formatDateNumber(parseInt(lag / 3600));
    if (hasDay) {
      hour = formatDateNumber(parseInt(Math.floor(lag % (3600 * 24)) / 3600));
    }
    let day = formatDateNumber(parseInt(lag / 3600 / 24));
    if (callBack) {
      callBack(hour, minite, second, day);
    }
  };
  timer();
  int = setInterval(timer, 1000);
  if (returnInt) {
    returnInt(int);
  }
}

export function formatDateNumber(n) {
  n = n.toString();
  return n[1] ? n : '0' + n;
}


/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value]; }
    // return value.toString().padStart(2, '0');
    if (key == 'm' || key == 'd') {
      return value.toString();
    } else {
      return value.toString().padStart(2, '0');
    }
  });
  return time_str;
}



export function isWeiXin() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

export function formatPriceNumber(num, cent, isThousand) {
  num = num.toString().replace(/\$|\,/g, '');
  cent = cent || 0;
  isThousand = isThousand || true;
  // 检查传入数值为数值类型
  if (isNaN(num))
    num = "0";
  // 获取符号e799bee5baa6e79fa5e98193e58685e5aeb931333337393634(正/负数)
  let sign = (num == (num = Math.abs(num)));
  num = Math.floor(num * Math.pow(10, cent) + 0.50000000001); // 把指定的小数位先转换成整数.多余的小数位四舍五入
  let cents = num % Math.pow(10, cent); // 求出小数位数值
  num = Math.floor(num / Math.pow(10, cent)).toString(); // 求出整数位数值
  cents = cents.toString(); // 把小数位转换成字符串,以便求小数位长度
  // 补足小数位到指定的位数
  while (cents.length < cent)
    cents = "0" + cents;
  if (isThousand) {
    // 对整数部分进行千分位格式化.
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  }
  if (cent > 0)
    return (((sign) ? '' : '-') + num + (cents > 0 ? '.' + cents : ''));
  else
    return (((sign) ? '' : '-') + num);
}

export function payAction(data, success, close, fail) {
  if(window.uni){
    window.uni.postMessage({
      data: {
        type: "pay",
        data: data
      },
    });
    window.payaction = (type) =>{
      if(type == "success"){
        success && success();
      }else if(type == "fail"){
        fail && fail();
      }
    }
    return
  }
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke(
      "getBrandWCPayRequest", {
      ...data
    },
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          if (success) success();
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          Toast('取消支付');
          if (close) close();
        } else if (res.err_msg === "get_brand_wcpay_request:fail") {
          Toast('支付失败');
          if (fail) fail();
        }
      }
    );
  }
}

export async function uploadImg(m, ossClient, cb) {
  const mer_id = store.getters.mer_id;
  try {
    const options = {
      callback: {
        url: `${process.env.VUE_APP_BASE_API}api/upload/oss/callback`,
        body:
          "filename=${object}&size=${size}&mimeType=${mimeType}&height=${imageInfo.height}&width=${imageInfo.width}" +
          "&attachment_name=${x:attachment_name}" +
          "&mer_id=${x:mer_id}",
        contentType: "application/x-www-form-urlencoded",
        customValue: {
          mer_id: `${mer_id}`,
        },
      },
    };

    const myDate = new Date();
    const imgType = m.name.split(".");
    let imgT = imgType[imgType.length - 1];
    imgT = imgT.toLowerCase();
    const imgName =
      myDate.getTime().toString() +
      parseInt(Math.random() * 1000000, 10).toString();
    const imgUrl = `${imgName}.${imgT}`;

    const result = await ossClient.value.put(
      `merchant/${mer_id || "000000"}/${myDate.getFullYear()}${myDate.getMonth() + 1
      }${myDate.getDate()}/image/${imgUrl}`,
      m,
      options
    );
    if (result && result.name) {
      cb(result);
    }
  } catch (e) {
    if (cb) cb();
  }
}

export function getBidMoney258(price) {
  const money = price.toString();
  let numCount = money.length;

  let firstNum = Number(money.substr(0, 1));
  let secondNum = money.substr(1, 1);

  if (numCount <= 2) {
    return 200;
  } else if (numCount <= 3) {
    if (firstNum < 2) {
      return 200;
    } else if (firstNum < 5) {
      return 500;
    } else if (firstNum < 8) {
      return 800;
    } else {
      return 1000;
    }
  }

  if (firstNum && secondNum == 0) {
    secondNum = 2;
  } else {
    if (secondNum < 2) {
      secondNum = 2;
    } else if (secondNum < 5) {
      secondNum = 5;
    } else if (secondNum < 8) {
      secondNum = 8;
    } else {
      firstNum += 1;
      secondNum = 0;
      if (`${firstNum}`.length > 1) {
        numCount += `${firstNum}`.length - 1;
      }
    }
  }
  return Number(prefixZero(`${firstNum}${secondNum}`, numCount));
}
export function getBidMoneyThree258(price) {
  const money = price.toString();
  const numCount = money.length;
  if (numCount < 5) {
    return getBidMoney258(price);
  }

  let firstNum = Number(money.substr(0, 1));
  let secondNum = money.substr(1, 1);
  let threeNum = money.substr(2, 1);

  if (threeNum < 2) {
    threeNum = Number(prefixZero(2, numCount - 2));
  } else if (threeNum < 5) {
    threeNum = Number(prefixZero(5, numCount - 2));
  } else if (threeNum < 8) {
    threeNum = Number(prefixZero(8, numCount - 2));
  } else {
    threeNum = Number(prefixZero(1, numCount - 1));
  }

  return Number(prefixZero(`${firstNum}${secondNum}`, numCount)) + threeNum;
}

export function prefixZero(num, n) {
  return (num + Array(n).join(0)).slice(0, n);
}

export function getPriceArea(price, priceData) {
  const data = priceData;
  let index = null;
  for (let i in data) {
    const m = data[i];
    if (price >= m.min && price < m.max) {
      index = i;
      break;
    }
  }
  if (index === null) {
    index = data.length - 1;
  }
  return data[index];
}

export function  settingPrice(price, priceData) {
  price = Number(price);
  const area = getPriceArea(price, priceData);
  let p = 0;
  switch (area.type) {
    case 1:
      p = price + area.price;
      break;
    case 2:
      p = getBidMoney258(price);
      break;
    case 3:
      p = getBidMoneyThree258(price);
      break;
    default:
      p = getBidMoney258(price);
      break;
  }

  return p;
}

export function returnWxPayType() {
  if (window.uni) {
    return 'wechatApp';
  }
  const uQ = localStorage.getItem('urlQuery');
  if (uQ) {
    return 'wechat_mini'
  } else {
    return 'wechat_h5'
  }
}

export function formatNumber (n) {
  let strNum = n.toString()
  if (strNum.indexOf('.') == -1) {
    strNum = strNum + '00'
  } else {
    if (strNum.length - 1 - strNum.indexOf('.') == 2) {
      strNum = strNum.replace('.', '')
    } else if (strNum.length - 1 - strNum.indexOf('.') == 1) {
      strNum = (strNum + '0').replace('.', '')
    }
  }
  return Number(strNum)
}

export function calculatePrice(price, rate) {
  return (formatNumber(price) * rate) / 100;
}

export function bigNumberTransform(value) {
  const newValue = ['', '', '']
  let fr = 1000
  let num = 3
  let text1 = ''
  let fm = 1
  while (value / fr >= 1) {
    fr *= 10
    num += 1
    // console.log('数字', value / fr, 'num:', num)
  }
  if (num <= 4) { // 千
    newValue[0] = parseInt(value / 1000) + ''
    newValue[1] = '千'
  } else if (num <= 8) { // 万
    text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
    // tslint:disable-next-line:no-shadowed-variable
    fm = text1 === '万' ? 10000 : 10000000
    if (value % fm === 0) {
      newValue[0] = parseInt(value / fm) + ''
    } else {
      newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    }
    newValue[1] = text1
  } else if (num <= 16) { // 亿
    text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
    text1 = (num - 8) / 4 > 1 ? '万亿' : text1
    text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
    // tslint:disable-next-line:no-shadowed-variable
    fm = 1
    if (text1 === '亿') {
      fm = 100000000
    } else if (text1 === '千亿') {
      fm = 100000000000
    } else if (text1 === '万亿') {
      fm = 1000000000000
    } else if (text1 === '千万亿') {
      fm = 1000000000000000
    }
    if (value % fm === 0) {
      newValue[0] = parseInt(value / fm) + ''
    } else {
      newValue[0] = parseFloat(value / fm).toFixed(2) + ''
    }
    newValue[1] = text1
  }
  if (value < 1000) {
    newValue[0] = value + ''
    newValue[1] = ''
  }
  return newValue.join('')
}