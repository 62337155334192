const usersRouter = {
  path: '/users',
  component: () => import('@/views/auction/index'),

  children: [{
    path: 'withdrawal',
    name: 'users_withdrawal',
    component: () => import('@/views/users/withdrawal'),
    meta: {
      titles: {
        '1': '申请退款',
        '2': '提现'
      },
      title: '申请退款'
    }
  }, {
    path: 'recharge',
    name: 'users_recharge',
    component: () => import('@/views/users/recharge'),
    meta: {
      title: '充值'
    }
  }, {
    path: 'follow_product',
    name: 'users_follow_product',
    component: () => import('@/views/users/followProduct'),
    meta: {
      title: '我的关注'
    }
  }, {
    path: 'bid_auction',
    name: 'users_bid_auction',
    component: () => import('@/views/users/bidProduct'),
    meta: {
      title: '我的出价'
    }
  }, {
    path: 'history',
    name: 'users_history_product',
    component: () => import('@/views/users/historyProduct'),
    meta: {
      title: '我的足迹'
    }
  }, {
    path: 'edit',
    name: 'users_edit_userInfo',
    component: () => import('@/views/users/edit_userInfo'),
    meta: {
      title: '修改资料'
    }
  }, {
    path: 'apply_verify',
    name: 'users_apply_verify',
    component: () => import('@/views/users/apply_verify'),
    meta: {
      title: '实名认证'
    }
  }, {
    path: 'apply_verify_status',
    name: 'users_apply_verify_finish',
    component: () => import('@/views/users/apply_verify_finish'),
    meta: {
      title: '实名认证'
    }
  }, {
    path: 'address_list',
    name: 'users_address_list',
    component: () => import('@/views/users/address_list'),
    meta: {
      title: '收货地址'
    }
  }, {
    path: 'address_detail',
    name: 'users_address_detail',
    component: () => import('@/views/users/address_detail'),
    meta: {
      title: '收货地址'
    }
  }, {
    path: 'agreement',
    name: 'users_agreement',
    component: () => import('@/views/users/agreement')
  }, {
    path: 'my_nft',
    name: 'users_my_nft',
    component: () => import('@/views/nftCollection/my_nft'),
    meta: {
      title: '我的数字藏品'
    }
  }, {
    path: 'my_nft_detail',
    name: 'users_my_nft_detail',
    component: () => import('@/views/nftCollection/my_nft_detail'),
    meta: {
      title: '区块链查证信息'
    }
  }, {
    path: 'give_nft',
    name: 'users_give_nft',
    component: () => import('@/views/nftCollection/give_nft'),
    meta: {
      title: '转赠'
    }
  }, {
    path: 'register_nft',
    name: 'users_register_nft',
    component: () => import('@/views/nftCollection/register_nft'),
    meta: {
      title: '开通数字藏品账户'
    }
  }],
}

export default usersRouter;