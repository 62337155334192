<template>
  <div class="auction">
    <template v-if="content.style == 1">
      <div
        class="auction-column1"
        :style="`padding: 0 ${facade.page_margin / 75}rem`"
      >
        <div
          v-for="(m, i) in content.renderData"
          :key="`a-c${i}`"
          class="item"
          :class="{ m0: i == 0 }"
          :style="{
            'background-color': facade.item_background,
            marginTop: (facade.top_margin / 75) + 'rem',
            borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
            borderTopRightRadius: (facade.top_radius / 75) + 'rem',
            borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
            borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
            boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
          }"
          @click="goDetail(m)"
        >
          <div class="img">
            <img
              :src="m.product.image + '!m640'"
              style="width: 100%;"
            >
            <div
              v-if="isCountDown"
              class="count-down-bg"
              :style="{ background: color_data.specialColor, borderBottomRightRadius: (facade.top_radius / 75) + 'rem' }"
            >
              <van-count-down
                :time="goTime(m.end_time)"
                format="HH:mm:ss"
              />
            </div>
          </div>
          <div
            class="desc flex-one"
            :style="{
              padding: `0.26666rem`,
              color: facade.sub_title_color
            }"
          >
            <div class="flex align-items-c justify-b mt5">
              <AuctionStatus
                :status="facade.status_type"
                :type="m.auction_status"
                :album_status="m.album_status || ''"
              />
              <div class="flex-one flex align-items-c justify-e">
                <div
                  v-if="m.auction_status > 2 && m.auction_status != 4"
                  class="time flex-one ml20"
                >{{pTime(m.end_time, '{m}-{d} {h}:{i}')}}</div>
                <div
                  v-if="m.auction_status == 1 && m.bid_num"
                  class="bid_num"
                >
                  <span>出价</span>
                  <span :style="{
                      fontSize: (facade.bid_num_size / 75) + 'rem',
                      color: facade.bid_num_color,
                      fontWeight: facade.bid_num_weight
                    }">{{m.bid_num}}</span>
                  <span>次</span>
                </div>
                <div class="price flex ml20">
                  <template v-if="m.auction_status != 4">
                    <div
                      class="lh"
                      v-if="(m.auction_status == 1 && !m.now_price) || m.auction_status == 2"
                    >起拍</div>
                    <div
                      class="lh"
                      v-else-if="m.auction_status > 4"
                    ><span style="position: relative;bottom:1px;">中拍价</span></div>
                    <AmountSymbol
                      :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }"
                      type="1"
                      :price="!m.now_price ? m.start_price : m.now_price"
                      :merchant="m.merchant"
                    ></AmountSymbol>
                    <!-- <span :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }">￥{{ formatPriceNumber((!m.now_price ? m.start_price : m.now_price), 2) }}</span> -->
                  </template>
                </div>
              </div>
            </div>
            <div class="bottom flex align-items-c justify-b mt10">
              <div
                class="name flex-one mr20"
                :style="{
                  fontSize: (facade.title_size / 75) + 'rem',
                  color: facade.title_color,
                  fontWeight: facade.title_weight
                }"
              >{{m.lot ? 'LOT' + m.lot + ' ' : ''}}{{ m.product.store_name }}</div>
              <div
                v-if="m.auction_status == 1"
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >出价</div>
              <div
                v-if="m.auction_status == 4"
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >洽购</div>
            </div>
            <div
              style="margin-top: 0.133333rem;"
              v-if="m.product.store_info"
              class="desc-show line1"
            >{{ m.product.store_info }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="content.style == 2">
      <div
        class="auction-column auction-column2 flex"
        :style="{ paddingLeft: (facade.page_margin / 75) + 'rem', paddingRight: ((facade.left_margin + facade.page_margin) / 75) + 'rem' }"
      >
        <div
          v-for="(item, i) in renderList"
          :key="`auctionC${i}`"
          :style="{
            flex: 1,
            paddingLeft: (facade.left_margin / 75) + 'rem',
            paddingBottom: (20 / 75) + 'rem'
          }"
        >
          <div
            v-for="(m, index) in item"
            :key="`auctionD${index}`"
            class="item"
            :class="{ 'm0': index == 0 }"
            :style="{
              backgroundColor: facade.item_background,
              marginTop: (facade.top_margin / 75) + 'rem',
              borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
              borderTopRightRadius: (facade.top_radius / 75) + 'rem',
              borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
              borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
              boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
              width: ((750 - ((facade.left_margin + facade.page_margin) * 2) - facade.left_margin) / 150) + 'rem',
              overflow: 'hidden'
            }"
            @click="goDetail(m)"
          >
            <div class="img">
              <van-image
                :src="m.product.image + '!m640'"
                style="width: 100%;display: block;"
                :style="listTwoImgStyle"
                :fit="facade.img_style == 3 ? 'cover' : 'contain'"
              />
              <div
                v-if="isCountDown"
                class="count-down-bg"
                :style="{ background: color_data.specialColor, borderBottomRightRadius: (facade.top_radius / 75) + 'rem' }"
              >
                <van-count-down
                  :time="goTime(m.end_time)"
                  format="HH:mm:ss"
                />
              </div>
            </div>
            <div
              class="desc"
              :style="{
                padding: `0.26666rem ${facade.text_margin / 75}rem`,
                color: facade.sub_title_color
              }"
            >
              <div class="flex align-items-c justify-b">
                <AuctionStatus
                  :status="facade.status_type"
                  :type="m.auction_status"
                  :album_status="m.album_status || ''"
                />
                <div
                  v-if="m.auction_status > 2 && m.auction_status != 4"
                  class="time"
                >{{pTime(m.end_time, '{m}-{d} {h}:{i}')}}</div>
              </div>
              <div
                class="name"
                :style="{
                  fontSize: (facade.title_size / 75) + 'rem',
                  color: facade.title_color,
                  fontWeight: facade.title_weight
                }"
              >{{m.lot ? 'LOT' + m.lot + ' ' : ''}}{{ m.product.store_name }}</div>
              <div
                style="margin-top: 0.133333rem;"
                v-if="m.product.store_info"
                class="desc-show line1"
              >{{ m.product.store_info }}</div>
              <div
                v-if="m.auction_status != 4"
                class="bottom flex justify-b"
              >
                <div class="price flex">
                  <div
                    v-if="(m.auction_status == 1 && !m.now_price) || m.auction_status == 2"
                    class="mr5 lh"
                  >起拍</div>
                  <div
                    v-else-if="m.auction_status > 4"
                    class="mr5 lh"
                  >中拍价</div>
                  <AmountSymbol
                    :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }"
                    :price="!m.now_price ? m.start_price : m.now_price"
                    :merchant="m.merchant"
                  ></AmountSymbol>
                </div>
                <div
                  v-if="m.auction_status == 1 && m.bid_num"
                  class="bid_num"
                >
                  <span>出价</span>
                  <span :style="{
                      fontSize: (facade.bid_num_size / 75) + 'rem',
                      color: facade.bid_num_color,
                      fontWeight: facade.bid_num_weight
                    }">5</span>
                  <span>次</span>
                </div>
              </div>
              <div
                v-else
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >洽购</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="content.style == 3">
      <div
        class="auction-column auction-column2 flex flex-wrap"
        :style="{ paddingLeft: (facade.page_margin / 75) + 'rem', paddingRight: ((facade.page_margin) / 75) + 'rem' }"
      >
        <div
          v-for="(m, i) in content.renderData"
          :key="`auctionC${i}`"
          :style="{
            width: listThreeWidth,
            paddingLeft: (facade.left_margin / 75) + 'rem',
          }"
        >
          <div
            class="item"
            :class="{ 'm0': i < 3 }"
            :style="{
              backgroundColor: facade.item_background,
              marginTop: (facade.top_margin / 75) + 'rem',
              borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
              borderTopRightRadius: (facade.top_radius / 75) + 'rem',
              borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
              borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
              boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
              width: listThreeWidth
            }"
            @click="goDetail(m)"
          >
            <div class="img">
              <van-image
                :src="m.product.image + '!m640'"
                style="display: block;"
                :style="{ width: listThreeWidth, height: listThreeWidth }"
                :fit="facade.img_style == 3 ? 'cover' : 'contain'"
              />
            </div>
            <div
              class="desc"
              :style="{
                padding: `0.26666rem ${facade.text_margin / 75}rem`,
                color: facade.sub_title_color
              }"
            >
              <div class="flex align-items-c justify-b">
                <AuctionStatus
                  :status="facade.status_type"
                  :type="m.auction_status"
                  :album_status="m.album_status || ''"
                />
              </div>
              <div
                class="name"
                :style="{
                  fontSize: (facade.title_size / 75) + 'rem',
                  color: facade.title_color,
                  fontWeight: facade.title_weight
                }"
              >{{m.lot ? 'LOT' + m.lot + ' ' : ''}}{{ m.product.store_name }}</div>
              <div
                v-if="m.auction_status != 4"
                class="bottom flex align-items-c justify-b"
              >
                <div class="price flex">
                  <div
                    v-if="(m.auction_status == 1 && !m.now_price) || m.auction_status == 2"
                    class="mr5 lh"
                  >起拍</div>
                  <div
                    v-else-if="m.auction_status > 4"
                    class="mr5 lh"
                  >中拍价</div>
                  <AmountSymbol
                    :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }"
                    :price="!m.now_price ? m.start_price : m.now_price"
                    :merchant="m.merchant"
                  ></AmountSymbol>
                </div>
              </div>
              <div
                v-else
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >洽购</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="content.style == 4">
      <div
        class="auction-column-line"
        :style="`padding: 0 ${facade.page_margin / 75}rem`"
      >
        <div
          v-for="(m, i) in content.renderData"
          :key="`a-c${i}`"
          class="item flex"
          :class="{ m0: i == 0 }"
          :style="{
            'background-color': facade.item_background,
            marginTop: (facade.top_margin / 75) + 'rem',
            borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
            borderTopRightRadius: (facade.top_radius / 75) + 'rem',
            borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
            borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
            boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
          }"
          @click="goDetail(m)"
        >
          <div
            class="img"
            :style="{ width: (facade.img_width / 75) + 'rem', height: (facade.img_height / 75) + 'rem' }"
          >
            <van-image
              style="width: 100%;height: 100%;"
              :src="m.product.image + '!m640'"
              :fit="facade.img_style === 2 ? 'contain' : 'cover'"
            />
            <div
              v-if="isCountDown"
              class="count-down-bg"
              :style="{ background: color_data.specialColor, borderBottomRightRadius: (facade.top_radius / 75) + 'rem' }"
            >
              <van-count-down
                :time="goTime(m.end_time)"
                format="HH:mm:ss"
              />
            </div>
          </div>
          <div
            class="desc flex-one"
            :style="{
              padding: `${facade.text_margin / 75}rem 0.26666rem `,
              color: facade.sub_title_color,
              overflow: 'hidden'
            }"
          >
            <div
              class="name"
              :style="{
                fontSize: (facade.title_size / 75) + 'rem',
                color: facade.title_color,
                fontWeight: facade.title_weight,
                height: m.product.store_info ? '0.533333rem' : '1.06666rem',
                '-webkit-line-clamp': m.product.store_info ? 1 : 2
              }"
            >{{m.lot ? 'LOT' + m.lot + ' ' : ''}}{{ m.product.store_name }}</div>
            <div
              v-if="m.product.store_info"
              class="desc-show line1"
            >{{ m.product.store_info }}</div>
            <div class="flex align-items-c justify-b mt5">
              <AuctionStatus
                :status="facade.status_type"
                :type="m.auction_status"
                :album_status="m.album_status || ''"
              />
              <div
                v-if="m.auction_status > 2 && m.auction_status != 4"
                class="time"
              >{{pTime(m.end_time, '{m}-{d} {h}:{i}')}}</div>
              <div
                v-if="m.auction_status == 1 && m.bid_num"
                class="bid_num"
              >
                <span>出价</span>
                <span :style="{
                      fontSize: (facade.bid_num_size / 75) + 'rem',
                      color: facade.bid_num_color,
                      fontWeight: facade.bid_num_weight
                    }">{{m.bid_num}}</span>
                <span>次</span>
              </div>
            </div>
            <div class="bottom flex align-items-c justify-b">
              <div class="price flex">
                <div
                  v-if="(m.auction_status == 1 && !m.now_price) || m.auction_status == 2"
                  class="mr5 lh"
                >起拍</div>
                <div
                  v-else-if="m.auction_status > 4"
                  class="mr5 lh"
                >中拍价</div>
                <AmountSymbol
                  :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }"
                  :price="!m.now_price ? m.start_price : m.now_price"
                  :merchant="m.merchant"
                ></AmountSymbol>
              </div>
              <div
                v-if="m.auction_status == 1"
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >出价</div>
              <div
                v-if="m.auction_status == 4"
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >洽购</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="content.style == 5">
      <div
        class="overflow-x-auto"
        :style="`padding: 0 ${facade.page_margin / 75}rem`"
      >
        <div
          class="auction-column flex"
          style="overflow: visible;padding-bottom: 0.26666rem;"
        >
          <div
            v-for="(m, i) in content.renderData"
            :key="`a-c${i}`"
            class="item"
            :style="{
              'background-color': facade.item_background,
              marginLeft: (facade.left_margin / 75) + 'rem',
              borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
              borderTopRightRadius: (facade.top_radius / 75) + 'rem',
              borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
              borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
              boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
              width: (facade.img_width / 75) + 'rem'
            }"
            @click="goDetail(m)"
          >
            <div
              class="img"
              :style="{ width: (facade.img_width / 75) + 'rem', height: (facade.img_height / 75) + 'rem' }"
            >
              <van-image
                style="width: 100%;height: 100%;"
                :src="m.product.image + '!m640'"
                :fit="facade.img_style === 2 ? 'contain' : 'cover'"
              />
              <div
                v-if="isCountDown"
                class="count-down-bg"
                :style="{ background: color_data.specialColor, borderBottomRightRadius: (facade.top_radius / 75) + 'rem' }"
              >
                <van-count-down
                  :time="goTime(m.end_time)"
                  format="HH:mm:ss"
                />
              </div>
            </div>
            <div
              class="desc"
              :style="{
              padding: `0.26666rem ${facade.text_margin / 75}rem`,
              color: facade.sub_title_color
            }"
            >
              <div class="flex align-items-c justify-b">
                <AuctionStatus
                  :status="facade.status_type"
                  :type="m.auction_status"
                  :album_status="m.album_status || ''"
                />
                <div
                  v-if="m.auction_status > 2 && m.auction_status != 4"
                  class="time"
                >{{pTime(m.end_time, '{m}-{d} {h}:{i}')}}</div>
              </div>
              <div
                class="name"
                :style="{
                fontSize: (facade.title_size / 75) + 'rem',
                color: facade.title_color,
                fontWeight: facade.title_weight
              }"
              >{{m.lot ? 'LOT' + m.lot + ' ' : ''}}{{ m.product.store_name }}</div>
              <div
                v-if="m.product.store_info"
                class="desc-show line1"
              >{{ m.product.store_info }}</div>
              <div
                v-if="m.auction_status != 4"
                class="bottom flex justify-b"
              >
                <div class="price flex">
                  <div
                    v-if="(m.auction_status == 1 && !m.now_price) || m.auction_status == 2"
                    class="mr5 lh"
                  >起拍</div>
                  <div
                    v-else-if="m.auction_status > 4"
                    class="mr5 lh"
                  >中拍价</div>
                  <AmountSymbol
                    :style="{
                        fontSize: (facade.price_size / 75) + 'rem',
                        color: facade.price_color,
                        fontWeight: facade.price_weight
                      }"
                    :price="!m.now_price ? m.start_price : m.now_price"
                    :merchant="m.merchant"
                  ></AmountSymbol>
                </div>
                <div
                  v-if="m.auction_status == 1 && m.bid_num"
                  class="bid_num"
                >
                  <span>出价</span>
                  <span :style="{
                      fontSize: (facade.bid_num_size / 75) + 'rem',
                      color: facade.bid_num_color,
                      fontWeight: facade.bid_num_weight
                    }">{{m.bid_num}}</span>
                  <span>次</span>
                </div>
              </div>
              <div
                v-else
                class="btn"
                :style="{
                  border: '0.026667rem solid ' + facade.btn_bg_color,
                  color: facade.btn_bg_color,
                  borderRadius: (facade.btn_radius / 75) + 'rem'
                }"
              >洽购</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, toRefs, computed } from "vue";
import { useStore } from "vuex";
import AuctionStatus from "./AuctionStatus";
import { parseTime, formatPriceNumber, isWeiXin } from "@/utils/util";
import { Image as VanImage, CountDown } from "vant";

export default {
  components: {
    AuctionStatus,
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
  },
  props: {
    content: [Object, String],
    facade: [Object, String],
    isCountDown: Boolean,
    jump: String
  },
  setup(props) {
    const { content, facade, jump } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };

    const goTime = (time) => {
      const d = new Date();
      return (time - Date.parse(d) / 1000) * 1000;
    };

    const renderList = computed(() => {
      if (content.value.renderData && content.value.style === 2) {
        const l = [];
        const r = [];
        if (facade.value.img_style === 1) {
          let lH = 0;
          let rH = 0;
          content.value.renderData.forEach((m) => {
            if (lH <= rH) {
              l.push(m);
              if(m.product.imageSize){
                lH += m.product.imageSize.height / m.product.imageSize.width;
              } else {
                lH += 200;
              }
            } else {
              r.push(m);
              if(m.product.imageSize){
                rH += m.product.imageSize.height / m.product.imageSize.width;
              } else {
                rH += 200;
              }
            }
          });
        } else {
          content.value.renderData.forEach((m, i) => {
            if (i % 2 === 0) {
              l.push(m);
            } else {
              r.push(m);
            }
          });
        }
        return [l, r];
      } else {
        return [];
      }
    });

    const listTwoImgStyle = computed(() => {
      const marginWidth = facade.value.left_margin * 3;
      const pageMargin = facade.value.page_margin * 2;
      if (facade.value.img_style === 1) {
        return {
          width: (750 - marginWidth - pageMargin) / 150 + "rem",
          height: "auto",
        };
      } else {
        return {
          width: (750 - marginWidth - pageMargin) / 150 + "rem",
          height: (750 - marginWidth - pageMargin) / 150 + "rem",
        };
      }
    });

    const listThreeWidth = computed(() => {
      const marginWidth = facade.value.left_margin * 4;
      const pageMargin = facade.value.page_margin * 2;
      return (750 - marginWidth - pageMargin) / 225 + "rem";
    });

    const goDetail = (item) => {
      const isWx = isWeiXin();
      if (isWx) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: "/pages/auctionDetail/index?id=" + item.product_id,
            });
          } else {
            goDetailAction(item);
          }
        });
      } else {
        goDetailAction(item);
      }
    };

    const color_data = computed(() => {
      return store.getters.color_data;
    });

    const goDetailAction = (item) => {
      localStorage.setItem("auctionDetail", JSON.stringify(item));
      let action = 'push';
      if (jump.value == 2) {
        action = 'replace';
      }
      router[action]({
        path: "/pages/auctionDetail/index",
        query: { id: item.product_id },
      });
    };

    return {
      pTime,
      goDetail,
      formatPriceNumber,
      goTime,
      color_data,

      renderList,
      listTwoImgStyle,
      listThreeWidth,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 112px;
  height: 48px;
  margin-top: 10px;
  font-size: 24px;
  text-align: center;
  line-height: 48px;
  font-weight: 500;
}
.time {
  font-size: 24px;
  position: relative;
  bottom: -2px;
}
.name {
  margin-top: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
  max-height: 80px;
}
.price {
  .lh {
    line-height: 40px;
  }
  span {
    margin-left: 6px;
    font-family: NUM;
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
.auction-column {
  overflow: hidden;
  .item {
    width: 300px;
    overflow: hidden;
    flex-shrink: 0;
    .img {
      width: 300px;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e1e1e1;
    }

    .desc {
      box-sizing: border-box;

      .name {
        height: 80px;
      }

      .bottom {
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
}
.auction-column2 {
  .item {
    width: 100%;
    .img {
      width: 100%;
      height: auto;
    }
  }
}

.auction-column-line {
  .item {
    font-size: 24px;
    .img {
      width: 240px;
      height: 240px;
      background: #e1e1e1;
    }
    .desc {
      .name {
        margin-top: 0;
      }

      .bottom {
        height: 50px;
        margin-top: 20px;
        .btn {
          width: 130px;
          height: 50px;
          line-height: 50px;
          margin: 0;
        }
      }
    }
  }
}

.auction-column1 {
  .item {
    font-size: 24px;
    overflow: hidden;

    .name {
      margin-top: 0;
    }

    .btn {
      width: 130px;
      height: 60px;
      line-height: 60px;
    }
  }
  img {
    display: block;
  }
}
.desc-show {
  font-size: 24rpx;
  line-height: 40rpx;
}

.img {
  position: relative;
  .count-down-bg {
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px 10px;
  }
}
</style>
<style scoped>
.img .count-down-bg .van-count-down {
  font-size: 22px;
  color: #fff;
}
</style>