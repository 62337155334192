<template>
  <div
    class="title"
    :style="{ backgroundColor: facade.background_color, padding: `0 ${facade.page_padding / 75}rem` }"
  >
    <div
      class="title-style1"
      :class="{ 'item-align-center': content.style <= 3 }"
      catchtap="goPageAction"
      data-jump="1"
      :data-link="content.morelink.link || ''"
    >
      <div class="flex-one" :class="{ 'item-align-center': content.style === 1 }">
        <div
          class="title-content item-align-center"
          :class="{ 'item-flex-center': content.style === 4 || content.style === 5 }"
          :style="{ color: facade.title_color, fontSize: `${facade.title_font_size / 75}rem` }"
        >
          <img
            v-if="content.title_img"
            class="img-block"
            :src="content.title_img + '!120a'"
            :style="{
              marginRight: '0.06666rem',
              width: ((facade.title_font_size + 8) / 75) + 'rem',
              height: ((facade.title_font_size + 8) / 75) + 'rem'
            }"
          >
          <div>{{content.title}}</div>
        </div>
        <div
          v-if="content.style === 1 || content.style === 3"
          class="subtitle-content"
          :class="{ 'item-align-center flex-one ml10 mt2': content.style === 1 }"
          :style="{ color: facade.subtitle_color, fontSize: (facade.subtitle_font_size / 75) + 'rem' }"
        >
          {{content.subtitle}}
        </div>
      </div>
      <div
        v-if="content.style !== 4"
        class="item-align-center more-content"
        :class="{ 'item-flex-center': content.style === 5 }"
        :style="{ color: facade.more_color, fontSize: (facade.more_font_size / 75) + 'rem' }"
      >
        <div class="moretitle">{{content.moretitle}}</div>
        <img
          v-if="(content.style === 1 || content.style === 3) && facade.more_img === 1"
          class="img-block"
          src="https://artimg2.artart.cn/image/20211013/a7355b2ef09b11794314a9f986be9f35.png"
          :style="{ width: (facade.more_font_size / 75) + 'rem', height: (facade.more_font_size / 75) + 'rem' }"
        >
        <img
          v-else-if="(content.style === 1 || content.style === 3) && facade.more_img === 2"
          class="img-block"
          src="https://artimg2.artart.cn/image/20211013/3ebdd18efdf9f99cd873340c91c239fc.png"
          :style="{ width: (facade.more_font_size / 75) + 'rem', height: (facade.more_font_size / 75) + 'rem' }"
        >
        <img
          v-if="content.style === 5"
          class="img-block"
          src="https://artimg2.artart.cn/image/20211013/45a77dffada667126effa371b1c1c6c9.png"
          :style="{ width: (facade.more_font_size / 75) + 'rem', height: (facade.more_font_size / 75) + 'rem' }"
        >
      </div>

      <!-- <button
        class="hide-button"
        open-type="share"
        wx:if="{{content.morelink.link == 'open_share'}}"
      ></button>
      <button
        class="hide-button"
        open-type="contact"
        wx:elif="{{content.morelink.link == 'contact'}}"
      ></button> -->
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    content: Object,
    facade: Object,
  },
  setup() {
    const router = useRouter();

    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf('pdf?url=') === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == 'writeOff') {
          // app.hxkqAction();
        } else if (item.link.indexOf('phone?phone=') === 0) {
          const phone = item.link.split('=')[1];
          window.location.href = 'tel://' + phone;
        } else if (item.link == 'open_share' || item.link == 'contact') {} else {
          let jump = 'push';
          if (item.jump == 2) {
            jump = 'replace'
          }
          const queryIndex = item.link.indexOf('?');
          const query = {};
          if (queryIndex !== 0) {
            const q = item.link.split('?');
            if (q[1]) {
              const qList = q[1].split('&');
              qList.forEach(m => {
                const mQ = m.split('=');
                if (mQ[0] && mQ[1]) {
                  query[mQ[0]] = mQ[1]
                }
              })
            }
          }
          router[jump]({ path: item.link, query: query })
        }
      } 
    };

    return {
      goPageAction
    }
  },
});
</script>

<style lang="scss" scoped>
.title .title-style1 {
  width: 100%;
  padding: 26px 0;
  position: relative;
}

.title .title-style1 .title-content {
  font-weight: 500;
}


</style>
