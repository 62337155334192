<template>
  <div
    class="blank item-flex-center"
    :style="{ height: ((facade.height * 2) / 75) + 'rem', background: facade.background_color }"
  >
    <div
      class="line"
      v-if="content.has_line"
      :style="{ height: ((facade.line_height * 2) / 75) + 'rem', width: ((facade.line_width * 2) / 75) + 'rem', background: facade.line_color }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
    facade: Object,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.blank{
  width: 100vw;
}
</style>
