const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  uname: state => state.user.uname,
  mer_id: state => state.user.mer_id,
  mer_name: state => state.user.mer_name,
  phone: state => state.user.phone,
  uid: state => state.user.uid,
  mark: state => state.user.mark,
  banner_style: state => state.user.banner_style,
  button_style: state => state.user.button_style,
  left_button_style: state => state.user.left_button_style,
  right_button_style: state => state.user.right_button_style,
  footer_data: state => state.user.footer_data,
  img_raduis_style: state => state.user.img_raduis_style,
  color_data: state => state.user.color_data,
  tag_style: state => state.user.tag_style,
  title_style: state => state.user.title_style,
  account: state => state.user.account,
  entrust_account: state => state.user.entrust_account,
  merchantInfo: state => state.user.merchantInfo,
  isVerify: state => state.user.isVerify,
  nft_info: state => state.user.nft_info,
  is_follow_official_account: state => state.user.is_follow_official_account,
  qrcode_url: state => state.user.qrcode_url
};
export default getters;
