import request from './request';

/** 
 * 订单列表
 */
export function orderList(data) {
  return request.get(`order/list`, data);
}

/** 
 * 订单列表
 */
export function orderDetail(id) {
  return request.get(`/order/detail/${id}`);
}

/** 
 * 售后列表
 */
export function refundList(data) {
  return request.get(`refund/list`, data);
}

/** 
 * 售后详情
 */
 export function refundDetail(id) {
  return request.get(`order/refund/info/${id}`);
}
/** 
 * 售后发货
 */
export function deliverGoods(id, data) {
  return request.post(`order/refund/deliverGoods/${id}`, data);
}
/** 
 * 取消售后
 */
export function cancelRefund(id) {
  return request.post(`/order/refund/cancel/${id}`);
}

export function orderSettlementInfo(data) {
  return request.get('order/getAuctionOrderSettlementInfo', data)
}

export function payAuctionOrder(data) {
  return request.post('/order/payAuctionOrder', data)
}

export function applyRefund(id, data) {
  return request.post(`order/refund/apply/${id}`, data)
}
// 确认收货
export function orderReceived(id) {
  return request.post(`order/received/${id}`)
}
// 物流信息
export function expressInfo(id) {
  return request.get(`order/express/${id}`)
}
// 售后物流信息
export function expressRefundInfo(id) {
  return request.get(`order/refund/express/${id}`)
}

/** 
 * 获取自提地址
 */
export function takeInfoApi(data) {
  return request.get(`order/takeInfo`, data);
}

/** 
 * 数字藏品预下单接口
 */
 export function getNftOrderSettlementInfoApi(data) {
  return request.get(`order/getNftOrderSettlementInfo`, data);
}