const orderRouter = {
  path: '/order',
  component: () => import('@/views/order/index'),
  redirect: `/order/detail/index`,

  children: [{
    path: 'list',
    name: 'auctionOrderList',
    component: () => import('@/views/order/orderList'),
    meta: {
      title: '我的订单'
    }
  }, {
    path: 'detail',
    name: 'auctionOrderDetail',
    component: () => import('@/views/order/orderDetail'),
    meta: {
      title: '订单详情'
    }
  }, {
    path: 'set_client',
    name: 'auctionOrder_set_client',
    component: () => import('@/views/order/set_client'),
    meta: {
      title: '委托提货'
    }
  }, {
    path: 'apply_refund',
    name: 'auctionOrder_apply_refund',
    component: () => import('@/views/order/apply_refund'),
    meta: {
      title: '申请售后'
    }
  }, {
    path: 'refund_detail',
    name: 'auctionOrder_refund_detail',
    component: () => import('@/views/order/refund_detail'),
    meta: {
      title: '售后详情'
    }
  }],
}

export default orderRouter;