<template>
  <div
    class="hot-album-b"
    :class="{ 'hui': m.album_status == 3 }"
    :style="{
      height: (facade.status_bar_height / 75) + 'rem',
      background: `linear-gradient(to right, ${facade.status_bar_bg}aa, ${facade.status_bar_bg})`
    }"
  >
    <div
      class="hot-album-bl-bg"
      :style="{ background: facade.status_bar_bg, height: (facade.status_bar_height / 75) + 'rem' }"
    >
      <div
        class="hot-album-bl-text"
        :style="{ height: (facade.status_bar_height / 75) + 'rem', color: facade.status_bar_color }"
      >

        <template v-if="m.album_type == 1">
          <img
            v-if="facade.status_bar_color === 'white'"
            src="https://artimg2.artart.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png"
          >
        </template>
        <template v-if="m.album_type == 2">
          <img
            v-if="facade.status_bar_color === 'white'"
            src="https://artimg2.artart.cn/image/20220409/105b8cc0d98088c7326b7df8078c80c4.png"
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220409/cd3b2883d16f3ebf2ef53687c5713ca8.png"
          >
        </template>
        <template v-if="m.album_type == 3">
          <img
            v-if="facade.status_bar_color === 'white'"
            src="https://artimg2.artart.cn/image/20220409/6c596515f8285ea5ae6887253ead021a.png"
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220409/6d9ac35576b54aab0da859dc0e90df41.png"
          >
        </template>
        <template v-if="m.album_type == 4">
          <img
            v-if="facade.status_bar_color === 'white'"
            src="https://artimg2.artart.cn/image/20220409/d4f7c277be61d79050175f94af4ade75.png"
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220409/2f5821e3ecadcbbfa06cc87c0f302669.png"
          >
        </template>
        <!-- <img :src="facade.status_bar_color === 'white' ? 'https://artimg2.artart.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png' : 'https://artimg2.artart.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png'"> -->
        <div>
          <span v-if="m.album_type == 1">限时拍</span>
          <span v-else-if="m.album_type == 2">即时拍</span>
          <span v-else-if="m.album_type == 3">同步拍</span>
          <span v-else-if="m.album_type == 4">即刻拍</span>
          ·
          <span><template v-if="m.album_status == 1">预展中</template><template v-else-if="m.album_status == 2">进行中</template><template v-else-if="m.album_status == 3">已结束</template></span>
        </div>
      </div>
      <div
        class="hot-album-bl-Yinying-wai"
        :style="{ height: (facade.status_bar_height / 75) + 'rem', width: ((facade.status_bar_height + 40) / 75) + 'rem' }"
      >
        <div
          class="hot-album-bl-Yinying"
          :class="{ 'end': m.album_status == 3 }"
          :style="{ background: facade.status_bar_bg, height: (facade.status_bar_height / 75) + 'rem', width: (facade.status_bar_height / 75) + 'rem' }"
        />
      </div>
    </div>
    <div
      class="hot-album-br"
      :style="{ color: facade.status_bar_color }"
    >
      <template v-if="m.album_status == 1">{{ m.stime_format[0] }} 开拍</template>
      <template v-else-if="m.album_status == 2 && m.album_type == 4">正在拍卖中</template>
      <template v-else-if="m.album_status == 2 && m.album_type != 1">当前{{m.now_auction_order_num}}/{{m.auction_num}}件</template>
      <template v-else-if="m.album_status == 2">{{ m.first_end_time_format ? m.first_end_time_format[0] : '' }} {{m.end_type == 1 ? '结拍' : '开始结拍'}}</template>
      <template v-else-if="m.album_status == 3">{{ m.etime_format[3] }}</template>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils/util";

export default {
  props: {
    m: Object,
    facade: Object,
  },
  setup() {
    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };

    return {
      pTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.hot-album-b {
  height: 60px;
  background: linear-gradient(90deg, #d04747 0%, #a32222 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
}

.hot-album-bl-bg {
  width: 260px;
  height: 60px;
  position: relative;
  font-weight: 500;
  color: #ffffff;
  font-size: 24px;
  border-bottom-left-radius: 8px;
}
.hot-album-bl-text {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 260px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.hot-album-b.hui {
  background: linear-gradient(90deg, #b2b2b2 0%, #909090 100%) !important;
}
.hot-album-b.hui .hot-album-bl-bg {
  background: linear-gradient(90deg, #b2b2b2 0%, #909090 100%) !important;
}
.hot-album-b.hui .hot-album-bl-text {
  background: #999 !important;
}
.hot-album-bl-Yinying-wai {
  position: absolute;
  left: 228px;
  top: 0;
  z-index: 8;
  width: 110px;
  height: 60px;
  overflow: hidden;
}
.hot-album-bl-Yinying {
  width: 60px;
  height: 60px;
  transform: rotate(-45deg);
}
.hot-album-bl-Yinying.end {
  background: #999 !important;
}

.hot-album-br {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  line-height: 34px;
  margin-right: 20px;
}

.hot-album-bl-bg img {
  width: 26px;
  height: 26px;
  margin-left: 16px;
  margin-right: 12px;
}
.no-img {
  width: 100%;
  height: 100%;
  background: #eaeef4;
}
</style>