<template>
  <div
    class="navigation"
    :style="{ backgroundColor: facade.isFixed ? (facade.background_color || '#fff') : facade.background_color }"
  >
    <div
      class="nav"
      :class="{ 'item-align-center': content.style === 2, 'display-flex': content.style === 1, 'justify-center': facade.nav_style === 2 }"
      :style="{ paddingLeft: `${facade.page_padding / 75}rem` }"
    >
      <div
        class="item"
        :class="{ 'ml0': index === 0, 'flex-one': facade.nav_style === 2 }"
        v-for="(item, index) in content.data"
        :key="index"
        :style="{ marginLeft: facade.nav_style === 1 ? `${facade.item_padding / 75}rem` : 0 }"
        @click="goPageAction"
        :data-jump="content.jump_type"
        :data-link="item.link.link || ''"
      >
        <div
          class="img"
          v-if="item.url && content.style === 1"
        >
          <img
            class="img-block"
            :src="item.url"
            alt=""
            :style="{ width: `${(facade.img_width || 120) / 75}rem`, height: `${(facade.img_height || 120) / 75}rem` }"
          >
        </div>
        <div
          class="item-flex-center"
          v-if="content.style === 1 && !item.url"
        >
          <div
            class="img-view item-flex-center"
            :style="{ width: `${(facade.img_width || 120) / 75}rem`, height: `${(facade.img_height || 120) / 75}rem` }"
          >
            <img
              class="img-block"
              src="https://artimg2.artart.cn/image/20211019/69645063154569e8626ff0ade964c8c2.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="txt"
          :style="{
            color: (facade.is_hover && index == facade.hover_index - 1 ? facade.hover_color : facade.text_color),
            fontWeight: (facade.is_hover && index == facade.hover_index - 1 ? 500 : facade.text_style),
            fontSize: ((parseInt(facade.text_size, 10) / 75) + 'rem') }"
        >{{ item.title }}
          <div
            v-if="facade.is_hover && index == facade.hover_index - 1"
            class="bg"
            :style="{ background: facade.hover_color }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { toRefs, defineComponent } from "vue";

export default defineComponent({
  props: {
    content: Object,
    facade: Object,
  },
  setup() {
    const router = useRouter();

    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf("pdf?url=") === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == "writeOff") {
          // app.hxkqAction();
        } else if (item.link.indexOf("phone?phone=") === 0) {
          const phone = item.link.split("=")[1];
          window.location.href = "tel://" + phone;
        } else if (item.link == "open_share" || item.link == "contact") {
        } else {
          let jump = "push";
          if (item.jump == 2) {
            jump = "replace";
          }
          const queryIndex = item.link.indexOf("?");
          const query = {};
          if (queryIndex !== 0) {
            const q = item.link.split("?");
            if (q[1]) {
              const qList = q[1].split("&");
              qList.forEach((m) => {
                const mQ = m.split("=");
                if (mQ[0] && mQ[1]) {
                  query[mQ[0]] = mQ[1];
                }
              });
            }
          }
          router[jump]({ path: item.link, query: query });
        }
      }
    };

    return {
      goPageAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.navigation {
  overflow-x: auto;
}

.nav {
  min-height: 96px;
}

.item {
  flex-shrink: 0;
  position: relative;
}

.txt {
  text-align: center;
  line-height: 72px;
  position: relative;

  .bg {
    position: absolute;
    width: 40px;
    height: 6px;
    left: 50%;
    margin-left: -20px;
    bottom: 0;
    border-radius: 3px;
  }
}

.img {
  display: flex;
  justify-content: center;
}

.img .img-block {
  width: 120px;
  height: 120px;
  display: block;
}

.img-view {
  width: 120px;
  height: 120px;
  background: #eaeef4;
}

.img-view .img-block {
  width: 48px;
  height: 48px;
  display: block;
}
</style>
