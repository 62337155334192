<template>
  <div class="all-top">
    <div
      class="common-view common-pay wechat-pay flex align-items-c mt32"
      style="margin-top: 0;"
      @click="changePayTypeAction('wx')"
    >
      <img
        class="icon"
        src="https://artimg2.artart.cn/image/20220122/6e301c956b0ab7b558b6ac3577ef102d.png"
        alt=""
      >
      <div class="flex-one ml16 ft14">微信支付</div>
      <img
        v-if="modelValue == 'wx'"
        src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
      >
      <img
        v-else
        src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
      >
    </div>
    <div
      class="common-view common-pay wechat-pay mt44"
      @click="changePayTypeAction('bank_transfer')"
    >
      <div class="flex align-items-c">
        <img
          class="icon"
          src="https://artimg2.artart.cn/image/20220122/594884fa99e3bd71b2479111f0affd6e.png"
          alt=""
        >
        <div class="flex-one ml16 ft14">银行转账</div>
        <img
          v-if="modelValue == 'bank_transfer'"
          src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
        >
        <img
          v-else
          src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
        >
      </div>
      <template v-if="modelValue == 'bank_transfer'">
        <van-uploader :after-read="modeUpload">
          <div
            class="upload-certificate1"
            v-if="pay_evidence"
          >
            <img
              :src="pay_evidence"
              alt=""
            >
          </div>
          <div
            class="upload-certificate item-flex-center flex-direction"
            v-else
          >
            <img
              src="https://artimg2.artart.cn/image/20220122/b50b1c5d5f2e1a86e79033f15cd74320.png"
              alt=""
            >
            <div>上传转账凭证</div>
          </div>
        </van-uploader>
        <template
          v-for="(item, index) in bankList"
          :key="`bank${index}`"
        >
          <template v-if="item.list && item.list.length">
            <div class="bank-title fw500">{{ item.title }}</div>
            <div>
              <div
                class="bank-list"
                v-for="(m, i) in item.list"
                :key="`bank${index}${i}`"
              >
                <div class="flex align-items-c">
                  <div class="flex-one">开户名：{{ m.param.account_name }}</div>
                  <div
                    class="r"
                    @click="copyAction(m.param.account_name)"
                  >复制</div>
                </div>
                <div
                  class="flex align-items-c"
                  v-if="item.type == 'bank_transfer'"
                >
                  <div class="flex-one">开户行：{{ m.param.open_bank }}</div>
                  <div
                    class="r"
                    @click="copyAction(m.param.open_bank)"
                  >复制</div>
                </div>
                <div class="flex align-items-c">
                  <div class="flex-one">账　号：{{ m.param.account_number }}</div>
                  <div
                    class="r"
                    @click="copyAction(m.param.account_number)"
                  >复制</div>
                </div>
                <div
                  class="copy-all textAlignC"
                  @click="copyAction(`开户名:${m.param.account_name} ${ m.param.open_bank ? '开户行:' + m.param.open_bank : ''} 账号:${m.param.account_number}`)"
                >复制全部</div>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { bankTransferAccount, getOssUploadConfig } from "@/api/system";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { Toast, Uploader } from "vant";
import OSS from "ali-oss";

export default {
  props: ["modelValue", "pay_evidence"],
  components: {
    [Uploader.name]: Uploader,
  },
  setup(props, content) {
    const store = useStore();
    const changePayTypeAction = (t) => {
      content.emit("update:modelValue", t);
    };

    const ossClient = ref(null);

    const bankList = ref([]); // 银行 支付宝  列表

    onBeforeMount(() => {
      getOssUploadConfig({ type: 1 })
        .then((res) => {
          ossClient.value = new OSS({
            region: res.data.region,
            accessKeyId: res.data.Credentials.AccessKeyId,
            accessKeySecret: res.data.Credentials.AccessKeySecret,
            stsToken: res.data.Credentials.SecurityToken,
            bucket: res.data.bucket,
          });
        })
        .catch((message) => Toast(message));
      // bankTransferAccount().then((res) => {
      //   bankList.value = res.data;
      // });
    });

    const modeUpload = (file) => {
      const f = file.file;
      if (f.type.indexOf("image") === -1 && f.type.indexOf("video") === -1) {
        return;
      }
      f.uploadStatus = 0;
      f.progress = 0;
      f.tempCheckpoint = false;
      if (f.size / 1024 / 1024 > 1) {
        f.showSize = `${Number(f.size / 1024 / 1024).toFixed(2)} MB`;
      } else {
        f.showSize = `${Number(f.size / 1024).toFixed(2)} KB`;
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      multipartUpload(f, (result) => {
        Toast.clear();
        if (result.data.status == 200) {
          content.emit("upload_pay_evidence", result.data.data.image_url);
        } else {
          Toast(result.data.message);
        }
      });
    };

    const multipartUpload = async (m, cb) => {
      const mer_id = store.getters.mer_id;
      try {
        const options = {
          callback: {
            url: "https://cloud-api.yunzongbu.cn/api/upload/oss/callback",
            body:
              "filename=${object}&size=${size}&mimeType=${mimeType}&height=${imageInfo.height}&width=${imageInfo.width}" +
              "&attachment_name=${x:attachment_name}" +
              "&mer_id=${x:mer_id}",
            contentType: "application/x-www-form-urlencoded",
            customValue: {
              mer_id: `${mer_id}`,
            },
          },
        };

        const myDate = new Date();
        const imgType = m.name.split(".");
        let imgT = imgType[imgType.length - 1];
        imgT = imgT.toLowerCase();
        const imgName =
          myDate.getTime().toString() +
          parseInt(Math.random() * 1000000, 10).toString();
        const imgUrl = `${imgName}.${imgT}`;

        const result = await ossClient.value.put(
          `merchant/${mer_id || "000000"}/${myDate.getFullYear()}${
            myDate.getMonth() + 1
          }${myDate.getDate()}/image/${imgUrl}`,
          m,
          options
        );
        if (result && result.name) {
          cb(result);
        }
      } catch (e) {
        if (cb) cb();
      }
    };

    return {
      bankList,

      changePayTypeAction,
      modeUpload,
    };
  },
  methods: {
    copyAction(val) {
      this.$copyText(val).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.all-top{
  padding-top: 32px;
}
.common-view {
  background: #fff;
  border-radius: 8px;
}
.common-pay {
  padding: 0 32px;
  box-sizing: border-box;
  font-size: 24px;

  &.mt44{
    margin-top: 44px;
  }

  img {
    width: 48px;
    height: 48px;
    display: block;
  }

  .ml16 {
    margin-left: 16px;
    color: #666;
  }

  .upload-certificate {
    width: 686px;
    height: 200px;
    border-radius: 8px;
    background: #f7f7f7;
    border: 2px solid #eee;
    margin-top: 32px;

    img {
      width: 48px;
      height: 48px;
    }

    div {
      margin-top: 8px;
      color: #666;
    }
  }
  .upload-certificate1 {
    width: 614px;
    border-radius: 8px;
    background: #f7f7f7;
    border: 2px solid #eee;
    margin-top: 32px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .bank-title {
    margin-top: 20px;
    color: #666;
  }

  .bank-list {
    color: #666;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 2px solid #f7f7f7;

    &:first-child {
      padding-top: 8px;
      margin-top: 0;
      border: none;
    }

    .flex {
      padding-top: 8px;

      .r {
        color: #347ff4;
      }
    }
  }
}

.mt32 {
  margin-top: 32px;
}
.mt16 {
  margin-top: 16px;
}

.copy-all {
  color: #347ff4;
  padding: 10px 0;
}
</style>