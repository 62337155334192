<template>
  <div>
    <!-- 单列模式 -->
    <div
      class="content-box"
      v-if="content.style == 1"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        class="one-list"
        :class="{ 'mt0': index === 0 }"
        v-for="(item, index) in content.renderData"
        :key="index"
        :style="`margin-top:${facade.item_bottom_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};`"
        @click="goDetailAction(item)"
      >
        <div
          class=""
          v-if="item.image_input"
        >
          <img :src="item.image_input + '!m640'">
        </div>
        <div class="text color-black">{{item.name}}</div>
        <div class="bottom-box">
          <div class="sign">{{item.visit}}阅读<span
              v-if="item.articleCategory"
              class="ml10"
            >{{item.articleCategory.title}}</span></div>
        </div>
      </div>
    </div>
    <!-- 双列 -->
    <div
      class="content-box"
      v-if="content.style == 2 && content.img_style != 1"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        v-for="(item, index) in content.renderData"
        :key="index"
        class="two-list"
        :class="{ 'mt0': index < 2, 'mr0': index % 2 !== 0 }"
        :style="`margin-top:${facade.item_bottom_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};width: ${(750 - facade.page_padding - facade.page_padding - facade.item_padding) / 150}rem`"
        @click="goDetailAction(item)"
      >
        <div
          class="img-box"
          :style="`width:${((750 - facade.page_padding - facade.page_padding - facade.item_padding) / 150)}rem;height: ${((750 - facade.page_padding - facade.page_padding - facade.item_padding) / 150)}rem;`"
        >
          <div class="image-slot">
            <van-image
              class="img"
              :src="item.image_input + '!m640'"
              v-if="item.image_input"
              :fit="content.img_style == 2 ? 'contain' : 'cover'"
            ></van-image>
          </div>
        </div>
        <div class="text">{{item.name}}</div>
        <div class="bottom-box">
          <div class="sign">{{item.visit}}阅读<span
              v-if="item.articleCategory"
              class="ml10"
            >{{item.articleCategory.title}}</span></div>
        </div>
      </div>
    </div>
    <!-- 三列 -->
    <div
      class="content-box"
      v-if="content.style == 3"
      :style="`padding: 0 ${facade.page_padding / 75}rem;justify-content: flex-start;background: ${facade.background_color};`"
    >
      <div
        v-for="(item, index) in content.renderData"
        :key="index"
        class="three-list"
        :class="{ 'mt0': index < 3 }"
        :style="`margin-top:${facade.item_bottom_padding / 75}rem;margin-right:${index%3 === 2?'0':facade.item_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};width: ${((750 - facade.page_padding - facade.page_padding - facade.item_padding - facade.item_padding) / 225)}rem;`"
        @click="goDetailAction(item)"
      >
        <div
          class="img-box"
          :style="`width:${((750 - facade.page_padding - facade.page_padding - facade.item_padding - facade.item_padding) / 225)}rem;height: ${((750 - facade.page_padding - facade.page_padding - facade.item_padding - facade.item_padding) / 225)}rem;`"
        >
          <div class="image-slot">
            <van-image
              class="img"
              :src="item.image_input + '!m640'"
              v-if="item.image_input"
              :fit="content.img_style == 2 ? 'contain' : 'cover'"
            ></van-image>
          </div>
        </div>
        <div class="text">{{item.name}}</div>
        <div class="bottom-box">
          <div class="sign">{{item.visit}}阅读<span
              v-if="item.articleCategory"
              class="ml10"
            >{{item.articleCategory.title}}</span></div>
        </div>
      </div>
    </div>
    <!-- 列表模式 -->
    <div
      class="content-box"
      v-if="content.style == 4"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        v-for="(item, index) in content.renderData"
        :key="index"
        :class="{ 'mt0': index < 1 }"
        class="list-horizontal"
        :style="`margin-top:${facade.item_bottom_padding / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};`"
        @click="goDetailAction(item)"
      >
        <div
          class="list-h-left"
          :style="`width: ${facade.img_width / 75}rem;height: ${facade.img_height / 75}rem;`"
          v-if="item.image_input"
        >
          <div class="image-slot">
            <van-image
              class="img"
              :src="item.image_input + '!m640'"
              :fit="content.img_style == 2 ? 'contain' : 'cover'"
            ></van-image>
          </div>
        </div>
        <div
          class="list-h-right"
          :style="!item.image_input ? 'padding-bottom: 0.8rem' : ''"
        >
          <div class="text">{{item.name}}</div>
          <div class="bottom-box">
            <div class="sign">{{item.visit}}阅读<span
                v-if="item.articleCategory"
                class="ml10"
              >{{item.articleCategory.title}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 左右滑动模式 -->
    <div
      class="swiper-list-box"
      v-if="content.style == 5"
      :style="`padding-left: ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        class="list-swiper"
        style="width: 100%;"
      >
        <div class="display-flex">
          <div
            class="display-flex justify-start"
            :style="`padding-right: ${facade.item_padding / 75}rem;`"
            v-for="(item, index) in content.renderData"
            :key="index"
          >
            <div
              class="swiper-list"
              :style="`width: ${facade.img_width / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};`"
              @click="goDetailAction(item)"
            >
              <div
                class="swiper-list-img"
                :style="`width: ${facade.img_width / 75}rem;height: ${facade.img_height / 75}rem;`"
              >
                <div class="image-slot">
                  <van-image
                    class="img"
                    :src="item.image_input + '!m640'"
                    :fit="content.img_style == 2 ? 'contain' : 'cover'"
                  ></van-image>
                </div>
              </div>
              <div
                class="text"
                :style="`width: ${(facade.img_width - 40) / 75}rem`"
              >{{item.name}}</div>
              <div class="bottom-box">
                <div class="sign">{{item.visit}}阅读<span
                    v-if="item.articleCategory"
                    class="ml10"
                  >{{item.articleCategory.title}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 瀑布流 -->
    <div
      class="content-box"
      v-if="content.style == 2 && content.img_style == 1"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        class="list-left"
        v-for="(item, i) in [0,1]"
        :key="`list${i}`"
      >
        <div
          v-for="(item, index) in renderList[item]"
          :key="`lists${i}${index}`"
          class="two-list"
          :class="{ 'mt0': index < 1 }"
          :style="`margin: 0;margin-top: ${facade.item_bottom_padding / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};width:${(750 - facade.page_padding - facade.page_padding - facade.item_padding) / 2 / 75}rem;`"
          @click="goDetailAction(item)"
        >
          <div
            v-if="item.image_input"
            :style="`background: #f5f7fa;`"
          >
            <img :src="item.image_input + '!m640'">
          </div>
          <div class="text">{{item.name}}</div>
          <div class="bottom-box">
            <div class="sign">{{item.visit}}阅读<span
                v-if="item.articleCategory"
                class="ml10"
              >{{item.articleCategory.title}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多图模式 -->
    <div
      class="content-box"
      v-if="content.style == 6"
      :style="`padding: 0 ${facade.page_padding / 75}rem;background: ${facade.background_color};`"
    >
      <div
        class="one-list"
        :class="{ 'mt0': index < 1 }"
        v-for="(item, index) in content.renderData"
        :key="index"
        :style="`padding: 0 ${facade.item_padding / 75}rem 0.21333rem ${facade.item_padding / 75}rem;margin-top:${facade.item_bottom_padding / 75}rem;border-top-left-radius: ${facade.img_radius / 75}rem;border-top-right-radius: ${facade.img_radius / 75}rem;border-bottom-left-radius: ${facade.status_radius / 75}rem;border-bottom-right-radius: ${facade.status_radius / 75}rem;background: ${facade.status_background};`"
        @click="goDetailAction(item)"
      >
        <div
          class="all-img"
          style="display:flex;flex-wrap: wrap;"
        >
          <van-image
            :class="i % 3 === 0 ? 'ml0' : ''"
            :src="item.image_input + '!120a'"
            :fit="content.img_style == 2 ? 'contain' : 'cover'"
            :style="`width: ${(750 - facade.page_padding - facade.page_padding - facade.item_padding - facade.item_padding - 70) / 3 / 75}rem;height: ${(750 - facade.page_padding - facade.page_padding - facade.item_padding - facade.item_padding - 70) / 3 / 75}rem;border-radius: ${facade.img_radius / 75}rem`"
          >
          </van-image>
        </div>
        <div class="text color-black">{{item.name}}</div>
        <div class="bottom-box">
          <div class="sign">{{item.visit}}阅读<span
              v-if="item.articleCategory"
              class="ml10"
            >{{item.articleCategory.title}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { parseTime, formatPriceNumber, isWeiXin } from "@/utils/util";
import { Image as VanImage, CountDown } from "vant";

export default {
  components: {
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
  },
  props: {
    content: [Object, String],
    facade: [Object, String],
  },
  setup(props) {
    const { content, facade, jump } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const renderList = computed(() => {
      if (content.value.renderData && content.value.style === 2) {
        const l = [];
        const r = [];
        if (facade.value.img_style === 1) {
          let lH = 0;
          let rH = 0;
          content.value.renderData.forEach((m) => {
            if (lH <= rH) {
              l.push(m);
              lH += m.product.imageSize.height / m.product.imageSize.width;
            } else {
              r.push(m);
              rH += m.product.imageSize.height / m.product.imageSize.width;
            }
          });
        } else {
          content.value.renderData.forEach((m, i) => {
            if (i % 2 === 0) {
              l.push(m);
            } else {
              r.push(m);
            }
          });
        }
        return [l, r];
      } else {
        return [];
      }
    });

    const color_data = computed(() => {
      return store.getters.color_data;
    });

    const goDetailAction = (item) => {
      if (item.synopsis.indexOf("https://") === 0) {
        location.href = item.synopsis;
      } else {
        router.push({
          path: "/pages/word/index",
          query: { id: item.article_id },
        });
      }
    };

    return {
      formatPriceNumber,
      color_data,

      renderList,
      goDetailAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-box .one-list {
  width: 100%;
  background: #fff;
  overflow: hidden;
  padding-bottom: 16px;
}
.content-box .one-list img {
  width: 100%;
}
.content-box .one-list .text {
  margin: 16px 20px 0 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-box .one-list .tags-box {
  display: flex;
  margin-left: 20px;
  margin-top: 8px;
}
.content-box .one-list .tags-box .tag {
  padding: 0 8px;
  border: 2px solid #cccccc;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-weight: 400;
  color: #999;
  border-radius: 4px;
  margin-right: 8px;
}
.content-box .one-list .bottom-box {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 10px;
  align-items: center;
}
.content-box .one-list .bottom-box .price {
  color: #003d7c;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}
.content-box .one-list .bottom-box .sign {
  font-size: 24px;
  color: #999;
}
.content-box .two-list {
  /*width: 164px;*/
  /*background: #fff;*/
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 16px;
  /*margin-bottom: 15px;*/
}
.content-box .two-list .img-box {
  width: 328px;
  height: 328px;
}
.content-box .two-list .img-box .img {
  width: 100%;
  height: 100%;
}
.content-box .two-list .img {
  width: 100%;
}
.content-box .two-list .text {
  margin: 16px 20px 0 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-box .two-list .tags-box {
  display: flex;
  margin-left: 20px;
  margin-top: 8px;
}
.content-box .two-list .tags-box .tag {
  padding: 0 8px;
  border: 2px solid #cccccc;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-weight: 400;
  color: #999;
  border-radius: 4px;
  margin-right: 8px;
}
.content-box .two-list .bottom-box {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 10px;
  align-items: center;
}
.content-box .two-list .bottom-box .price {
  color: #003d7c;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}
.content-box .two-list .bottom-box .sign {
  font-size: 24px;
  color: #999;
}
.content-box .three-list {
  width: 210px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 16px;
  /*margin-bottom: 15px;*/
}
.content-box .three-list .img-box {
  width: 210px;
  height: 210px;
}
.content-box .three-list .img {
  width: 100%;
  height: 100%;
}
.content-box .three-list .text {
  margin: 20px 20px 0 20px;
  font-weight: 500;
  color: #000;
  width: 170px;
  font-size: 24px;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.content-box .three-list .bottom-box {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 10px;
  align-items: center;
}
.content-box .three-list .bottom-box .price {
  color: #003d7c;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}
.content-box .three-list .bottom-box .sign {
  font-size: 24px;
  color: #999;
}
/* 列表 */
.content-box .list-horizontal {
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  /*margin-bottom: 16px;*/
}
.content-box .list-horizontal .list-h-left {
  width: 240px;
  height: 240px;
}
.content-box .list-horizontal .list-h-left .img {
  width: 100%;
  height: 100%;
}
.content-box .list-horizontal .list-h-right {
  flex: 1;
  padding: 20px;
  position: relative;
  overflow: hidden;
}
.content-box .list-horizontal .list-h-right .text {
  color: #000;
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.content-box .list-horizontal .list-h-right .tags-box {
  display: flex;
  margin-top: 16px;
}
.content-box .list-horizontal .list-h-right .tags-box .tag {
  padding: 0 8px;
  border: 2px solid #cccccc;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-weight: 400;
  color: #999;
  border-radius: 4px;
  margin-right: 8px;
}
.content-box .list-horizontal .list-h-right .bottom-box {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
  justify-content: space-between;
  align-items: center;
}
.content-box .list-horizontal .list-h-right .bottom-box .price {
  color: #003d7c;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
  flex: 1;
}
.content-box .list-horizontal .list-h-right .bottom-box .sign {
  font-size: 24px;
  color: #999;
}
/* 左右滑动 */
.swiper-list-box {
  width: 750px;
  background: transparent;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  box-sizing: border-box;
}
.swiper-list-box .scroll {
  width: 750px;
}
.swiper-list-box .swiper-content {
  width: 10000px;
  display: flex;
}
.swiper-list-box .dot-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.swiper-list-box .dot-box .dot {
  width: 30px;
  height: 6px;
  background: rgba(0, 0, 0, 0.1);
  margin-right: 8px;
}
.swiper-list-box .dot-box .dot.active {
  background: #003d7c;
}
.swiper-list-box .swiper-list {
  width: 600px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 16px;
}
.swiper-list-box .swiper-list .swiper-list-img {
  width: 600px;
  height: 380px;
}
.swiper-list-box .swiper-list .swiper-list-img .img {
  width: 100%;
  height: 100%;
}
.swiper-list-box .swiper-list .text {
  width: 560px;
  margin-left: 20px;
  margin-top: 16px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swiper-list-box .swiper-list .tags-box {
  display: flex;
  margin-left: 20px;
  margin-top: 8px;
}
.swiper-list-box .swiper-list .tags-box .tag {
  padding: 4px 8px;
  border: 2px solid #cccccc;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-weight: 400;
  color: #999;
  border-radius: 4px;
  margin-right: 8px;
}
.swiper-list-box .swiper-list .bottom-box {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 10px;
  align-items: center;
}
.swiper-list-box .swiper-list .bottom-box .price {
  color: #003d7c;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}
.swiper-list-box .swiper-list .bottom-box .sign {
  font-size: 24px;
  color: #999;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #eee;
  color: #bbbfc8;
}

.image-icon {
  font-size: 100px;
  line-height: 100px;
  height: 100px;
  width: 100px;
}
.price {
  color: #003d7c;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
.mr0 {
  margin-right: 0;
}

.all-img .van-image {
  margin-left: 20px;
  margin-top: 20px;
  overflow: hidden;
}
.all-img .ml0 {
  margin-left: 0;
}

.mt0 {
  margin-top: 0 !important;
}

.sign {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ml10 {
  margin-left: 10px;
}
</style>