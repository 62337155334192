<template>
  <div
    class="notice"
    :style="{ backgroundColor: facade.background_color, paddingLeft: (facade.page_padding / 75) + 'rem' }"
  >
    <div
      class="notice-icon"
      v-if="content.icon"
    >
      <img :src="content.icon">
    </div>
    <div
      class="notice-view"
    >
      <div
        class="notice-title"
        :style="{ color: facade.title_color }"
      >
        <div
          class="item"
          v-for="(m, i) in content.data"
          :key="i"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="m.link.link || ''"
          :style="{ position: 'relative', paddingLeft: (facade.item_padding / 75) + 'rem' }"
        >{{m.title}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  props: {
    content: Object,
    facade: Object,
  },
  setup() {
    const router = useRouter();
    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf('pdf?url=') === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == 'writeOff') {
          // app.hxkqAction();
        } else if (item.link.indexOf('phone?phone=') === 0) {
          const phone = item.link.split('=')[1];
          window.location.href = 'tel://' + phone;
        } else if (item.link == 'open_share' || item.link == 'contact') {} else {
          let jump = 'push';
          if (item.jump == 2) {
            jump = 'replace'
          }
          const queryIndex = item.link.indexOf('?');
          const query = {};
          if (queryIndex !== 0) {
            const q = item.link.split('?');
            if (q[1]) {
              const qList = q[1].split('&');
              qList.forEach(m => {
                const mQ = m.split('=');
                if (mQ[0] && mQ[1]) {
                  query[mQ[0]] = mQ[1]
                }
              })
            }
          }
          router[jump]({ path: item.link, query: query })
        }
      } 
    };

    return {
      goPageAction
    }
  },
};
</script>

<style lang="scss" scoped>

.notice {
  width: 100%;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;
  /* Safari */
}

.notice .notice-icon {
  float: left;
  width: 40px;
  height: 40px;
  /* margin: 20rpx 24rpx; */

}

.notice .notice-icon img {
  width: 100%;
  height: 100%;
}

.notice .notice-view {
  position: relative;
  flex: 1;
  overflow-x: auto;
}

.notice .notice-title {
  line-height: 80px;
  height: 80px;
  font-size: 26px;
  display: flex;
  align-items: center;
}

.notice .notice-title .item {
  flex-shrink: 0;
}
</style>