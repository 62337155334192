const auctionRouter = {
  path: '/pages',
  component: () => import('@/views/auction/index'),

  children: [{
    path: 'auctionDetail/index',
    name: 'auction_detail',
    component: () => import('@/views/auction/auctionDetail'),
  }],
}

export default auctionRouter;
