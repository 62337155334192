import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Toast, Dialog, List, Lazyload,PullRefresh } from 'vant';
import VueClipboard from 'vue-clipboard2';
import Footer from '@/components/Footer';
import CommonHeader from '@/components/CommonHeader';
import AmountSymbol from '@/components/AmountSymbol';
import NProgress from 'nprogress'; // progress bar
import 'swiper/swiper.min.css';
import 'nprogress/nprogress.css'; // progress bar style
import 'lib-flexible';
// import 'amfe-flexible'
import '@/styles/vant-ui.scss';
import '@/styles/index.scss';
import '@/styles/fontFamily.scss';
import { isWeiXin, getQueryObject } from '@/utils/util';
import setRem from '@/utils/rem';
import { shareConfig } from '@/utils/share';
// import FastClick from 'fastclick'
setRem();
// FastClick.attach(document.body);
NProgress.configure({
  minimum:0.1, // 最小百分比
  // template: "...", // 修改标记结构，需要一个包含 role='bar' 属性的元素。
  ease: 'ease',  // 动画设置
  speed: 500, // 速度设置毫秒ms
  trickle: false, // 关闭进度条步进
  trickleRate: 0.02, // 度条步进
  trickleSpeed: 300, // 度条步进速度毫秒ms
  showSpinner: false // 关闭进度环 默认true
})
Toast.setDefaultOptions('loading', { forbidClick: true });

import { getDoMain } from '@/api/system';
const url = location.hostname.split('.')[0];
const urlQuery = getQueryObject(null);

const whiteList = [`/login`, `/Login`, '/auth-redirect', 'test']; // no redirect whitelist
const xcxPathList = [`/pages/orderConfirm/index`, '/pages/word/index', '/pages/auctionMeeting/index', '/pages/auctionDetail/index', '/pages/albumDetail/index', '/pages/Live/Live']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  if (to.meta.titles) {
    document.title = to.meta.titles[to.query.type || '1']
  } else if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = store.getters.mer_name
  }
  // document.title = to.meta.title || ''

  // await store.dispatch('user/logout');

  NProgress.start();
  const token = store.getters.token;

  let scope = 'snsapi_userinfo';

  if (token) {
    // const exp = localStorage.getItem('token_exp');
    // const nowDate = (Date.parse(new Date()) / 1000) - (24 * 60 * 60);
    // if (nowDate > Number(exp)) {
    //   scope = 'snsapi_base';
    //   await store.dispatch('user/logout');
    // }
  }

  if (token) {
    shareConfig('', '', '', `https://${location.hostname}`, '', `https://${location.hostname}${to.fullPath}`);
    if (whiteList.indexOf(to.path) !== -1) {
      next({ path: '/' });
      NProgress.done();
    } else {
      if (isWeiXin()) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            if (to.path.indexOf('/pages/users/index') == 0) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.switchTab({
                url: to.fullPath,
              });
            } else if (to.path.indexOf(`/pages/home/home`) == 0) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.reLaunch({
                url: to.fullPath,
              });
            } else if (to.path == '/') {
              // eslint-disable-next-line no-undef
              wx.miniProgram.reLaunch({
                url: '/pages/home/home',
              });
            } else if (xcxPathList.indexOf(to.path) !== -1) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.navigateTo({
                url: to.fullPath,
              });
            } else {
              window.scrollTo(0, 1);
              window.scrollTo(0, 0);
              next();
              NProgress.done();
            }
          } else {
            window.scrollTo(0, 1);
            window.scrollTo(0, 0);
            next();
            NProgress.done();
          }
        });
      } else {
        // if (to.path != '/' && to.path != '/pages/home/home' && to.path != '/pages/albumDetail/index' && to.path != '/pages/albumDetail/index' && to.path != '/pages/search/index') {
        // }
        window.scrollTo(0, 1);
        window.scrollTo(0, 0);
        next();
        NProgress.done();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1 || to.path == '/') {
      // in the free login whitelist, go directly

      // if (whiteList.indexOf(to.path) === 0) {
      //   window.location.href='/auth-redirect'
      // }

      // next({ path: '/' });
      // NProgress.done();
 
      next();
    } else {
      if (isWeiXin()) {
        window.location.href = `${process.env.VUE_APP_BASE_API}api/login/h5/wechat/login_auth?domain_key=${(url == 'localhost' ? 'apptyy6644' : url)}&scope=${scope}&url=${encodeURIComponent(to.href)}`;
        NProgress.done();
        return false;
      }
      // other pages that do not have permission to access are redirected to the login page.
      await store.dispatch('user/resetToken');
      next(`/login?redirect=${to.path}&jumpUrl=${encodeURIComponent(to.href)}`);
      NProgress.done();
      next();
    }
  }
  next();
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});


const initLoad = () => {
  // yitianxia
  let data = { domain_key: ((url == 'localhost'|| url.indexOf('192.168')) ? 'epaitianxia' : url) };
  const uQ = localStorage.getItem('urlQuery');
  if (urlQuery.is_miniprogram && urlQuery.token && urlQuery.token_exp) {
    localStorage.setItem('urlQuery', JSON.stringify(urlQuery));
    store.dispatch('user/appLogin', {
      token: urlQuery.token,
      exp: urlQuery.token_exp,
    });
    data = { mer_id: urlQuery.mer_id, domain_key: 'miniprogram' }
  } else if (uQ) {   
    const urlQ = JSON.parse(uQ)
    store.dispatch('user/appLogin', {
      token: urlQ.token,
      exp: urlQ.token_exp,
    });
    data = { mer_id: urlQ.mer_id, domain_key: 'miniprogram' }
  }

  getDoMain(data).then(res => {
    document.title = res.data.name || '';
    store.dispatch('user/set_merchant', res.data);
    store.dispatch('user/set_system_configure', res.data.home_page_data_config);
    store.dispatch('user/get_merchant');
    createApp(App).use(store).use(router).use(List).use(VueClipboard).use(Dialog).use(Lazyload, { lazyComponent: true }).use(PullRefresh).component('Footer', Footer).component('CommonHeader', CommonHeader).component('AmountSymbol', AmountSymbol).mount('#app');
  }).catch(({ msg }) => {
    console.log(msg);
  });
};

initLoad();

