<template>
  <div>
    <template v-if="footerData">
      <div
        class="page-footer"
        :style="{ background: footerData.footer_background, color: footerData.footer_text_color, paddingBottom: (isIphonex ? '0.693333rem' : '0') }"
      >
        <div
          class="page-footer-view"
          :style="{ padding: `0 ${footerData.footer_margin / 75}rem`, fontSize: (footerData.footer_text_size / 75) + 'rem' }"
        >
          <template
            v-for="(item, index) in footerData.content"
            :key="`footer${index}`"
          >
            <template v-if="((item.link.link == 'writeOff' || item.link.link == '/frame/write-off/write-off') && hxy) || (item.link.link != 'writeOff' && item.link.link != '/frame/write-off/write-off')">
              <div
                class="item"
                @click="goFooterAction(item.link.link || '')"
              >
                <div class="img">
                  <img
                    v-if="footerIndex == index && item.url_after"
                    :src="item.url_after + '!120a'"
                    mode="aspectFit"
                    class="image"
                    :class="{ 'tuchu': isTuchu && tuchuIndex === index }"
                  >
                  <img
                    :src="item.url + '!120a'"
                    alt=""
                    v-else-if="item.url"
                    mode="aspectFit"
                    class="image"
                    :class="{ 'tuchu': isTuchu && tuchuIndex === index }"
                  >

                  <div
                    class="tidai"
                    :class="{ 'tuchu': isTuchu && tuchuIndex === index }"
                    v-else
                  ></div>
                </div>
                <div
                  class="item-txt"
                  :style="{ color: (footerIndex == index ? footerData.footer_active_color : '') }"
                >
                  {{ item.title }}</div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  props: {
    isHomePage: Boolean,
    useMine: Boolean,
    useData: Object,
  },
  setup(props) {
    const { isHomePage, useMine, useData } = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const footerData = computed(() => {
      if (useMine.value) {
        return useData.value;
      } else {
        return store.getters.footer_data &&
          store.getters.footer_data.footerSetting
          ? store.getters.footer_data.footerSetting
          : "";
      }
    });

    const isTuchu = computed(() => {
      return footerData.value && footerData.value.footer_type === 2;
    });

    const tuchuIndex = computed(() => {
      return parseInt(
        footerData.value && footerData.value.content
          ? footerData.value.content.length / 2
          : 0,
        10
      );
    });

    const isIphonex = computed(() => {
      const uQ = localStorage.getItem("urlQuery");
      if (uQ) {
        const urlQ = JSON.parse(uQ);
        if (urlQ.isIphoneX == 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
      // if (typeof window !== "undefined" && window) {
      //   return (
      //     /iphone/gi.test(window.navigator.userAgent) &&
      //     window.screen.height >= 812
      //   );
      // }
      // return false;
    });

    const footerIndex = computed(() => {
      let fIndex = -1;
      if (footerData.value && footerData.value.content) {
        let link = route.path;
        if (route.query) {
          const linkQuery = [];
          for (const key in route.query) {
            if (
              key != "is_miniprogram" &&
              key != "mer_id" &&
              key != "token_exp" &&
              key != "token" &&
              key != 'isIphoneX'
            ) {
              linkQuery.push(`${key}=${route.query[key]}`);
            }
          }
          if (linkQuery.length > 0) {
            link += `?${linkQuery.join("&")}`;
          }
        }
        for (let i in footerData.value.content) {
          const m = footerData.value.content[i];
          if (!m.link.link) {
            console.log("未设置跳转地址");
          } else if (
            link == "/pages/home/home" &&
            (m.link.link == "/" ||
              m.link.link == "/pages/home/home" ||
              m.link.link == "/pages/home/home?id=")
          ) {
            fIndex = i;
            break;
          } else if (m.link.link == "/" && isHomePage.value) {
            fIndex = i;
            break;
          } else if (m.link.link.indexOf(link) != -1) {
            fIndex = i;
            break;
          }
        }
      }
      return fIndex;
    });

    const goFooterAction = (link) => {
      if (link.indexOf("pdf?url=") === 0) {
        // const url = item.link.split("=")[1];
        // if (url) {
        //   app.openFiles(url);
        // }
      } else if (link == "writeOff") {
        // app.hxkqAction();
      } else if (link == "open_share" || link == "contact") {
        //
      } else if (link.indexOf("phone?phone=") === 0) {
        const phone = link.split("=")[1];
        window.location.href = "tel://" + phone;
      } else if (link) {
        router.replace(link);
      }
    };

    return {
      footerData,
      isTuchu,
      tuchuIndex,
      footerIndex,
      isIphonex,

      goFooterAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 110px;
  display: flex;
  z-index: 100;
  padding-bottom:30px !important;
  .page-footer-view {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .page-footer-view .item {
    padding-top: 22px;
    text-align: center;
    flex: 1;
    position: relative;
  }
  .page-footer-view .item .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .page-footer-view .item .tidai {
    width: 48px;
    height: 48px;
    display: block;
  }
  .page-footer-view .item .image {
    width: 48px;
    height: 48px;
    display: block;
  }
  .page-footer-view .item .tuchu {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-top: -52px;
    overflow: hidden;
  }

  .hide-button {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
  }
}

.item-txt {
  font-size: 20px;
  margin-top: 4px;
}
</style>