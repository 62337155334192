import { wxLogin, bindPhone, phoneLoginApi, getInfo, accountInfo, applyVerifyInfo, isSubscribeOfficialAccountApi } from '@/api/user';
import { accountInfoApi } from '@/api/nft';
import { merchantInfoApi } from '@/api/system';
import router from '@/router';
import { Dialog } from 'vant';
import { isWeiXin } from '@/utils/util';

const state = {
  uname: '',
  avatar: '',
  uid: '',
  phone: '',
  mark: '',
  mer_id: localStorage.getItem('mer_id'),
  mer_name: '',
  token: localStorage.getItem('token'),
  nft_info: {},

  banner_style: {},
  button_style: {},
  left_button_style: {},
  right_button_style: {},
  footer_data: {},
  img_raduis_style: {},
  color_data: {},
  tag_style: {},
  title_style: {},
  account: {
    balance: 0,
    frozen_balance: 0
  },
  entrust_account: {
    balance: 0,
    frozen_balance: 0
  },
  merchantInfo: {},
  isVerify: '',
  is_follow_official_account: '',
  qrcode_url: ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    localStorage.setItem('token', token);
    state.token = token;
  },
  SET_MERID: (state, mer_id) => {
    state.mer_id = mer_id;
  },
  SET_MERNAME: (state, mer_name) => {
    state.mer_name = mer_name;
  },
  SET_USERINFO: (state, info) => {
    state.uname = info.nickname;
    state.avatar = info.avatar;
    state.mark = info.mark;
    state.uid = info.uid;
  },
  SET_USERPHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_SYSTEM_CONFIGURE: (state, data) => {
    state.banner_style = (data.banner_style ? JSON.parse(data.banner_style) : {});
    state.button_style = (data.button_style ? JSON.parse(data.button_style) : {});
    state.right_button_style = {
      border: `0.05333333rem solid ${state.button_style.afterBorderColor}`,
      background: state.button_style.afterBgColor,
      color: state.button_style.afterColor,
      fontWeight: state.button_style.afterFontWeight,
      fontSize: (state.button_style.afterFontSize / 75) + "rem",
    };
    state.left_button_style = {
      border: `0.05333333rem solid ${state.button_style.frontBorderColor}`,
      background: state.button_style.frontBgColor,
      color: state.button_style.frontColor,
      fontWeight: state.button_style.frontFontWeight,
      fontSize: (state.button_style.frontFontSize / 75) + "rem",
      marginRight: ((state.button_style.style == 1 ? 20 : 0) / 75) + 'rem'
    };
    if (state.button_style.style == 1) {
      state.left_button_style.borderRadius = (state.button_style.frontRadius / 75) + "rem";
      state.right_button_style.borderRadius = (state.button_style.afterRadius / 75) + "rem";
    } else {
      state.left_button_style.borderTopLeftRadius = (state.button_style.frontRadius / 75) + "rem";
      state.left_button_style.borderBottomLeftRadius = (state.button_style.frontRadius / 75) + "rem";
      state.right_button_style.borderTopRightRadius = (state.button_style.afterRadius / 75) + "rem";
      state.right_button_style.borderBottomRightRadius = (state.button_style.afterRadius / 75) + "rem";
    }
    state.footer_data = (data.footer_data ? JSON.parse(data.footer_data) : {});
    state.img_raduis_style = (data.img_raduis_style ? JSON.parse(data.img_raduis_style) : {});
    state.color_data = (data.other_data ? JSON.parse(data.other_data) : {});
    state.tag_style = (data.tag_style ? JSON.parse(data.tag_style) : {});
    state.title_style = (data.title_style ? JSON.parse(data.title_style) : {});
  },
  SET_KEY_VALUE: (state, d) => {
    state[d.key] = d.value;
  },
  SET_ACCOUNT: (state, money) => {
    state.account = money;
  },
  SET_ENTRUST_ACCOUNT: (state, money) => {
    state.entrust_account = money;
  },
  SET_MERCHANT_INFO: (state, data) => {
    state.merchantInfo = data;
  },
  SET_QRCODE_URL: (state, data) => {
    state.qrcode_url = data;
  },
  APPLYVERIFYINFO: (state, isVerify) => {
    state.isVerify = isVerify;
  },
  SET_NFT_USERINFO: (state, data) => {
    state.nft_info = data;
  },
  SET_FOLLOW_OFFICIAL_ACCOUNT: (state, val) => {
    state.is_follow_official_account = val;
  }
};

const actions = {
  // user login
  wxLogin({ commit }, data) {
    // const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      const user = localStorage.getItem('userInfo');
      if (user && user.uid) {

        this.setInfo(commit, user);

        resolve(user);

      } else {
        wxLogin(data.code, data).then(response => {
          const { data } = response;

          if (data.status !== 2) {
            commit('SET_TOKEN', data.token);
            localStorage.setItem('token_exp', data.exp);
            commit('SET_USERINFO', data.user);

            if (data.user && data.user.phone) {
              commit('SET_USERPHONE', data.user);
            }
          }

          resolve(data);
        }).catch(message => {
          reject(message);
        });
      }
    });
  },

  bindPhone({ commit }, data) {
    return new Promise((resolve, reject) => {
      const user = localStorage.getItem('userInfo');
      if (user && user.uid) {

        this.setInfo(commit, user);

        resolve(user);

      } else {
        bindPhone(data).then(response => {
          const { data } = response;

          commit('SET_TOKEN', data.token);
          localStorage.setItem('token_exp', data.exp);
          commit('SET_USERINFO', data.user);

          if (data.user && data.user.phone) {
            commit('SET_USERPHONE', data.user);
          }

          resolve(data);
        }).catch(message => {
          reject(message);
        });
      }
    });
  },

  phoneLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      const user = localStorage.getItem('userInfo');
      if (user && user.uid) {

        this.setInfo(commit, user);

        resolve(user);

      } else {
        phoneLoginApi(data).then(response => {
          const { data } = response;

          commit('SET_TOKEN', data.token);
          localStorage.setItem('token_exp', data.exp);
          commit('SET_USERINFO', data.user);

          if (data.user && data.user.phone) {
            commit('SET_USERPHONE', data.user);
          }

          resolve(data);
        }).catch(message => {
          reject(message);
        });
      }
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        commit('SET_USERINFO', data);

        if (data.phone) {
          commit('SET_USERPHONE', data.phone);
        } else {
          router.push('/rebind_phone?type=2');
        }

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // get user info
  getIsFollowOfficialAccountInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.is_follow_official_account) {
        resolve(true);
      } else {
        if (isWeiXin()) {
          isSubscribeOfficialAccountApi().then(response => {
            const { data } = response;

            if (!data) {
              reject('Verification failed, please Login again.');
            }

            commit('SET_FOLLOW_OFFICIAL_ACCOUNT', data.isSubscribe);

            resolve(data);
          }).catch(error => {
            reject(error);
          });
        } else {
          commit('SET_FOLLOW_OFFICIAL_ACCOUNT', true);
          resolve(true);
        }
      }
    });
  },
  getNftInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      accountInfoApi().then(response => {
        const { data } = response;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        commit('SET_NFT_USERINFO', data);

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  get_merchant({ commit }) {
    return new Promise((resolve, reject) => {
      merchantInfoApi().then(response => {
        const { data } = response;

        commit('SET_MERCHANT_INFO', data.info);
        commit('SET_QRCODE_URL', data.officialAccountInfo.qrcode_url);

        resolve(data.info);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // get user info
  getAccountInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      accountInfo({ account_type: 1 }).then(response => {
        let { data } = response;

        if (!data) {
          reject('Verification failed, please Login again.');
        }
        data.balance = data.now_money;
        commit('SET_ACCOUNT', data);

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  applyVerifyInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.isVerify == 1 || state.isVerify == 2) {
        resolve(state.isVerify);
      } else {
        applyVerifyInfo().then(res => {
          commit('APPLYVERIFYINFO', (res.data ? res.data.verify_status : 0));
          if (res.data.verify_status == 0) {
            Dialog.confirm({
              message: '请补充个人资料',
              confirmButtonText: '立即补充'
            })
              .then(() => {
                router.push('/users/apply_verify');
              })
              .catch(() => {
                resolve(0);
              });
          }
          resolve(res.data.verify_status);
        }).catch(message => {
          reject(message);
        });
      }
    });
  },
  getIsApplyVerify({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.isVerify == 1 || state.isVerify == 2) {
        resolve(state.isVerify);
      } else {
        applyVerifyInfo().then(res => {
          commit('APPLYVERIFYINFO', (res.data ? res.data.verify_status : 0));
          resolve(res.data.verify_status);
        }).catch(message => {
          reject(message);
        });
      }
    });
  },

  // get user info
  getEntrustAccountInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      accountInfo({ account_type: 2 }).then(response => {
        const { data } = response;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        commit('SET_ENTRUST_ACCOUNT', data);

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },

  setUserInfo({ commit }, data) {
    commit('SET_KEY_VALUE', data);
  },

  // 修改商户id
  set_merchant({ commit }, merData) {
    return new Promise((resolve, reject) => {
      if (merData && merData.mer_id) {
        commit('SET_MERID', merData.mer_id);
        commit('SET_MERNAME', merData.name);
        resolve(merData);
      } else {
        reject('未找到机构');
      }
    });
  },

  // 修改商户id
  set_system_configure({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        commit('SET_SYSTEM_CONFIGURE', data);
        resolve(data);
      } else {
        reject('未设置');
      }
    });
  },

  // 退出登录
  appLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', data.token);
      localStorage.setItem('token_exp', data.exp);

      resolve();
    });
  },

  // 退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '');
      localStorage.setItem('token_exp', 0);
      // resetRouter();

      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
