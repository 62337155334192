<template>
  <div v-if="content.renderData">
    <template v-if="content.style === 1">
      <div
        class="auction-meeting"
        :style="{
          margin: `0 ${facade.page_margin / 75}rem`,
          paddingBottom: '0.66666rem'
        }"
      >

        <van-swipe
          class="auction-album-swiper"
          indicator-color="white"
          :width="(((750 - (facade.item_margin) - 64) / 75) * fix)"
          :show-indicators="false"
          @change="changeSwiper"
          :stop-propagation="false"
          @touchmove.stop
        >
          <van-swipe-item
            v-for="(m, i) in content.renderData"
            :key="i"
          >
            <!-- <div class="flex align-items-c"> -->
            <div
              class="album flex"
              :style="{
                marginLeft: (facade.item_margin / 75) + 'rem',
                width: ((750 - (facade.item_margin * 2) - 64) / 75) + 'rem',
                borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
                borderTopRightRadius: (facade.top_radius / 75) + 'rem',
                borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
                borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
                background: facade.item_background,
                paddingBottom: (facade.status_bar_height / 75) + 'rem',
                boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
              }"
              @click="goAlbumDetail(m.album_id)"
            >
              <template v-if="m.album_config && m.album_config.image_show_style == 1">
                <div
                  class="album-line flex flex-direction"
                  style="height: 5.33333rem;"
                  :style="{
                  width: ((750 - (facade.item_margin * 2) - 64) / 75) + 'rem',
                }"
                >
                  <div
                    class="info flex align-items-c"
                    style="padding: 0;"
                  >
                    <div
                      class="name"
                      :style="{ color: facade.title_color }"
                    >{{ m.album_name }}</div>
                  </div>
                  <div class="img flex-one">
                    <van-image
                      style="width: 100%;height: 100%;"
                      :src="m.album_image + '!m640'"
                      fit="cover"
                      v-lazy="m.album_image + '!m640'"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="img"
                  style="width: 4rem;height: 5.33333rem;"
                >
                  <van-image
                    style="width: 100%;height: 100%;"
                    :src="m.album_image + '!m640'"
                    fit="cover"
                    v-lazy="m.album_image + '!m640'"
                  />
                </div>
                <div class="info flex-one">
                  <div
                    class="name"
                    :style="{ color: facade.title_color }"
                  >{{ m.album_name }}</div>
                  <div
                    class="time"
                    :style="{ color: facade.sub_title_color }"
                  >{{ pTime(m.album_start_time, '{m}月{d}日 {h}:{i}') }} <span v-if="m.album_end_time">至 {{ pTime(m.album_end_time, '{m}月{d} {h}:{i}') }}</span><span v-else>开拍</span></div>
                  <div
                    class="lots"
                    :style="{ color: facade.detail_color }"
                  >
                    <div
                      class="lot"
                      v-if="m.auction_num"
                    >拍品：{{ m.auction_num }}件</div>
                    <div
                      class="lot"
                      v-if="m.commission_config_json"
                    >佣金：{{ m.commission_config_json.commission }}{{m.commission_config_json.after_commission ? ' - ' + m.commission_config_json.after_commission : ''}}%</div>
                    <div class="lot">围观：{{ m.virtual_click_num }}次</div>
                  </div>
                </div>
              </template>
              <AuctionAlbumStatus
                :m="m"
                :facade="facade"
              ></AuctionAlbumStatus>
            </div>
            <!-- </div> -->
          </van-swipe-item>
        </van-swipe>
        <Pointer
          vAlign="bottom"
          :align="facade.indicator_align"
          :type="facade.indicator_style"
          :color="facade.indicator_color"
          :pointerCount="content.renderData.length"
          :curr="current"
        />
      </div>
    </template>
    <template v-else-if="content.style === 2">
      <div class="auction-meeting">
        <template
          v-for="(m, i) in content.renderData"
          :key="i"
        >
          <template v-if="m.album_config && m.album_config.image_show_style == 1">
            <div
              class="album flex"
              :class="{ 'mt0': i == 0 }"
              :style="{
                padding: `${(facade.page_margin / 150)}rem ${(facade.page_margin / 75)}rem`,
                background: facade.item_background,
                marginTop: `${facade.item_margin / 75}rem`
              }"
              @click="goAlbumDetail(m.album_id)"
            >
              <div class="c-info">
                <div
                  class="c-name"
                  :class="{ 'line1': !facade.name_wrap }"
                  :style="{ color: facade.column_title_font_size, fontSize: (facade.column_title_font_size / 75) + 'rem' }"
                >{{ m.album_name }}</div>
                <div
                  class="c-lots"
                  :style="{ color: facade.detail_color, paddingBottom: '0.13333rem' }"
                >
                  <template v-if="m.album_type == 4">
                    <div class="lot"><span class="mr10">开拍：{{ m.stime_format[0] }}</span> | <span class="ml10">拍品：{{ m.auction_num }}件</span></div>
                  </template>
                  <template v-else>
                    <div class="lot"><span class="mr10">预展：{{ m.pre_show_time }}</span> | <span class="ml10">拍品：{{ m.auction_num }}件</span></div>
                    <div class="lot"><span class="mr10">开拍：{{ m.stime_format[0] }}</span></div>
                  </template>
                </div>
                <div
                  class="c-bottom-img"
                  :style="{
                    paddingBottom: (facade.status_bar_height / 75) + 'rem',
                    borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
                    borderTopRightRadius: (facade.top_radius / 75) + 'rem',
                    borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
                    borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
                  }"
                >
                  <div
                    class="img"
                    style="width: 100%;height: auto;"
                  >
                    <img
                      style="width: 100%;display: block;"
                      :src="m.album_image + '!m640'"
                      alt=""
                    >
                  </div>
                  <AuctionAlbumStatus
                    :m="m"
                    :facade="facade"
                  ></AuctionAlbumStatus>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="album flex"
              :class="{ 'mt0': i == 0 }"
              :style="{
                marginLeft: (facade.page_margin / 75) + 'rem',
                width: ((750 - facade.page_margin * 2) / 75) + 'rem',
                borderTopLeftRadius: (facade.top_radius / 75) + 'rem',
                borderTopRightRadius: (facade.top_radius / 75) + 'rem',
                borderBottomLeftRadius: (facade.bottom_radius / 75) + 'rem',
                borderBottomRightRadius: (facade.bottom_radius / 75) + 'rem',
                background: facade.item_background,
                paddingBottom: (facade.status_bar_height / 75) + 'rem',
                boxShadow: facade.item_shadow ? `0 0 ${facade.item_shadow_size / 75}rem ${facade.item_shadow}` : '',
                marginTop: `${facade.item_margin / 75}rem`
              }"
              @click="goAlbumDetail(m.album_id)"
            >
              <div
                class="img"
                style="width: 4rem;height: 5.333333rem;"
              >
                <van-image
                  style="width: 100%;height: 100%;"
                  :src="m.album_image + '!m640'"
                  fit="cover"
                  v-lazy="m.album_image + '!m640'"
                />
              </div>
              <div class="info flex-one">
                <div
                  class="name"
                  :style="{ color: facade.title_color }"
                >{{ m.album_name }}</div>
                <div
                  class="time"
                  :style="{ color: facade.sub_title_color }"
                >{{ pTime(m.album_start_time, '{m}月{d}日 {h}:{i}') }} <span v-if="m.album_end_time">至 {{ pTime(m.album_end_time, '{m}月{d} {h}:{i}') }}</span><span v-else>开拍</span></div>
                <div
                  class="lots"
                  :style="{ color: facade.detail_color }"
                >
                  <div class="lot">拍品：{{ m.auction_num }}件</div>
                  <div
                    class="lot"
                    v-if="m.commission_config_json"
                  >佣金：{{ m.commission_config_json.commission }}{{m.commission_config_json.after_commission ? ' - ' + m.commission_config_json.after_commission : ''}}%</div>
                  <div class="lot">围观：{{ m.virtual_click_num }}次</div>
                </div>
              </div>
              <AuctionAlbumStatus
                :m="m"
                :facade="facade"
              ></AuctionAlbumStatus>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { getRem } from "@/utils/rem";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { parseTime, isWeiXin } from "@/utils/util";
import { Image as VanImage, Swipe, SwipeItem } from "vant";
import Pointer from "@/components/DiyComponent/Pointer";
import AuctionAlbumStatus from "@/components/DiyComponent/AuctionAlbumStatus";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Pointer,
    AuctionAlbumStatus,
  },
  props: {
    content: Object,
    facade: Object,
    changeSwiper: Function,
    renderindx: Number,
  },
  setup(props, cont) {
    const router = useRouter();
    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };
    const current = ref(0);
    const changeSwiper = (index) => (current.value = index,cont.emit("changeSwiper", index, props.renderindx));
    const fix = ref(getRem());

    const goAlbumDetail = (id) => {
      const isWx = isWeiXin();
      if (isWx) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: "/pages/albumDetail/index?id=" + id,
            });
          } else {
            goDetailAction(id);
          }
        });
      } else {
        goDetailAction(id);
      }
    };

    const goDetailAction = (id) => {
      router.push({
        path: "/pages/albumDetail/index",
        query: { id: id },
      });
    };

    return {
      current,
      fix,

      goAlbumDetail,
      changeSwiper,
      pTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.auction-meeting {
  overflow: hidden;
  position: relative;

  .title {
    height: 44px;
    font-size: 32px;

    .l {
      font-weight: 600;
      line-height: 44px;
    }

    .r {
      line-height: 32px;
      height: 40px;
      background: #ffffff;
      border-radius: 20px;
      border: 2px solid #fff;
      padding: 4px 16px;
      font-size: 24px;
    }
  }

  .album {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    .img {
      width: 270px;
      height: 400px;
    }
    .info {
      overflow: hidden;
      padding: 28px 32px 0 32px;

      .name {
        font-size: 28px;
        color: #000;
        line-height: 40px;
        font-weight: 500;
        max-height: 80px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        &.line1 {
          -webkit-line-clamp: 1;
        }
      }

      .lots {
        margin-top: 16px;
      }
      .lot {
        font-size: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        line-height: 38px;
      }

      .time {
        margin-top: 10px;
        font-size: 24px;
      }
    }

    .album-line {
      .info {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        overflow: hidden;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        height: 80px;
        z-index: 1;
        overflow: hidden;
      }
      .name,
      .lots {
        padding: 0 20px;
        color: #fff !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .img {
        width: 100%;
        overflow: hidden;
      }
    }
    .c-info {
      width: 100%;
      overflow: hidden;
      .c-name {
        font-weight: 500;
        &.line1 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .c-lots {
        margin-top: 10px;
        .lot {
          margin-top: 10px;
          font-size: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .c-bottom-img {
        position: relative;
        overflow: hidden;
      }
    }
  }
}
</style>