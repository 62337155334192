<template>
  <div class="home word-detail">
    <CommonHeader title="详情" />
    <div class="main">
      <div class="name">
        <div class="name-s">{{data.title && data.title != 'undefined' ? data.title : ''}}</div>
      </div>
      <div class="name-bottom">
        <span v-if="data.articleCategory && data.articleCategory.title">{{data.articleCategory.title}}</span>
      </div>
      <div
        v-if="data.video_url"
        class="detail-video"
      >
        <video :src="data.video_url" controls></video>
      </div>
      <div class="detail-content" v-if="data.content">
        <div v-html="data.content.content"></div>
      </div>
      <div class="detail-content-bottom">
        <div>由{{data.author}}发布</div>
        <div>{{data.visit}}浏览</div>
      </div>
    </div>
  </div>
</template>

<script>
import { wordDetail } from "@/api/cms";
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { auctionVenueInfoApi, albumListApi } from "@/api/auction";
import { getDiyPage } from "@/api/system";
import { Toast } from "vant";
import { parseTime } from "@/utils/util";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const id = ref("");
    const data = ref({});
    onBeforeMount(() => {
      id.value = route.query.id;
      wordDetail(id.value)
        .then((res) => {
          data.value = res.data;
        })
        .catch((message) => Toast(message));
    });

    return {
      data
    };
  },
};
</script>

<style lang="scss" scoped>
.home{
  max-height: 99999px;
}
.name{
  font-size: 38px;
  font-weight: bold;
  line-height: 50px;
  padding: 20px 48px 0 48px;
  display: flex;
}
.name-s{
  flex: 1;
  overflow: hidden;
}
.name-bottom{
  padding: 0 48px;
  font-size: 24px;
  color: #999;
  margin-top: 20px;
  margin-bottom: 50px;
}
.name-bottom span{
  padding-right: 40px;
}
.video-list{
  padding: 0 20px;
}
.video, .photoscbk{
  margin-bottom: 20px;
}
.video video{
  width: 710px;
  height: 399px;
  display: block;
}
.image-list{
  padding: 0 20px;
}
.image-list image{
  width: 100%;
}
.detail-content{
  padding: 0 48px;
  font-size: 28px;
  >>> p{
    font-size: 28px;
  }
}
.detail-content image{
  max-width: 100%;
}

.detail-content-bottom{
  display: flex;
  padding: 20px 48px;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 24px;
  padding-bottom: 60px;
}


.shu{
  height: 20px;
  background: #f7f7f7;
}

.artist-user{
  height: 140px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.user-avatar{
  width: 96px;
  height: 96px;
}
.user-avatar image{
  width: 96px;
  height: 96px;
  display: block;
  border-radius: 8px;
}

.user-right{
  flex: 1;
  margin-left: 20px;
}
.user-name-top{
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}
.user-name{
  font-size: 30px;
}
.user-like, .user-sixin{
  margin-left: 40px;
  height: 40px;
  font-size: 26px;
  background: #999;
  color: #fff;
  border-radius: 20px;
  padding: 0 40px;
}
.user-sixin{
  margin-left: 20px;
}
.user-num{
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 24px;
  color: #999;
  margin-top: 10px;
}
.user-num text{
  margin-right: 20px;
}



.a-reward {
  margin-bottom: 30px;
  background: #fff;
  padding: 0 20px;
}
.a-reward .a-reward-center {
  padding: 30px 0 15px 0;
  text-align: center;
}
.a-reward .a-reward-center text {
  display: inline-block;
  width: 160px;
  font-size: 28px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 6px;
  background: #DB604E;
  color: #fff;
}
.a-reward .a-reward-center view {
  margin-top: 26px;
  color: #999;
  text-align: center;
  display: block;
  font-size: 24px;
}
.a-reward .a-reward-bottom .list {
  padding: 0 25px;
  border-bottom: 2px solid #e7e7e7;
  height: 96px;
  display: flex;
  align-items: center;
}
.a-reward .a-reward-bottom navigator{
  padding: 10px 25px;
  text-align: center;
  font-size: 24px;
  color: #999;
  margin-top: 20px;
}
.a-reward .a-reward-bottom .list image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.a-reward .a-reward-bottom .list .content {
  flex: 1;
  margin-left: 22px;
  font-size: 26px;
}
.a-reward .a-reward-bottom .list .time {
  color: #999;
  font-size: 20px;
}
.a-reward .a-reward-bottom .list .content text {
  color: #999;
  margin: 0 12px 0 22px;
}

.display-f .mr20{
  margin-right: 20px;
}
.display-f image{
  flex: 1;
}
.go-detail{
  height: 44px;
  width: 132px;
  border-radius: 22px;
  font-size: 26px;
  line-height: 44px;
  text-align: center;
}

.detail-video{
  width: 654px;
  height: 368px;
  margin: 0 48px;
  margin-bottom: 30px;
}
.detail-video video{
  width: 654px;
  height: 368px;
  display: block;
}
</style>

<style scoped>
.word-detail .detail-content >>> p span{
  font-size: 28px !important;
}
</style>