import { getJsSdkConfigApi } from '@/api/system';
import store from '../store';

export function shareConfig(title, desc, imgUrl, url, other, regiestUrl) {
  // let href = window.location.href.split('#');
  // if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
  //   window.entryUrl = href[0];
  // }
  // const u = navigator.userAgent;
  // const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

  getJsSdkConfigApi({
    url: regiestUrl,
  }).then(res => {
    wxShareAll(JSON.parse(res.data.config));
    wxShareTxt(title, desc, imgUrl, url, other);
  });
}

function wxShareAll(config) {
  // eslint-disable-next-line no-undef
  wx.config({
    debug: false,
    appId: config.appId,
    timestamp: config.timestamp,
    nonceStr: config.nonceStr,
    signature: config.signature,
    jsApiList: ['scanQRCode', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'hideMenuItems', 'openAddress', 'showMenuItems', 'chooseImage', 'uploadImage', 'startRecord', 'stopRecord', 'playVoice', 'stopVoice', 'uploadVoice']
  });
}

function wxShareTxt(title, desc, imgUrl, url, other) {
  // eslint-disable-next-line no-undef
  wx.ready(function () {
    const uid = store.getters.uid;
    const mer_name = store.getters.mer_name;
    const merchantInfo = store.getters.merchantInfo;
    const href = location.href;
    const query = href.split('?');
    let quryList = [];
    let newQuery = [];
    let newUrl = query[0];
    if (query[1]) {
      quryList = query[1].split('&');
      quryList.forEach(m => {
        if (m.indexOf('phone=') == -1 && m.indexOf('token=') == -1 && m.indexOf('openid=') ==
          -1 && m.indexOf('islogin=') == -1 && m.indexOf('inviteuid=') == -1 && m.indexOf('login_code=') == -1 && m.indexOf(
            'state=') == -1 && m.indexOf('login_time=') == -1) {
          newQuery = newQuery.concat([m]);
        }
      });
    }
    if (url) {
      newUrl = url;
      newQuery = [];
    }
    if (uid) {
      newQuery.push('inviteuid=' + uid);
    }
    if (newQuery && newQuery.length > 0) {
      newUrl += `?${newQuery.join('&')}`;
    }
    if (other) {
      newUrl += other;
    }
    let newimgurl = imgUrl.indexOf('!120a') > -1 ? imgUrl : `${imgUrl}!120a`;
    // eslint-disable-next-line no-undef
    wx.onMenuShareAppMessage({
      title: (title ? title : mer_name),
      desc: (desc ? desc : ' '),
      imgUrl: (imgUrl ? newimgurl : merchantInfo.mer_logo),
      link: newUrl,
    });
    // eslint-disable-next-line no-undef
    wx.onMenuShareTimeline({
      title: (title ? title : mer_name),
      imgUrl: (imgUrl ? newimgurl : merchantInfo.mer_logo),
      link: newUrl,
    });
  });
}