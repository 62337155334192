<template>
  <div>
    <template v-if="merchantInfo.fee_type == 'CNY'">￥<span class="p">{{ formatPriceNumber(price, 2) }}</span></template>
    <template v-if="merchantInfo.fee_type != 'CNY'">
      <div :class="{ 'flex': type == 1 }">
        <div class="flex-p">
          <span v-if="text" :style="'display:inline-block;width: 1rem;' + textStyle">{{ text }}</span><span class="ft12">{{ merchantInfo.fee_type }} </span><span :class="{ 'p': type != 3, 'big': type == 2 }">{{ formatPriceNumber(price, 2) }} </span>
        </div>
        <div class="flex-p" :class="{ 'ml10': type == 1 }">
          <span v-if="text" :style="'display:inline-block;width: 1rem;' + textStyle"></span><span class="ft12">RMB </span><span :class="{ 'p': type != 3, 'big': type == 2 }">{{ formatPriceNumber(calculatePrice(price, merchantInfo.fee_rate), 2) }} </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from "vuex";
import { formatPriceNumber, calculatePrice } from "@/utils/util";
export default {
  props: {
    price: Number,
    text: String,
    type: String,
    textStyle: String,
    merchant: Object,
  },
  setup(props) {
    const store = useStore();

    const merchantInfo = computed(() => {
      if (props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      merchantInfo,

      formatPriceNumber,
      calculatePrice
    }
  }
};
</script>

<style lang="scss" scoped>
.p{
  font-family: NUM;
}

.flex{
  display: flex;
}
.flex .flex-p .ft12{
  margin-right: 6px;
}
.flex .flex-p {
  font-family: NUM;
}
.big{
  font-size: 48px;
}
</style>
