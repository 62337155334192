<template>
  <div class="rubik">
    <template v-if="content.style == 1 || content.style == 2 || content.style == 3 || content.style == 5">
      <div
        :data-url="item.url || ''"
        v-for="(item, index) in adverArray"
        :key="index"
        class="one-line"
        catchtap="goPageAction"
        data-jump="1"
        :data-link="item.link.link || ''"
      >
        <div
          class="img"
          v-if="item.url"
        >
          <img
            class="img-block"
            :src="item.url + '!m640'"
          >
        </div>
        <div
          class="no-img item-flex-center"
          v-else
          :style="{ height: ((750 / (content.style == 5 ? 4 : content.style)) / 75) + 'rem' }"
        >
          <img
            class="img-block"
            src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
          >
        </div>
      </div>
    </template>
    <template v-if="content.style == 4 || content.style == 6">
      <div class="one-line">
        <div
          :data-url="adverArray[0].url"
          class="img"
          v-if="adverArray[0].url"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="adverArray[0].link.link || ''"
        >
          <img
            class="img-block"
            :src="adverArray[0].url + '!m640'"
            mode="widthFix"
          >
        </div>
        <div
          class="no-img item-flex-center"
          v-else
          :style="{ height: ((content.style == 6 ? 188 : 376) / 75) + 'rem' }"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="adverArray[0].link.link || ''"
        >
          <img
            class="img-block"
            src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
          >
        </div>
        <template v-if="content.style == 6">
          <div
            :data-url="adverArray[2].url"
            class="img"
            v-if="adverArray[2].url"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[2].link.link || ''"
          >
            <img
              class="img-block"
              :src="adverArray[2].url + '!m640'"
              mode="widthFix"
            >
          </div>
          <div
            class="no-img item-flex-center"
            v-else
            style="height: 2.50666rem;"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[2].link.link || ''"
          >
            <img
              class="img-block"
              src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
            >
          </div>
        </template>
      </div>
      <div class="one-line">
        <div
          :data-url="adverArray[1].url"
          class="img"
          v-if="adverArray[1].url"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="adverArray[1].link.link || ''"
        >
          <img
            class="img-block"
            :src="adverArray[1].url + '!m640'"
            mode="widthFix"
          >
        </div>
        <div
          class="no-img item-flex-center"
          v-else
          style="height: 2.50666rem;"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="adverArray[1].link.link || ''"
        >
          <img
            class="img-block"
            src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
          >
        </div>
        <template v-if="content.style == 4">
          <div
            :data-url="adverArray[2].url"
            class="img"
            v-if="adverArray[2].url"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[2].link.link || ''"
          >
            <img
              class="img-block"
              :src="adverArray[2].url + '!m640'"
              mode="widthFix"
            >
          </div>
          <div
            class="no-img item-flex-center"
            v-else
            style="height: 2.50666rem;"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[2].link.link || ''"
          >
            <img
              class="img-block"
              src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
            >
          </div>
        </template>
        <template v-else>
          <div
            :data-url="adverArray[3].url"
            class="img"
            v-if="adverArray[3].url"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[3].link.link || ''"
          >
            <img
              class="img-block"
              :src="adverArray[3].url + '!m640'"
              mode="widthFix"
            >
          </div>
          <div
            class="no-img item-flex-center"
            v-else
            style="height: 2.50666rem;"
            catchtap="goPageAction"
            data-jump="1"
            :data-link="adverArray[3].link.link || ''"
          >
            <img
              class="img-block"
              src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
            >
          </div>
        </template>
      </div>
    </template>
    <template v-if="content.style == 7">
      <div class="img-container">
        <div
          :data-url="item.url || ''"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="item.link.link || ''"
          v-for="(item, index) in adverArray"
          :key="index"
          class="img-absolute"
          :style="{
            height: ((windowFix * (item.rowEnd - item.rowStart + 1)) / 75) + 'rem',
            width: ((windowFix * (item.columnEnd - item.columnStart + 1)) / 75) + 'rem',
            left: ((windowFix * (item.columnStart - 1)) / 75) + 'rem',
            top: ((windowFix * (item.rowStart - 1)) / 75) + 'rem'
          }"
        >
          <div
            class="img"
            v-if="item.url"
          >
            <img
              class="img-block"
              :src="item.url + '!m640'"
              mode="aspectFill"
            >
          </div>
          <div
            class="no-img item-flex-center"
            v-else
          >
            <img
              class="img-block"
              src="https://artimg2.artart.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png"
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { toRefs, computed } from 'vue'
import { useRouter } from "vue-router";

export default {
  props: {
    content: Object,
    facade: Object,
  },
  setup(props) {
    const { content } = toRefs(props)

    const adverArray = computed(() => content.value.data)
    const windowFix = computed(() => parseInt(750 / content.value.density, 10))

    const router = useRouter();
    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf('pdf?url=') === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == 'writeOff') {
          // app.hxkqAction();
        } else if (item.link.indexOf('phone?phone=') === 0) {
          const phone = item.link.split('=')[1];
          window.location.href = 'tel://' + phone;
        } else if (item.link == 'open_share' || item.link == 'contact') {} else {
          let jump = 'push';
          if (item.jump == 2) {
            jump = 'replace'
          }
          const queryIndex = item.link.indexOf('?');
          const query = {};
          if (queryIndex !== 0) {
            const q = item.link.split('?');
            if (q[1]) {
              const qList = q[1].split('&');
              qList.forEach(m => {
                const mQ = m.split('=');
                if (mQ[0] && mQ[1]) {
                  query[mQ[0]] = mQ[1]
                }
              })
            }
          }
          router[jump]({ path: item.link, query: query })
        }
      } 
    };

    return {
      adverArray,
      windowFix,
      goPageAction
    };
  },
};
</script>
<style lang="scss" scoped>
.rubik{
  width: 100vw;
  display: flex;
  align-items: flex-start;
}

.one-line{
  flex: 1;
  overflow: hidden;
  position: relative;
}

.img{
  width: 100%;
  position: relative;
}
.img .img-block{
  width: 100%;
}
.no-img{
  width: 100%;
  position: relative;
}


.img-container{
  width: 750px;
  height: 750px;
  position: relative;
}
.img-absolute{
  position: absolute;
}
.img-absolute .no-img, .img-absolute .img{
  width: 100%;
  height: 100%;
}
.img-absolute .img .img-block{
  width: 100%;
  height: 100%;
}


.hide-button{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
}
</style>