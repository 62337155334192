<template>
  <div v-if="payType == 'wechat_h5'">
    <div class="common-header item-flex-center fw500">
      <div>{{ title }}</div>
      <div
        class="back-img item-flex-center"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png"
          alt=""
        >
      </div>
    </div>
    <div class="common-header-bg"></div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { Sticky } from "vant";
import { useRouter } from "vue-router";
import { returnWxPayType } from "@/utils/util";

export default {
  components: {
    [Sticky.name]: Sticky,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    }
  },
  setup() {
    const router = useRouter();
    const payType = ref(null);

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace('/');
      } else {
        router.back();
      }
    };

    onBeforeMount(() => {
      if(window.uni){
        payType.value = 'wechat_h5';
      }else{
        payType.value = returnWxPayType();
      }
      
    })

    return { backAction, payType };
  },
};
</script>

<style lang="scss" scoped>
.common-header {
  height: 88px;
  background: #fff;
  font-size: 34px;
  z-index: 10;
  color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  .back-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 88px;
    width: 88px;
    z-index: 11;

    img {
      width: 48px;
      height: 48px;
      display: block;
    }
  }
}
.common-header-bg{
  height: 88px;
  background: #fff;
}
</style>