<template>
  <div class="main">
    <div class="express-view">
      <div class="express-view-top flex align-items-c ft12">
        <div
          class="flex align-items-c"
          @click="changeExpressTypeAction(1)"
        >
          <img
            v-if="expressType == 1"
            src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
            alt=""
          >
          <div>快递</div>
        </div>
        <div
          class="flex align-items-c ml48"
          @click="changeExpressTypeAction(2)"
        >
          <img
            v-if="expressType == 2"
            src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
            alt=""
          >
          <div>自提</div>
        </div>
        <!-- <div
          class="flex align-items-c ml48"
          @click="changeExpressTypeAction(3)"
        >
          <img
            v-if="expressType == 3"
            src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
            alt=""
          >
          <img
            v-else
            src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
            alt=""
          >
          <div>委托他人提货</div>
        </div> -->
      </div>
      <div class="express-view-center">
        <template v-if="expressType == 1">
          <template v-if="changeAddress === ''">
            <div
              class="item-flex-center"
              style="color: #347ff4;"
              @click="addAddressAction"
            >新增收货地址</div>
          </template>
          <template v-else-if="changeAddress">
            <div class="flex justify-b ft14 fw500">
              <div class="address-name flex-one">{{ changeAddress.real_name }}</div>
              <div class="address-phone">{{ changeAddress.phone }}</div>
            </div>
            <div class="flex justify-b ft12 mt8">
              <div class="address flex-one">{{ changeAddress.province }} {{ changeAddress.district }} {{ changeAddress.city }} {{ changeAddress.detail }}</div>
              <div
                class="address-edit ml10"
                @click="goChangeAddressAction"
              >修改</div>
            </div>
          </template>
        </template>
        <template v-else-if="expressType == 2">
          <div class="flex justify-b ft14 fw500">
            <div class="address-name flex-one">{{ takeInfo.mer_take_name }}</div>
            <div class="address-phone">{{ takeInfo.mer_take_phone }}</div>
          </div>
          <div class="flex justify-b ft12 mt8">
            <div class="address flex-one">{{ takeInfo.mer_take_address }}</div>
          </div>
        </template>
      </div>
      <div
        class="express-view-bottom flex justify-b"
        v-if="expressType == 1 && order_is_valuation"
      >
        <div class="flex align-items-c">
          <div class="mr10">运输保价</div>
          <van-switch
            v-model="openInsuredPrice"
            active-color="#009406"
            size="38"
            :disabled="order_is_valuation == 1"
          />
        </div>
        <div class="flex align-items-c">
          <div class="mr10">保价金额</div>
          <input
            class="common-input"
            type="number"
            v-model="insuredPrice"
            :disabled="!openInsuredPrice"
            @blur="checkInsuredPrice"
          >
        </div>
      </div>
    </div>
    <div class="common-title">货品信息</div>
    <div class="product-view common-view">
      <div
        class="item flex align-items-c"
        v-for="(item, index) in auctionList"
        :key="index"
      >
        <div class="img">
          <img
            :src="item.orderProduct[0].cart_info.product.image + '!120a'"
            alt=""
          >
        </div>
        <div class="desc">
          <div class="name fw500 ft12"><span
              v-if="item.orderProduct[0].cart_info.product.lot"
              class="mr5"
            >LOT{{ item.orderProduct[0].cart_info.product.lot }}</span>{{ item.orderProduct[0].cart_info.product.store_name }}</div>
          <div class="price">
            <span>落槌价 <b>{{ formatPriceNumber(Number(item.orderProduct[0].product_price), 2) }}</b></span>
            <span>佣金 <b>{{ formatPriceNumber(Number(item.commission_price), 2) }}</b></span>
            <span v-if="expressType == 1">
              <template v-if="item.orderProduct[0].cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(item.orderProduct[0].cart_info.product.postage_price), 2) }}</template>
              <template v-else-if="item.orderProduct[0].cart_info.product.postage_type == 3">包邮</template>
              <template v-else-if="item.orderProduct[0].cart_info.product.postage_type == 4">到付</template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="true">
      <div class="common-title">实名认证</div>
      <div class="receipt common-view ft14">
        <div
          class="item flex align-items-c"
          @click="goVerifyAction"
        >
          <div
            v-if="isVerify == 0"
            class="l flex-one line1"
          >请先实名认证</div>
          <div
            v-else
            class="l flex-one line1"
          >已实名</div>
          <div
            v-if="isVerify == 0"
            class="c ft12"
            style="color: #666"
          >
            <img
              src="https://artimg2.artart.cn/image/20220219/86c43e7fab3564d61750317942dc0d83.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </template>
    <template v-if="is_open_invoice">
      <div class="common-title">发票开具</div>
      <div class="receipt common-view ft14">
        <div
          class="item flex align-items-c"
          @click="openSetInvoice"
        >
          <div
            v-if="invoiceData && invoiceData.open == 1"
            class="l flex-one line1"
          >{{ invoiceData.receipt_type == 1 ? '普票' : '电子' }}-{{ invoiceData.receipt_company }}</div>
          <div
            v-else-if="invoiceData && invoiceData.open == 2"
            class="l flex-one line1"
          >不开具发票</div>
          <div
            v-else-if="invoiceData && invoiceData.open == 0"
            class="l flex-one line1"
          >请设置发票信息</div>
          <div
            v-else
            class="l flex-one line1"
          >请设置发票信息</div>
          <!-- <div class="l flex-one line1">电子-商品明细-北京赛纳科技有限公司</div> -->
          <div
            class="c ft12"
            style="color: #666"
          >
            <img
              src="https://artimg2.artart.cn/image/20220219/86c43e7fab3564d61750317942dc0d83.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </template>
    <div class="common-title">抵扣金额</div>
    <div class="deduction common-view ft14">
      <div class="item flex align-items-c">
        <div class="l">余额</div>
        <div class="flex-one ml52">
          <input
            type="number"
            class="common-input"
            v-model="useBalance"
            placeholder="输入金额"
          >
        </div>
        <div
          class="c ft12"
          style="color: #666"
        >可用余额 {{ formatPriceNumber(account, 2) }}</div>
      </div>
      <div
        v-if="entrust_account"
        class="item flex align-items-c"
      >
        <div class="l">委托方余额</div>
        <div class="flex-one ml52">
          <input
            type="number"
            class="common-input"
            v-model="useEntrustAccount"
            placeholder="输入金额"
          >
        </div>
        <div
          class="c ft12"
          style="color: #666"
        >可用余额 {{ formatPriceNumber(entrust_account, 2) }}</div>
      </div>
    </div>
    <template v-if="totalPrice - useBalance - useEntrustAccount - (expressType != 1 ? totalPostage : 0) > 0">
      <div class="common-title">支付方式</div>
      <div
        class="pb32"
        style="background: #fff;"
      >
        <PayTypeView
          v-model="payType"
          :pay_evidence="pay_evidence"
          @upload_pay_evidence="set_pay_evidence"
        />
      </div>
    </template>
    <div class="no-data flex item-flex-center">
      <div class="heng"></div>
      <div class="txt">我是有底线的</div>
      <div class="heng"></div>
    </div>
    <div class="bottom-btn flex align-items-c">
      <div class="l">还需支付<span
          class="fw500"
          style="color: #FB7575;"
        >{{ formatPriceNumber((totalPrice - useBalance - useEntrustAccount - (expressType != 1 ? totalPostage : 0)), 2) }}</span><template v-if="merchantInfo">{{ merchantInfo.fee_type_text }}</template></div>
      <div
        class="r-btn ft14 fw500"
        :style="rightBtnStyle"
        @click="goPayAction"
      >立即支付</div>
    </div>

    <van-action-sheet
      v-model:show="showSetReceipt"
      title="开具发票"
    >
      <div class="receipt-view">
        <div class="receipt-item flex align-items-c">
          <div class="l">是否开票</div>
          <div class="c flex-one flex align-items-c">
            <div
              class="type flex align-items-c"
              @click="setReceiptType('open', 1)"
            >
              <img
                v-if="receiptData.open == 1"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>开票</div>
            </div>
            <div
              class="type flex align-items-c ml20"
              @click="setReceiptType('open', 2)"
            >
              <img
                v-if="receiptData.open == 2"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>不开发票</div>
            </div>
          </div>
        </div>
        <div class="receipt-item flex align-items-c">
          <div class="l">发票抬头</div>
          <div class="c flex-one flex align-items-c">
            <div
              class="type flex align-items-c"
              @click="setReceiptType('receipt_header_type', 1)"
            >
              <img
                v-if="receiptData.receipt_header_type == 1"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>个人</div>
            </div>
            <div
              class="type flex align-items-c ml20"
              @click="setReceiptType('receipt_header_type', 2)"
            >
              <img
                v-if="receiptData.receipt_header_type == 2"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>企业</div>
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div class="receipt-item flex align-items-c">
          <div class="l">发票类型</div>
          <div class="c flex-one flex align-items-c">
            <!-- <div
              class="type flex align-items-c"
              @click="setReceiptType('receipt_type', 1)"
            >
              <img
                v-if="receiptData.receipt_type == 1"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>普通发票</div>
            </div> -->
            <div
              class="type flex align-items-c"
              @click="setReceiptType('receipt_type', 2)"
            >
              <img
                v-if="receiptData.receipt_type == 2"
                src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              >
              <div>普通电子发票</div>
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div class="receipt-item p16 flex align-items-c">
          <div class="l">{{ receiptData.receipt_header_type == 1 ? '个人名称' : '企业名称' }}<span>*</span></div>
          <div class="c flex-one flex align-items-c">
            <div class="inp">
              <input
                type="text"
                v-model="receiptData.receipt_company"
                :disabled="receiptData.open != 1"
              >
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div
          v-if="receiptData.receipt_header_type == 2"
          class="receipt-item p16 flex align-items-c"
        >
          <div class="l">企业税号<span>*</span></div>
          <div class="c flex-one flex align-items-c">
            <div class="inp">
              <input
                type="text"
                v-model="receiptData.duty_paragraph"
                :disabled="receiptData.open != 1"
              >
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div class="receipt-item p16 flex align-items-c">
          <div class="l">收票人手机号<span>*</span></div>
          <div class="c flex-one flex align-items-c">
            <div class="inp">
              <input
                type="text"
                v-model="receiptData.phone"
                :disabled="receiptData.open != 1"
              >
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div class="receipt-item p16 flex align-items-c">
          <div class="l">收票人邮箱</div>
          <div class="c flex-one flex align-items-c">
            <div class="inp">
              <input
                type="text"
                v-model="receiptData.email"
                :disabled="receiptData.open != 1"
              >
            </div>
          </div>
          <div
            v-if="receiptData.open != 1"
            class="bg"
          ></div>
        </div>
        <div
          v-if="invoice_description"
          class="remarks"
        >{{invoice_description}}</div>
        <div
          class="receipt-btn"
          :style="rightBtnStyle"
          @click="createInvoice"
        >确认</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch } from "vue";
import { useStore } from "vuex";
import { Switch, Toast, ActionSheet, Dialog } from "vant";
import PayTypeView from "@/components/PayType";
import { useRoute, useRouter } from "vue-router";
import { orderSettlementInfo, payAuctionOrder, takeInfoApi } from "@/api/order";
import { getConfigClassKeys } from "@/api/system";
import { addressList } from "@/api/user";
import { formatPriceNumber, payAction, returnWxPayType } from "@/utils/util";

export default {
  components: {
    [Switch.name]: Switch,
    [ActionSheet.name]: ActionSheet,
    PayTypeView,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const orderIds = ref("");
    const changeAddress = ref(null);
    const expressType = ref(1); // 快递类型 1 快递 2 自提 3 委托
    const openInsuredPrice = ref(true); // 是否保价
    const insuredPrice = ref(""); // 保价金额
    const useBalance = ref(""); // 使用余额（元）
    const useEntrustAccount = ref(""); // 使用余额（元）
    const usePoints = ref(""); // 使用积分（元）
    const payType = ref("wx"); // 支付方式
    const totalPrice = ref("");
    // const bondMoney = ref(0);
    const auctionList = ref([]);
    const pay_evidence = ref("");
    const totalPostage = ref(0);
    const is_open_invoice = ref(0);
    const invoice_reminder = ref(0);
    const invoice_description = ref("");
    const order_is_valuation = ref(0);
    const takeInfo = ref({});

    onBeforeMount(() => {
      // 初始化页面
      orderIds.value = route.query.order_id;
      store.dispatch("user/getIsApplyVerify");
      getConfigClassKeys("auction_rule_set").then((res) => {
        is_open_invoice.value = res.data.is_open_invoice;
        invoice_reminder.value = res.data.invoice_reminder;
        invoice_description.value = res.data.invoice_description;
        order_is_valuation.value = res.data.order_is_valuation;
      });
      orderSettlementInfo({ order_ids: orderIds.value })
        .then((res) => {
          takeInfoApi({
            merchant_id: res.data.orderList[0].mer_id,
          }).then((res) => {
            takeInfo.value = res.data;
          });
          insuredPrice.value = res.data.total_price;
          auctionList.value = res.data.orderList;
          totalPrice.value = res.data.total_price;
          totalPostage.value = res.data.total_postage;

          if (res.data.receiptInfo && res.data.receiptInfo.receipt_company) {
            const receipt = res.data.receiptInfo;
            receiptData.value = {
              open: 1,
              receipt_header_type: receipt.receipt_header_type,
              receipt_type: receipt.receipt_type,
              receipt_content_type: receipt.receipt_content_type,
              receipt_company: receipt.receipt_company,
              duty_paragraph: receipt.duty_paragraph,
              company_bank_name: receipt.company_bank_name,
              company_bank_no: receipt.company_bank_no,
              company_register_address: receipt.company_register_address,
              company_register_phone: receipt.company_register_phone,
              phone: receipt.delivery_info ? receipt.delivery_info.phone : "",
              email: receipt.delivery_info ? receipt.delivery_info.email : "",
            };
          }
          // bondMoney.value = res.data.availableFrozen.price;
          account.value =
            res.data.userAccountInfo.now_money + res.data.availableFrozen.price;

          useBalance.value =
            account.value > totalPrice.value ? totalPrice.value : account.value;

          // entrust_account.value = res.data.entrustWalletInfo.now_money;

          // 如果有余额  计算自动使用多少余额
          // if (account.value) {
          //   setMaxUseAccount();
          // }
        })
        .catch((message) => {
          console.log(message);
          Toast(message);
          setTimeout(() => {
            router.back();
          }, 1400);
        });
      loadAddress();
      
    });
    watch(expressType, (val) => {
      // useBalance.value = 0;
      if (
        val == 2 &&
        useBalance.value > totalPrice.value - totalPostage.value
      ) {
        useBalance.value = totalPrice.value - totalPostage.value;
      }
      useEntrustAccount.value = 0;
    });
    watch(useBalance, (val) => {
      if (
        val > account.value ||
        val > totalPrice.value - useEntrustAccount.value
      ) {
        if (account.value > totalPrice.value - useEntrustAccount.value) {
          useBalance.value = totalPrice.value - useEntrustAccount.value;
        } else {
          useBalance.value = account.value;
        }
      }
    });
    watch(useEntrustAccount, (val) => {
      if (
        val > entrust_account.value ||
        val > totalPrice.value - useBalance.value
      ) {
        if (entrust_account.value > totalPrice.value - useBalance.value) {
          useEntrustAccount.value = totalPrice.value - useBalance.value;
        } else {
          useEntrustAccount.value = entrust_account.value;
        }
      }
    });

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const isVerify = computed(() => {
      return store.getters.isVerify ? store.getters.isVerify : 0;
    });

    const loadAddress = () => {
      addressList()
        .then((res) => {
          const address_id = localStorage.getItem("address_id");
          const list = res.data.list;
          if (list.length <= 0) {
            changeAddress.value = "";
          } else if (address_id) {
            list.forEach((m) => {
              if (m.address_id == address_id) {
                changeAddress.value = m;
              }
            });
          } else {
            changeAddress.value = list[0];
          }
          localStorage.setItem("address_id", "");
        })
        .catch((message) => Toast(message));
    };

    // 余额
    const account = ref("");
    const entrust_account = ref("");

    // 选择快递类型
    const changeExpressTypeAction = (key) => (expressType.value = key);

    // 选择支付方式
    const changePayTypeAction = (type) => (payType.value = type);

    const addAddressAction = () => {
      router.push("/users/address_detail?buy=auction");
    };

    const goChangeAddressAction = () => {
      router.push("/users/address_list?buy=auction");
    };

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const showSetReceipt = ref(false); // 展开发票设置
    const receiptData = ref({
      open: 1,
      receipt_header_type: 1,
      receipt_type: 2,
      receipt_content_type: 1,
      receipt_company: "",
      duty_paragraph: "",
      phone: "",
      email: "",
    });

    const invoiceData = ref(null);

    const openSetInvoice = () => {
      showSetReceipt.value = true;
    };

    const createInvoice = () => {
      if (!receiptData.value.open) {
        Toast("请选择是否开票");
        return false;
      }
      if (receiptData.value.open == 1) {
        if (!receiptData.value.receipt_company) {
          Toast(
            `请输入${
              receiptData.value.receipt_header_type == 1
                ? "个人名称"
                : "企业名称"
            }`
          );
          return false;
        }
        if (
          receiptData.value.receipt_header_type == 2 &&
          !receiptData.value.duty_paragraph
        ) {
          Toast(`请输入企业税号`);
          return false;
        }
        if (!receiptData.value.phone) {
          Toast("请输入收票人手机号");
          return false;
        }
      }

      invoiceData.value = JSON.parse(JSON.stringify(receiptData.value));
      showSetReceipt.value = false;
    };

    const set_pay_evidence = (img) => {
      pay_evidence.value = img;
    };

    const setReceiptType = (key, value) => {
      // 修改发票类型
      if (receiptData.value.open) {
        receiptData.value[key] = value;
      }
    };
    let payLock = false;

    const goPayAction = () => {
      // 去支付
      if (
        (!changeAddress.value || !changeAddress.value.address_id) &&
        expressType.value == 1
      ) {
        Toast("请选择收货地址");
        return false;
      }
      const data = {
        order_ids: orderIds.value,
        pay_type: payType.value,
        delivery_type: expressType.value,
        use_wallet_price: useBalance.value,
        use_entrust_wallet_price: useEntrustAccount.value,
      };
      if (data.delivery_type == 1) {
        data.address_id = changeAddress.value.address_id;
      }
      if (
        totalPrice.value -
          useBalance.value -
          useEntrustAccount.value -
          (data.delivery_type != 1 ? totalPostage.value : 0) <=
        0
      ) {
        payType.value = "wx";
      }
      if (payType.value == "wx") {
        // if (payType.value == "wx" && isWeiXin()) {
        data.pay_type = returnWxPayType();
      } else if (payType.value == "bank_transfer") {
        if (!pay_evidence.value) {
          Toast("请上传支付凭证");
          return false;
        }
        data.pay_evidence = pay_evidence.value;
      }
      if (openInsuredPrice.value && order_is_valuation.value) {
        data.delivery_insured_price = insuredPrice.value;
      }
      if (invoiceData.value && invoiceData.value.open == 1) {
        data.receipt_data = JSON.parse(JSON.stringify(invoiceData.value));
      }
      if (
        is_open_invoice.value &&
        invoice_reminder.value &&
        (!invoiceData.value || (invoiceData.value && !invoiceData.value.open))
      ) {
        Dialog({
          message: "您还未设置发票信息 \n如不开发票请直接支付",
          showCancelButton: true,
          confirmButtonText: "直接支付",
          cancelButtonText: "设置发票",
        })
          .then(() => {
            payBeforeAction(data);
          })
          .catch(() => {
            openSetInvoice();
          });
        return false;
      }
      payBeforeAction(data);
    };
    const payBeforeAction = (data) => {
      store
        .dispatch("user/applyVerifyInfo")
        .then((type) => {
          if (type == 1 || type == 2) {
            if (payLock) return false;
            payLock = true;
            payAuctionOrder(data)
              .then((res) => {
                if (res.data.status == 1) {
                  payLock = false;
                  Toast("支付成功！");
                  router.replace("/order/list");
                } else if (data.pay_type == returnWxPayType()) {
                  payAction(
                    res.data.result.config,
                    () => {
                      Toast("支付成功");
                      payLock = false;
                      router.replace("/order/list");
                    },
                    () => {
                      payLock = false;
                    },
                    () => {
                      payLock = false;
                    }
                  );
                } else if (data.pay_type == "bank_transfer") {
                  payLock = false;
                  Toast("操作成功,等待人工审核！");
                  router.replace("/order/list");
                }
              })
              .catch((message) => {
                payLock = false;
                Toast(message);
              });
          }
        })
        .catch((message) => Toast(message));
    };

    const goVerifyAction = () => {
      if (isVerify.value == 0) {
        router.push("/users/apply_verify");
      }
    };

    const checkInsuredPrice = () => {
      if (
        openInsuredPrice.value &&
        (!Number(insuredPrice.value) || isNaN(insuredPrice.value))
      ) {
        Toast("保价金额不能小于1元");
        insuredPrice.value = 1;
      }
    };

    return {
      rightBtnStyle,
      expressType,
      openInsuredPrice,
      insuredPrice,
      useBalance,
      useEntrustAccount,
      usePoints,
      payType,
      changeAddress,
      auctionList,
      account,
      entrust_account,
      totalPrice,
      showSetReceipt,
      receiptData,
      invoiceData,
      pay_evidence,
      totalPostage,
      merchantInfo,
      is_open_invoice,
      invoice_reminder,
      invoice_description,
      order_is_valuation,
      isVerify,
      takeInfo,

      changeExpressTypeAction,
      changePayTypeAction,
      addAddressAction,
      goChangeAddressAction,
      formatPriceNumber,
      goPayAction,
      openSetInvoice,
      setReceiptType,
      createInvoice,
      set_pay_evidence,
      goVerifyAction,
      checkInsuredPrice,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  background: #f5f5f5;
  box-sizing: border-box;
  padding-bottom: 164px;

  .common-title {
    padding-top: 32px;
    font-size: 28px;
    font-weight: 500;
    padding-left: 32px;
    background: #fff;
    margin-top: 32px;
  }
}
.express-view {
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 32px 32px;

  .express-view-top {
    img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }
  }

  .ml48 {
    margin-left: 48px;
  }

  .express-view-center {
    padding: 22px 32px;
    background: #f7f7f7;
    margin-top: 26px;
    border-radius: 8px;

    .mt8 {
      margin-top: 8px;
    }

    .address {
      color: #999;
    }
    .address-edit {
      color: #347ff4;
    }
  }

  .express-view-bottom {
    margin-top: 32px;
    margin-bottom: 10px;
    color: #666;
    font-size: 28px;
  }
}
.common-input {
  text-align: center;
  width: 218px;
  height: 64px;
  line-height: 64px;
  border: 2px solid #eeeeee;
  box-sizing: border-box;
}

.product-view {
  .item {
    border-top: 2px solid #eeeeee;
    padding: 32px 0;
    &:first-child {
      border-top: none;
    }
    .img {
      width: 128px;
      height: 128px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 8px;
      }
    }

    .desc {
      flex: 1;
      margin-left: 18px;
      color: #000;

      .name {
        height: 66px;
        line-height: 33px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .price {
        margin-top: 16px;
        color: #999999;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.receipt {
  .item {
    padding: 32px 0;

    img {
      width: 32px;
      height: 32px;
      display: block;
    }
  }
}

.deduction {
  .item {
    padding-top: 32px;

    &:first-child {
      padding-top: 22px;
    }

    &:last-child {
      padding-bottom: 32px;
    }

    .c {
      color: #999;
      margin-left: 16px;
      font-weight: 400;

      span {
        color: #347ff4;
        margin-left: 16px;
      }
    }

    .w130 {
      width: 130px;
    }

    .ml52 {
      margin-left: 52px;
    }
    .ml12 {
      margin-left: 12px;
    }
  }
}

.common-view {
  background: #fff;
  border-radius: 8px;
  padding: 0 32px;
  color: #666;
}

.bottom-btn {
  padding-bottom: 68px;
  height: 96px;
  background: #fff;
  box-shadow: 0px -2px 0px 0px #eeeeee;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .l {
    padding-left: 32px;
    color: #666;
    flex: 1;
    padding-right: 32px;

    span {
      font-size: 32px;
      margin-left: 10px;
    }
  }
  .r-btn {
    height: 96px;
    width: 240px;
    text-align: center;
    line-height: 96px;
    background: #003d7c;
    color: #fff;
    border-radius: 0 !important;
  }
}

.receipt-view {
  .receipt-btn {
    margin: 32px 32px 68px 32px;
    line-height: 80px;
    text-align: center;
  }
  .receipt-item {
    padding: 18px 32px;
    position: relative;

    .bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      background: rgba(255, 255, 255, 0.5);
    }

    &.p16 {
      padding: 10px 32px;
    }
    .l {
      width: 190px;
      font-size: 28px;
      color: #666;

      span {
        color: #fb7575;
      }
    }

    .c {
      color: #000;
      font-size: 28px;
      img {
        width: 48px;
        height: 48px;
        display: block;
        margin-right: 4px;
      }

      .inp {
        height: 64px;
        box-sizing: border-box;
        border: 2px solid #ddd;
        border-radius: 8px;
        width: 100%;
        padding: 10px;

        input {
          border: none;
          width: 100%;
          background: none;
          height: 44px;
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.remarks {
  padding: 16px 32px 16px 32px;
  margin: 12px 32px 0 32px;
  background: #fff8ef;
  border-radius: 8px;
  border: 2px solid #f4d5ae;
  font-size: 24px;
  color: #c1955c;
  white-space: pre-wrap;
  line-height: 30px;
  max-height: 100000px;
}
</style>