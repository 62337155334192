import request from './request';

/** 
 * 拍卖会详情
 */
 export function auctionVenueInfoApi(data) {
  return request.get(`auction/venue/info`, data);
}

/** 
 * 专场详情
 */
 export function albumDetail(id) {
  return request.get(`auction/album/info/${id}`);
}

/** 
 * 专场列表
 */
export function albumListApi(data) {
  return request.get(`/auction/album/list`, data);
}

/** 
 * 专场详情
 */
export function userNumberPlateHandle(data) {
  return request.post(`auction/numberPlate/userNumberPlateHandle`, data);
}

/** 
 * 拍品列表
 */
export function auctionList(data) {
  return request.get(`auction/list`, data);
}

/** 
 * 拍品详情
 */
export function auctionDetail(id) {
  return request.get(`auction/info/${id}`);
}

/** 
 * 拍品出价记录
 */
export function bidRecordList(data) {
  return request.get(`auction/bidRecordList`, data);
}

/** 
 * 拍品出价
 */
export function auctionBidPrice(data) {
  return request.post(`auction/bidPrice`, data);
}

/** 
 * 检测是否缴纳保证金
 */
export function checkBondPay(id) {
  return request.get(`auction/order/checkAuctionBondPay/${id}`);
}

/** 
 * 检测是否缴纳保证金
 */
export function checkAlbumBondPay(id) {
  return request.get(`auction/order/checkAlbumBondPay/${id}`);
}

/** 
 * 缴纳专场保证金
 */
 export function payAlbumBond(data) {
  return request.post(`auction/order/payAlbumBond`, data);
}

/** 
 * 缴纳拍品保证金
 */
 export function payAuctionBond(data) {
  return request.post(`auction/order/payAuctionBond`, data);
}

/** 
 * 获取代理出价
 */
 export function getProxyInfo(data) {
  return request.get(`auction/getProxyInfo`, data);
}

/** 
 * 添加代理出价
 */
 export function addProxy(data) {
  return request.post(`auction/addProxy`, data);
}

/** 
 * 取消代理出价
 */
 export function cancelProxy(data) {
  return request.post(`auction/cancelProxy`, data);
}

/** 
 * 获取专场正在进行中或预展中的拍品
 */
 export function albumStartAuctionInfo(data) {
  return request.get(`auction/syn/albumStartAuctionInfo`, data);
}

/** 
 * 获取专场统计数据
 */
 export function albumAuctionStatistical(data) {
  return request.get(`auction/syn/albumAuctionStatistical`, data);
}

/** 
 * 获取专场下 直播间置顶拍品
 */
 export function getLiveTopAuction(data) {
  return request.get(`auction/live/getLiveTopAuction`, data);
}

/** 
 * 获取获取用户上一次出价
 */
 export function getLastUserBidRecord(data) {
  return request.get(`auction/getLastUserBidRecord`, data);
}

/**
 * 确认保留价格
 */
 export function confirmRetainPriceApi(data) {
  return request.post(`auction/confirmRetainPrice`, data);
}
/** 
 * 获取日历
 */
 export function getcalendar(data) {
  return request.get(`auction/album/calendar`, data);
}