<template>
  <div
    class="tag-view"
    :style="{ background: item.facade.isFixed ? (item.facade.background_color || '#ffffff') : item.facade.background_color }"
  >
    <div
      class="nav display-flex"
      :class="{ 'justify-center': item.facade.nav_style === 2 }"
      :style="{ padding: `0 ${item.facade.page_padding / 75}rem`, height: ((item.facade.nav_height || 96) / 75) + 'rem' }"
    >
      <template
        v-for="(m, i) in item.content.data"
        :key="`tag${index}${i}`"
      >
        <div
          class="items"
          :class="{ 'ml0': i === 0 }"
          :style="{ marginLeft: (item.facade.item_padding / 75) + 'rem' }"
        >
          <div
            class="item"
            :style="{
              fontSize: (item.facade.text_size / 75) + 'rem',
              fontWeight: item.facade.text_style,
              color: item.facade.text_color,
              height: ((item.facade.nav_height || 96) / 75) + 'rem'
            }"
            @click.stop="changeTagAction(index, i)"
          >
            <div
              class="txt"
              :style="{ fontWeight: (item.active || 0) === i ? 500 : item.facade.text_style, color: (item.active || 0) === i ? item.facade.text_active_color : item.facade.text_color, paddingTop: (item.facade.text_margin_top / 75) + 'rem' }"
            >
              {{ m.title }}</div>
            <div
              class="bg"
              v-if="(item.active || 0) === i"
              :style="{ background: item.facade.text_active_color }"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    index: [Number, String]
  },
  setup(props, context) {
    const changeTagAction = (index, i) => {
      context.emit('changeTag', { index, i });
    }

    return {
      changeTagAction
    }
  },
};
</script>

<style lang="scss" scoped>
.tag-view {
  overflow-x: auto;

  .nav {
    height: 96px;
  }

  .nav .items {
    flex-shrink: 0;
  }

  .nav .item {
    position: relative;
    height: 96px;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  .nav .items.ml0 {
    margin-left: 0 !important;
  }

  .nav .item .bg {
    position: absolute;
    width: 36px;
    height: 6px;
    border-radius: 4px;
    left: 50%;
    bottom: 18px;
    margin-left: -18px;
  }
}
</style>