<template>
  <div
    class="search item-align-center"
    :style="{ backgroundColor: facade.background_color, padding: `0.21333rem ${facade.page_padding / 75}rem` }"
  >
    <div
      v-if="content.front_icon && content.style === 1"
      style="position: relative;"
      catchtap="goPageAction"
      data-jump="1"
      :data-link="content.front_link.link || ''"
    >
      <img
        class="img-block"
        :src="content.front_icon + '!120a'"
        :style="{ width: ((facade.high_style - 8) / 75) + 'rem', height: ((facade.high_style - 8) / 75) + 'rem', marginRight: '0.21333rem' }"
      >
    </div>
    <div
      class="search-view item-align-center justify-start"
      catchtap="goSearch"
      :style="{
        color: facade.text_color,
        backgroundColor: facade.border_color,
        height: (facade.high_style / 75) + 'rem',
        borderRadius: (facade.border_radius / 75) + 'rem',
        fontSize: (facade.text_size / 75) + 'rem',
        width: (facade.width_style / 75) + 'rem',
        fontWeight: facade.text_style
      }"
    >
      <van-icon
        v-if="facade.icon_align == 'left'"
        name="search"
        :size="(facade.text_size)"
        :color="(facade.icon_color)"
        style="margin-right: 0.13333rem;"
      />
      <view class="flex-one">{{content.text}}</view>
      <van-icon
        v-if="facade.icon_align == 'right'"
        name="search"
        :size="(facade.text_size)"
        :color="(facade.icon_color)"
        style="margin-left: 0.133333rem;"
      />
    </div>
    <div
      v-if="content.after_icon && content.style === 1"
      style="position: relative;"
      catchtap="goPageAction"
      data-jump="1"
      :data-link="content.after_link.link || ''"
    >
      <img
        class="img-block"
        :src="content.after_icon + '!120a'"
        :style="{ width: ((facade.high_style - 8) / 75) + 'rem', height: ((facade.high_style - 8) / 75) + 'rem', marginLeft: '0.21333rem' }"
      >
    </div>
    <div
      v-if="content.style === 3"
      class="flex-one search-txt"
    >
      <view
        class="text-link item-align-center"
        :style="{ color: facade.text_link_color, fontSize: (facade.text_link_size / 75) + 'rem', fontWeight: facade.text_link_style, height: (facade.high_style / 75) + 'rem' }"
      >
        <view
          class="item"
          v-for="(item, index) in content.data"
          :key="index"
          catchtap="goPageAction"
          data-jump="1"
          :data-link="item.link.link || ''"
        >{{item.title}}
        </view>
      </view>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { Icon } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    content: Object,
    facade: Object,
  },
  setup() {
    const router = useRouter();

    const goPageAction = (e) => {
      const item = e.currentTarget.dataset;
      if (item.link) {
        if (item.link.indexOf('pdf?url=') === 0) {
          // const url = item.link.split("=")[1];
          // if (url) {
          //   app.openFiles(url);
          // }
        } else if (item.link == 'writeOff') {
          // app.hxkqAction();
        } else if (item.link.indexOf('phone?phone=') === 0) {
          const phone = item.link.split('=')[1];
          window.location.href = 'tel://' + phone;
        } else if (item.link == 'open_share' || item.link == 'contact') {} else {
          let jump = 'push';
          if (item.jump == 2) {
            jump = 'replace'
          }
          const queryIndex = item.link.indexOf('?');
          const query = {};
          if (queryIndex !== 0) {
            const q = item.link.split('?');
            if (q[1]) {
              const qList = q[1].split('&');
              qList.forEach(m => {
                const mQ = m.split('=');
                if (mQ[0] && mQ[1]) {
                  query[mQ[0]] = mQ[1]
                }
              })
            }
          }
          router[jump]({ path: item.link, query: query })
        }
      } 
    };

    return {
      goPageAction
    }
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.search-view {
  height: 64px;
  line-height: 64px;
  font-size: 28px;
  box-sizing: border-box;
  color: RGBA(156, 156, 156, 1);
  background-color: #ffffff;
  padding: 0 24px;
  flex-shrink: 0;
}

.search-content .flex-one {
  padding-left: 10px;
}

.search-content .img-block {
  flex-shrink: 0;
}

.text-link {
  flex-shrink: 0;
  overflow-x: auto;
  margin-left: 20px;
}

.text-link .item {
  flex-shrink: 0;
  margin-left: 20px;
  position: relative;
  &:first-child{
    margin-left: 0;
  }
}

// .search-txt{
//   overflow-x: auto;
// }
</style>