import request from './request';
/**
  * @description 验证码
  */
export function captchaApi(data) {
  return request.post(`auth/verify`, data);
}
/**
  * @description 微信登录
  */
export function wxLogin(code, data) {
  return request.post(`login/h5/wechat?code=${code}`, data);
}

/**
  * @description 微信绑定手机号
  */
 export function bindPhone(data) {
  return request.post(`login/h5/wechatRegisterBindPhone`, data);
}
/**
  * @description 手机号登录
  */
export function phoneLoginApi(data) {
  return request.post(`auth/AppPhoneLogin`, data);
}

/**
  * @description 用户信息
  */
 export function getInfo() {
  return request.get(`user`);
}

/** 
 * 实名认证
 */
 export function applyVerify(data) {
  return request.post(`user/applyVerify`, data);
}

/** 
 * 身份证照片校验
 */
 export function checkUserIdCardImage(data) {
  return request.post(`user/checkUserIdCardImage`, data);
}

/** 
 * 实名认证信息
 */
 export function applyVerifyInfo() {
  return request.get(`user/userVerifyInfo`);
}

/** 
 * 修改资料
 */
 export function editUserInfo(data) {
  return request.post(`user/editUserInfo`, data);
}

/** 
 * 收货地址列表
 */
 export function addressList() {
  return request.get(`user/address/lst`);
}

/** 
 * 收货地址详情
 */
 export function addressDetail(id) {
  return request.get(`user/address/detail/${id}`);
}

/** 
 * 创建收货地址
 */
 export function createAddress(id, data) {
  return request.post(`user/address/create`, data);
}

/** 
 * 修改收货地址
 */
 export function editAddress(id, data) {
  return request.post(`user/address/update/${id}`, data);
}

/** 
 * 设置为默认
 */
 export function setDefaultAddress(id) {
  return request.post(`user/address/setDefault/${id}`);
}

/** 
 * 删除地址
 */
 export function delAddress(id) {
  return request.post(`user/address/delete/${id}`);
}

/**
  * @description 钱包账户
  */
 export function accountInfo(data) {
  return request.get(`user/getAccountInfo`, data);
}

/**
  * @description 充值列表
  */
 export function rechargeList(data) {
  return request.get(`user/wallet/recharge/list`, data);
}
/**
  * @description 冻结列表
  */
 export function frozenList(data) {
  return request.get(`user/wallet/frozen/list`, data);
}
/**
  * @description 提现列表
  */
 export function extractList(data) {
  return request.get(`user/wallet/extract/lst`, data);
}
/**
  * @description 钱包明细接口
  */
 export function historyList(data) {
  return request.get(`user/wallet/historyList`, data);
}

/**
  * @description 充值
  */
 export function recharge(data) {
  return request.post(`user/recharge`, data);
}

/** 
 * 关注接口   type 1 商品  11拍品
 */
 export function relationCreate(data) {
  return request.post(`user/relation/create`, data);
}

/** 
 * 取消关注接口   type 1 商品  11拍品
 */
 export function relationDel(data) {
  return request.post(`user/relation/delete`, data);
}

/** 
 * 我关注的拍品列表
 */
 export function relationList(data) {
  return request.get(`user/relation/auction/lst`, data);
}

/** 
 * 我的拍卖足迹
 */
 export function auctionHistory(data) {
  return request.get(`user/history/auction`, data);
}

/** 
 * 清空拍卖足迹
 */
 export function auctionHistoryDel() {
  return request.post(`user/history/delete`);
}

/** 
 * 我的参拍记录
 */
 export function userBidRecordApi(data) {
  return request.get(`auction/userBidRecord`, data);
}

/** 
 * 提现
 */
 export function applyExtract(data) {
  return request.post(`user/wallet/extract/applyExtract`, data);
}

/**
 * 个人中心
 */
 export function userCenterNum(data) {
  return request.get(`user/centerNum`, data);
}
/** 
 * 提现
 */
 export function isSubscribeOfficialAccountApi() {
  return request.get(`user/isSubscribeOfficialAccount`);
}
// 浏览历史
export function historyListApi(data) {
  return request.get(`user/history`, data);
}
// 清空浏览历史
export function historyBatchDelete(data) {
  return request.post(`user/history/batch/delete`, data);
}
// 删除浏览历史
export function historyDelete(id) {
  return request.post(`user/history/delete${id}`);
}
/*
 * 资金明细（types|0=全部,1=消费,2=充值,3=返佣）
 * */
export function getCommissionInfo(q, types) {
	return request.get("user/bill", q);
}
/**
 * 订单统计数据
*/
export function orderData(){
  return request.get('order/number')
}