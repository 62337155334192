<template>
  <div :style="{
    background: facade.background_color,
    margin: `0 ${facade.page_padding / 75}rem`,
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: (facade.indicator_vertical_align === 'bottom' ? '0.66666rem' : '')
  }">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in content.data"
          :key="index"
          class="swiper-slide"
          :style="{
            width: content.style == 2 || content.style == 3?(content.view_width / 75) + 'rem':'100%',
            height: content.style == 2 || content.style == 3?(content.view_height / 75) + 'rem':'100%',
            padding: paddingVal,
            marginRight: (content.style == 2 || content.style == 3?((750 - content.view_width - facade.item_padding - (content.style == 3 ? 0 : facade.page_padding) - facade.page_padding) / (content.style == 2 ? 2 : 1))/75 + 'rem':0)
          }"
        >
        <!-- v-lazy="item.url" -->
          <img
            :src="item.url"
            
            :style="{
              width: (content.view_width / 75) + 'rem',
              height: (content.view_height / 75) + 'rem',
              borderTopLeftRadius: (facade.img_radius / 75) + 'rem',
              borderTopRightRadius: (facade.img_radius / 75) + 'rem',
              borderBottomLeftRadius: (facade.img_bottom_radius / 75) + 'rem',
              borderBottomRightRadius: (facade.img_bottom_radius / 75) + 'rem',
            }"
          />
        </div>
      </div>
    </div>
    <Pointer
      :vAlign="facade.indicator_vertical_align"
      :align="facade.indicator_align"
      :type="facade.indicator_style"
      :color="facade.indicator_color"
      :pointerCount="content.data.length"
      :curr="current"
    />
  </div>
</template>

<script>
import Swiper from "swiper";
import { ref, defineComponent, onMounted, toRefs, computed } from "vue";
import Pointer from "@/components/DiyComponent/Pointer";

export default defineComponent({
  components: {
    Pointer,
  },
  props: {
    content: Object,
    facade: Object,
  },
  setup(props) {
    const { facade, content } = toRefs(props);
    const current = ref(0);
    const mySwiper = ref(null);
    onMounted(() => {
      mySwiper.value = new Swiper(".swiper-container", {
        loop: true,
        slidesPerView: "auto", //每页显示数量，auto为自动填充，3为每页显示3块
        centeredSlides: content.value.style == 2 || content.value.style == 1, //轮播图自动居中
        initialSlide: 0, //轮播图初始下标位置
        //方法事件
        on: {
          //回调函数，swiper从当前slide开始过渡到另一个slide时执行
          slideChangeTransitionEnd: function () {
            current.value = this.activeIndex % content.value.data.length;
          },
        },
      });
    });

    const paddingVal = computed(() => {
      if (content.value.style === 3) {
        return `0 ${facade.value.item_padding / 75}rem 0 0`
      } else if (content.value.style === 2) {
        return `0 ${facade.value.item_padding / 150}rem`
      } else {
        return `0`
      }
    })

    return {
      current,
      paddingVal
    };
  },
});
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
}
.banner-swiper {
  width: 100%;
  .swiper-img {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.swiper-slide{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>