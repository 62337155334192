<template>
  <div
    v-on:touchstart="bodyTouchStart"
    v-on:touchmove="bodyTouchMove"
    v-on:touchend="bodyTouchEnd"
  >
    <!-- <div style="position: fixed; height: 200px; left: 0px; z-index: 9999; display: flex; align-items: center; top: 263.8px;">
      <div style="width: 38px;height: 109.576px;background: url(&quot;https://cdn.weipaitang.com/static/2019121808df9be1-82c2-9be182c2-7a74-76514f6ee9ff-W100H159&quot;) 0% 0% / 100% 100% no-repeat;text-align: center;display: flex;align-items: center;justify-content: center;overflow: hidden;">
      <img src="https://cdn.weipaitang.com/static/20191218b47ce633-53fd-e63353fd-c00b-5345ef21a902-W17H32" style="width: 10px; height: 20px; display: none;"></div>
    </div> -->
    <template v-if="!isIndexPage">
    <div id="navback" :style="`position: fixed; height: ${400/75}rem; left: 0px; z-index: 9999; display: flex; align-items: center; top: ${520/75}rem;`" v-show="countnum>10 && lockx">
      <div  style="background: url(&quot;https://artimg2.artart.cn/merchant/333425/2022511/image/1652260857969436707.png&quot;) 0% 0% / 100% 100% no-repeat;text-align: center;display: flex;align-items: center;justify-content: center;overflow: hidden;" 
            :style="`width:${(countnum)/75}rem;height:${((200-countnum))/75}rem`">
      <img src="https://artimg2.artart.cn/merchant/333425/2022511/image/1652260857228904644.png" :style="`width: ${(15/75)}rem; height: ${30/75}rem;`" v-show="countnum>20"></div>
    </div>
    </template>
    <!-- -->
    <div style="width:100vw;height:100vh;position: fixed;left:0;top:0;z-index:8000;background: rgba(0,0,0,0);overflow: hidden;" v-show="lockx" @touchstart.stop="prenstart" @touchmove.stop="prenmove"></div>
    <!-- {{router.customRouterData.transitionName}} -->
    <!-- mode="out-in" -->
    <router-view v-slot="{ Component }">
      <!-- :duration="{enter:600,leave:1000}"  mode="in-out"-->
      <transition :name="animate?router.customRouterData.transitionName:'fade'" :appear="true"  mode="in-out" :duration="animate?{enter:200,leave:100}:0">
        <keep-alive :include="includeList">
          <component :key="key" :is="Component"></component>
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script>
import { ref,computed } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
var swidth = document.documentElement.clientWidth;
export default {
  name: "App",
  setup() {
    const router = useRouter();
    const route = useRoute();
    // 需要缓存的组件name值
    const includeList = ref(['HomeOther', 'Home']);
    // const includeList = [
    //   "Home"
    // ]
    const key = ref("");

    window.myhistory = () => {
      if(window.uni){
        window.uni.postMessage({
          data: {
            type: "myhistory",
          },
        });
        if (router.customRouterData.history.length <= 1) {
          router.replace("/");
        } else {
          router.back();
        }
      }
      
    };

    const store = useStore();

    const footerData = computed(() => {
      return store.getters.footer_data &&
          store.getters.footer_data.footerSetting
          ? store.getters.footer_data.footerSetting.content.map(m=>{
            return m.link.link
          })
          : "";
    });
    // direction 页面切换的过渡动画，配合transition组件使用
    //  direction: "slide-left",
    // touchLeft 划动起点界限，起点在靠近屏幕左侧时才有效
    //  touchLeft: swidth*2/5,
    // touchStartPoint 记录起始点X坐标
    //  touchStartPoint: 0,
    // distance 记录划动的距离
    //  distance: 0,
    // 回退按钮的dom，根据页面上是否存在此dom来判断该路由是否可回退
    //  backBtn: null
    const direction = ref("slide-left");
    const touchLeft = ref((swidth * 2) / 5);
    const touchStartPoint = ref(0);
    const startY= ref(0);
    const distance = ref(0);
    const distanceY = ref(0);
    const countnum = ref(0);
    const backBtn = ref(null);

    const lockx = ref(false);
    const locky = ref(false);
    const bodyTouchStart = function (event) {
      backBtn.value = document.getElementById("navback");
      if (backBtn.value) {
        // 获得起点X坐标，初始化distance为0
        touchStartPoint.value = event.targetTouches[0].pageX;
        startY.value = event.targetTouches[0].pageY;
        distance.value = 0;
      }
    };

    const bodyTouchMove = function (event) {
      if(lockx.value){
        event.preventDefault();
      }
      // event.preventDefault();
      if((route.name=="Home" && route.fullPath == '/') || route.name=="users_index_new"){
        return
      }
      if (backBtn.value && touchStartPoint.value < touchLeft.value) {
        // 只监听单指划动，多指划动不作响应
        if (event.targetTouches.length > 1) {
          return;
        }
        // 实时计算distance
        distance.value = event.targetTouches[0].pageX - touchStartPoint.value;
        distanceY.value = event.targetTouches[0].pageY - startY.value;

        console.log(distance.value,distanceY.value);

        if(!lockx.value && !locky.value) {
          if(Math.abs(distanceY.value)>10){
            lockx.value = false;
            locky.value = true;
          }
          if(Math.abs(distanceY.value) - Math.abs(distance.value) > 10) {
            lockx.value = false;
            locky.value = true;
          }
          if(Math.abs(distance.value) - Math.abs(distanceY.value) > 10) {
            lockx.value = true;
            locky.value = false;
          }
          
        }
        // if (distance.value < Math.abs(distanceY.value)){
        //   countnum.value = 0;
        //   return
        // }
        // 根据distance在页面上做出反馈。这里演示通过返回按钮的背景变化作出反馈
        if (distance.value > 0 && distance.value < 100 && lockx.value) {
          // backBtn.value.style.backgroundPosition =
          //   ((distance.value - 100) / 100) * 50 + "px 0";
          countnum.value = distance.value>80?80:distance.value;
        } else if (distance.value >= 100 && lockx.value) {
          countnum.value = 80;
          // backBtn.value.style.backgroundPosition = "0 0";
        } else {
          countnum.value = 0;
          // backBtn.value.style.backgroundPosition = "-50px 0";
        }
      }
    };
    const bodyTouchEnd = function (event) {
      
      if((route.name=="Home" && route.fullPath == '/') || route.name=="users_index_new"){
        return
      }
      // if (distance.value < Math.abs(distanceY.value)){
      //   countnum.value = 0;
      //   return
      // }
      countnum.value = 0;
      if (backBtn.value && touchStartPoint.value < touchLeft.value) {
        // 划动结束，重置数据
        touchStartPoint.value = 0;
        backBtn.value.style.backgroundPosition = "-50px 0";
        // 当划动距离超过100px时，触发返回事件
        if (distance.value > 100 && lockx.value) {
          // 返回前修改样式，让过渡动画看起来更快
          // document.getElementById("app").classList.add("quickback");
          // router.back();
          distance.value = 0;
          console.log(router);
          if (router.customRouterData.history.length <= 1) {
            router.replace("/");
          } else {
            router.back();
          } 
          // setTimeout(function () {
          //   document.getElementById("app").classList.remove("quickback");
          // }, 100);
        }
      }
      lockx.value = false;
      locky.value = false;
    };
    const isIndexPage = ref(false);
    const animate = ref(true)
    const prenstart = function (event) {
      event.preventDefault();
      // event.stopPropagation();
    }
    const prenmove = function (event) {
      event.preventDefault();
      // event.stopPropagation();
    }
    return {
      key,
      includeList,
      direction,
      countnum,
    
      touchLeft,
      touchStartPoint,
      distance,
      backBtn,
      bodyTouchStart,
      bodyTouchMove,
      bodyTouchEnd,
      router,

      footerData,
      animate,
      isIndexPage,
      lockx,
      prenstart,
      prenmove
    };
  },
  watch: {
    $route(to, from) {
      let linkarr = [...this.footerData, '/']
      if (window.uni) {
        window.uni.postMessage({
          data: {
            type: "link",
            url: to.fullPath,
            title: to.meta.title || ''
          },
        });
      }
      this.key = to.fullPath;
      if (to.meta.keepAlive && this.includeList.indexOf(to.name) === -1) {
        this.includeList.push(to.name);
      }
      if((linkarr.indexOf(to.fullPath)>-1 && linkarr.indexOf(from.fullPath)>-1)||(to.name == 'auction_detail'&&from.name == 'auction_detail')){
        this.animate = false;
      }else{
        this.animate = true;
      }
      if(linkarr.indexOf(to.fullPath)>-1){
        this.isIndexPage = true;
      }else{
        this.isIndexPage = false;
      }
      // if (from.name == "login" || from.path.indexOf("home") > -1) {
      //   this.direction = "slide-left";
      // } else if (to.path.indexOf("home") > -1) {
      //   this.direction = "slide-right";
      // } else {
      //   const toDepth = to.path.split("/").length;
      //   const fromDepth = from.path.split("/").length;
      //   this.direction = toDepth < fromDepth ? "slide-right" : "slide-left";
      // }
      // console.log(this.direction);
    },
  },
};
</script>

<style>
body {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  user-select: none;
}
#app {
  /* font-family: none; */
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #000;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
p {
  margin: 0;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
/* #navback{
  position:absolute;
  left:0;
  top:500px;
} */
#app {
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  /* width: 100%; */
  /* overflow-x: hidden; */
}
/* .appView {
  position: absolute;
  width: 100%;
  background: #fff;
  min-height: 100vh;
  transition: transform 0.24s ease-out;
}
#app.quickback .appView {
  transition-duration: 0.1s;
}
.slide-left-enter {
  transform: translate(100%, 0);
}
.slide-left-leave-active {
  transform: translate(-50%, 0);
}
.slide-right-enter {
  transform: translate(-50%, 0);
}
.slide-right-leave-active {
  transform: translate(100%, 0);
} */
.slide_left-enter-active, .slide_left-leave-active, .slide_right-enter-active, .slide_right-leave-active {
    /* transition: all 0.5s; */
    transition: transform 0.2s;
    position: absolute !important;
    background-color: white;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100000;
  }
.slide_left-leave-active, .slide_right-leave-active {
  z-index: -1;
  transition: none;
}
  .slide_left-enter-from, .slide_right-leave-to {
    opacity: 1;
    transform: translateX(100%);
  }

  .slide_right-enter-from, .slide_left-leave-to {
    opacity: 1;
    transform: translateX(-100%);
  }

  .slide_left-leave-to, .slide_right-leave-to {
    opacity: 0;
  }
#nprogress .bar {
  background: #fff !important;
}
</style>
