<template>
  <div
    class="pointer"
    :style="{ marginBottom: '0.10666rem', bottom: `${vAlign === 'bottom' ? '0' : '0.13333'}rem` }"
  >
    <div
      v-if="type < 3"
      class="pointer-content"
      :class="{ 'circle': type == 1, 'strip': type != 1 }"
      :style="{ textAlign: align }"
    >
      <text
        class="pointer-item"
        :class="{ 'active': index == curr }"
        :style="{ backgroundColor: (index == curr ? color : '') }"
        v-for="(item, index) in pointerCount"
        :key="index"
      ></text>
    </div>
    <!-- <div
      v-else-if="type==3"
      class="pointer-text"
      :style="{
        textAlign: align,
      }"
    >
      <span>{{ curr + 1 }}/{{ pointerCount }}</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    vAlign: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#dd0000",
    },
    pointerCount: {
      type: Number,
      default: 0,
    },
    curr: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.pointer {
  position: absolute;
  bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  left: 0;
  right: 0;
  z-index: 1;
}

.pointer .circle .pointer-item {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  border-radius: 100%;
  opacity: 0.5;
}

.pointer .strip .pointer-item {
  display: inline-block;
  width: 30px;
  height: 6px;
  background-color: #000;
  opacity: 0.3;
}

.pointer .pointer-item {
  margin-left: 12px;
}

.pointer .pointer-content {
  margin: 0 40px;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
}

.pointer .pointer-content .active {
  background-color: #f44;
  opacity: 1;
}

.pointer-text {
  margin: 0 40px;
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    min-width: 36px;
    height: 36px;
    padding: 0 10px;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    background: #000;
    border-radius: 18px;
    opacity: 0.3;
  }
}
</style>