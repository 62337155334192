<template>
  <div :style="sty">
    <div
      v-if="type == 1"
      :class="`status-type${status}`"
      :style="styleData"
    >进行中</div>
    <div
      v-else-if="type == 2"
      :class="`status-type${status}`"
      :style="styleData"
    >{{ album_status == 2 ? '待开拍' : '预展中' }}</div>
    <div
      v-else-if="type == 4"
      class="qiagou"
      :class="`status-type${status}`"
      :style="styleData"
    >可洽购</div>
    <div
      v-else
      class="hui"
      :class="`status-type${status}`"
      :style="styleData"
    >已结拍</div>
  </div>
</template>
<script>
import { ref, toRefs, computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    type: {
      type: [String, Number],
      default: "",
    },
    status: {
      type: [String, Number],
      default: "1",
    },
    sty: {
      type: String,
      default: "",
    },
    album_status: [Number, String]
  },
  setup(props) {
    const store = useStore();
    const { status, type } = toRefs(props);

    const tagData = computed(() => {
      return store.getters.tag_style ? store.getters.tag_style : {};
    });
    const styleData = computed(() => {
      if (tagData.value) {
        const d = {
          topLeftRadius: tagData.value.topLeftRadius,
          topRightRadius: tagData.value.topRightRadius,
          bottomLeftRadius: tagData.value.bottomLeftRadius,
          bottomRightRadius: tagData.value.bottomRightRadius,
        };
        switch (type.value) {
          case 1:
            d.btnBg = tagData.value.goingBtnBg;
            d.btnColor = tagData.value.goingBtnColor;
            d.btnBorder = tagData.value.goingBorder;
            d.btnBorderColor = tagData.value.goingBorderColor;
            d.activeColor = tagData.value.goingActiveColor;
            break;
          case 2:
            d.btnBg = tagData.value.previewBtnBg;
            d.btnColor = tagData.value.previewBtnColor;
            d.btnBorder = tagData.value.previewBorder;
            d.btnBorderColor = tagData.value.previewBorderColor;
            d.activeColor = tagData.value.previewActiveColor;
            break;
          case 4:
            d.btnBg = tagData.value.discussBtnBg;
            d.btnColor = tagData.value.discussBtnColor;
            d.btnBorder = tagData.value.discussBorder;
            d.btnBorderColor = tagData.value.discussBorderColor;
            d.activeColor = tagData.value.discussActiveColor;
            break;
          default:
            d.btnBg = tagData.value.finishBtnBg;
            d.btnColor = tagData.value.finishBtnColor;
            d.btnBorder = tagData.value.finishBorder;
            d.btnBorderColor = tagData.value.finishBorderColor;
            d.activeColor = tagData.value.finishActiveColor;
            break;
        }
        let sty = {};
        switch (status.value) {
          case 1:
            sty = {
              background: `linear-gradient(135deg, ${d.btnBg}aa, ${d.btnBg})`,
              color: d.btnColor,
            };
            break;
          case 2:
            sty = {
              border: `0.05333rem solid ${d.btnBorder}`,
              color: d.btnBorderColor,
            };
            break;
          case 3:
            sty = {
              color: d.activeColor,
              textAlign: 'left',
              fontSize: (24 / 75) + 'rem'
            };
            break;
        }
        sty.borderRadius = `${d.topLeftRadius / 75}rem ${d.topRightRadius / 75}rem ${d.bottomRightRadius / 75}rem ${d.bottomLeftRadius / 75}rem`;
        return sty;
      } else {
        return {};
      }
    });

    return {
      tagData,
      styleData,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-type1,
.status-type2,
.status-type3 {
  width: 88px;
  height: 36px;
  border-radius: 4px 18px 18px 4px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
.status-type1 {
  background: linear-gradient(135deg, #cd2c2caa, #cd2c2c);
}
.status-type1.hui {
  background: linear-gradient(135deg, #999999aa, #999999);
}
.status-type1.qiagou {
  background: linear-gradient(135deg, #009406aa, #009406);
}
.status-type2 {
  border: 4px solid #cd2c2d;
  line-height: 28px;
  text-align: center;
  color: #cd2c2d;
  box-sizing: border-box;
}
.status-type2.hui {
  border: 4px solid #999;
  color: #999;
}
.status-type2.qiagou {
  border: 4px solid #009406;
  color: #009406;
}
.status-type3 {
  color: #cd2c2d;
}
.status-type3.hui {
  color: #999;
}
.status-type3.qiagou {
  color: #009406;
}
</style>