<template>
  <div v-if="renderData.renderData">
    <template v-if="content.style === 3">
      <div
        class="txt-line"
        :style="`padding: ${facade.item_padding / 75}rem ${facade.page_padding / 75}rem;`"
        @click="goDetailAction(m.venue_id)"
        v-for="(m, i) in renderData.renderData"
        :key="i"
      >
        <div class="meeting-name">{{m.name}}</div>
        <div class="meeting-line-center item-align-center">
          <div>{{parseTime(m.start_time, '{y}年{m}月{d}日 {h}:{i}')}}</div>
          <div
            class="shu"
            v-if="m.address_name"
          ></div>
          <div
            v-if="m.address_name"
            class="flex-one"
          >{{m.address_name}}</div>
        </div>
        <div class="meeting-line-bottom item-align-center">
          <div class="flex-one">
            <span>成交额：</span>
            <span :style="`color: ${facade.status_background}`">{{m.fee_type == 'CNY' ? 'RMB' : m.fee_type}}</span>
            <span :style="`color: ${facade.status_background};margin-left: 0.066667rem;`">{{m.sum_transaction_price}}</span>
          </div>
          <div>
            <span>拍品总数：{{m.auction_count}}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(m, i) in renderData.renderData"
        :key="`auction-meeting${i}`"
        class="auction-meeting"
        :class="{'mt0': i === 0}"
        :style="{ 'background-color': facade.background_color , marginTop: meetingMarginTop}"
      >
        <div
          class="title item-align-center"
          :style="{ padding: `0 ${facade.meeting_title_padding / 75}rem ${facade.meeting_title_padding / 75}rem ${facade.meeting_title_padding / 75}rem` }"
          @click="goDetailAction(m.venue_id)"
        >
          <div
            class="l flex-one"
            :style="{ color: facade.name_color, fontSize: (facade.name_font_size / 75) + 'rem' }"
          >{{ m.name }}</div>
          <div
            v-if="m.album_count"
            class="r ml10"
            :style="{ color: facade.name_color, borderColor: facade.name_color }"
          >{{m.swiperIndex || 1}}/{{ m.album_count }}</div>
          <!-- <div
            v-if="m.album_count"
            class="r ml10"
            :style="{ color: facade.name_color, borderColor: facade.name_color }"
          >{{ m.album_count }}个专场</div> -->
        </div>
        <AuctionAlbum
          v-if="m.renderAlbum.renderData && m.renderAlbum.renderData.length > 0"
          :facade="facade"
          :content="m.renderAlbum"
          :renderindx="i"
          @changeSwiper="changeSwiper"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import { parseTime } from "@/utils/util";
import AuctionAlbum from "@/components/DiyComponent/AuctionAlbum";

export default {
  components: {
    AuctionAlbum,
  },
  props: {
    content: Object,
    facade: Object,
  },
  setup(props) {
    const { content, facade } = toRefs(props);
    const router = useRouter();

    const meetingMarginTop = computed(() => {
      if (content.value.style === 1) {
        return `${(facade.value.meeting_padding || 16) / 75}rem`;
      } else {
        return `${(facade.value.meeting_padding1 || 48) / 75}rem`;
      }
    });

    const renderData = computed(() => {
      if (content.value.renderData) {
        const d = [];
        content.value.renderData.forEach((m) => {
          d.push({
            auction_count: m.auction_count,
            sum_transaction_price: m.sum_transaction_price,
            start_time: m.start_time,
            name: m.name,
            album_count: m.album_count,
            venue_id: m.venue_id,
            renderAlbum: {
              style: content.value.style,
              renderData: m.album || [],
            },
          });
        });
        return {
          style: content.value.style,
          renderData: d,
        };
      } else {
        return {};
      }
    });

    const goDetailAction = (id) => {
      router.push("/pages/auctionMeeting/index?id=" + id);
    };
    const changeSwiper = (e) => {
      console.log(e);
      content.value.swiperIndex = e;
    };
    return {
      meetingMarginTop,
      renderData,
      parseTime,

      goDetailAction,
      changeSwiper
    };
  },
};
</script>

<style lang="scss" scoped>
.auction-meeting {
  overflow: hidden;
  position: relative;

  .title {
    font-size: 32px;

    .l {
      font-weight: 600;
      line-height: 44px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .r {
      line-height: 40px;
      height: 44px;
      background: #ffffff;
      border-radius: 24px;
      border: 2px solid #fff;
      padding: 0 16px;
      font-size: 24px;
      box-sizing: border-box;
    }
  }
}

.txt-line {
  padding: 20px;
  border-top: 2px solid #eee;
}

.txt-line .txt-line:first-child {
  border-top: none;
}

.txt-line .meeting-name {
  font-size: 32px;
  font-weight: 500;
  color: #000;
}

.txt-line .meeting-line-center {
  margin-top: 16px;
  font-size: 24px;
  color: #999;
}

.txt-line .meeting-line-center .shu {
  margin: 0 20px;
}

.txt-line .meeting-line-bottom {
  margin-top: 16px;
  font-size: 26px;
  color: #999;
}

.txt-line .meeting-line-bottom .flex-one {
  color: #000;
}
</style>