import request from './request';
import store from '@/store';
/** 
 * 获取机构信息
 */
export function getDoMain(data) {
  return request.get(`findDomainKey`, data);
}

/**
  * @description 获取阿里云配置key
  */
 export function getOssUploadConfig() {
  return request.get(`upload/oss/getUploadOssStsToken`);
}

/** 
 * 获取自定义页面
 */
export function getDiyPage(data) {
  return request.get(`diypage/info`, data);
}

/** 
 * 获取自定义页面数据
 */
 export function getDiyPageData(data) {
  return request.get(`diypage/area/data`, data);
}

/** 
 * 获取自定义页面数据
 */
export function getConfigClassKeys(key) {
  return request.get(`config/getConfigClassKeys/${key}`);
}

/**
  * @description 线下支付银行列表
  */
 export function bankTransferAccount() {
  return request.get(`user/wallet/getBankTransferAccount`);
}

/**
  * @description 物流公司
  */
 export function expressList() {
  return request.get(`common/express`);
}

/**
  * @description 查看协议
  */
 export function getAgreement(data) {
  return request.get(`/merchant/getAgreement`, data);
}

/**
  * @description 获取商户信息
  */
 export function merchantInfoApi() {
  return request.get(`merchant/info`);
}

/**
  * @description 获取商户信息
  */
 export function getJsSdkConfigApi(data) {
  return request.get(`getJsSdkConfig`, data);
}

/** 
 * 获取分享海报
 */
 export function sharePosterImg(data) {
  const mer_id = store.getters.mer_id;
  let q = [`mer_id=${mer_id}`];
  for (let key in data) {
    const m = data[key];
    q.push(`${key}=${m}`)
  }
  return `${process.env.VUE_APP_BASE_API}api/sharePoster?${q.join('&')}`;
}
// 获取Oss信息
export function getOss(data) {
	return request.get("upload/oss/actionGetOssUplosdconfig", data)
}