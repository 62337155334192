import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import OrderConfirm from '../views/OrderConfirm.vue'
import Word from '../views/Word.vue'
import auctionRouter from './modules/auction';
import orderRouter from './modules/order';
import usersRouter from './modules/users';
import Search from '../views/Search';
import SearchType from '../views/SearchType';
import transitionExtend from "./transition-extend";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/auth-redirect',
    name: 'LoginAuthRedirect',
    component: () => import('@/views/login/authRedirect')
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/login/Login')
  },
  {
    path: '/rebind_phone',
    name: 'RebindPhone',
    component: () => import('@/views/login/rebind_phone')
  },
  {
    path: '/pages/home/home',
    name: 'HomeOther',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/pages/users/index',
    name: 'users_index_new',
    component: () => import('@/views/users/index'),
    meta: {
      keepAlive: true,
      title: '个人中心'
    }
  },
  {
    path: '/pages/live/auction_live',
    name: 'LiveAuctionPage',
    component: () => import('@/views/live/auctionLive'),
  },
  {
    path: '/pages/live/live',
    name: 'LivePage',
    component: () => import('@/views/live/index'),
  },
  {
    path: '/pages/live/live_bg',
    name: 'LiveBgPage',
    component: () => import('@/views/live/liveBg'),
  },
  {
    path: '/pages/orderConfirm/index',
    name: 'OrderConfirm',
    component: OrderConfirm,
    meta: {
      title: '订单结算'
    }
  },
  {
    path: '/pages/word/index',
    name: 'Word',
    component: Word,
    meta: {
      title: '文章'
    }
  },
  {
    path: '/pages/search/index',
    name: 'Search',
    component: Search,
    meta: {
      keepAlive: true,
      title: '搜索',
    }
  },
  {
    path: '/pages/search/type',
    name: 'SearchType',
    component: SearchType,
    meta: {
      keepAlive: true,
      title: '搜索',
    }
  },
  {
    path: '/pages/wallet/index',
    name: 'users_wallet',
    component: () => import('@/views/users/wallet'),
    meta: {
      title: '买家余额'
    }
  },
  {
    path: '/pages/wallet/entrust',
    name: 'users_wallet_entrust',
    component: () => import('@/views/users/wallet_entrust'),
    meta: {
      title: '委托方余额'
    }
  },
  {
    path: '/pages/albumDetail/index',
    name: 'auctionAlbumDetail',
    component: () => import('@/views/auction/albumDetail'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/pages/auctionMeeting/index',
    name: 'auctionAuctionMeeting',
    component: () => import('@/views/auction/auctionMeeting'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/pages/word/index',
    name: 'Word',
    component: () => import('@/views/Word')
  },
  {
    path: '/pages/nftCollection/index',
    name: 'nftCollection_detail',
    component: () => import('@/views/nftCollection/detail.vue'),
    meta: {
      title: '详情'
    }
  },
  {
    path: '/pages/nftCollection/payment',
    name: 'nftCollection_payment',
    component: () => import('@/views/nftCollection/payment.vue'),
    meta: {
      title: '支付'
    }
  },
  {
    path: '/pages/calender/calender',
    name: 'Calender',
    component: () => import('@/views/Calender'),
    meta: {
      keepAlive: true,
      title: '日历'
    }
  },
  {
    path: '/pages/pay/password',
    name: 'PayPassword',
    component: () => import('@/views/users/password'),
  },
  auctionRouter,
  orderRouter,
  usersRouter
]

let router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 对router对象扩展
router = transitionExtend(router)

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router
